/**====================================
    46.  Email Application  CSS Start
========================================**/
%email-mixin {
  background: transparent;
  font-size: 15px;
  line-height: 25px;
}
.email-wrap {
  .email-app-sidebar {
    .d-flex {
      img {
        border: 2px solid $light-gray;
      }
    }
    .main-menu {
      > li {
        width: 100%;
        text-align: left;
        a {
          color: $light-text;
          line-height: 39px;
          text-transform: uppercase;
          border-radius: 3px;
          font-weight: 400;
          i {
            margin-right: 10px;
          }
          &.active > a {
            color: $black;
          }
          &:hover {
            background-color: rgba($primary-color, 0.1);
            text-decoration: none;
            > .title {
              color: $primary-color;
            }
            > .badge {
              color: $primary-color;
            }
          }
        }
      }
    }
    ul {
      li {
        a {
          display: flex;
          padding-left: 20px;
          align-items: center;
          > {
            .title {
              width: 100%;
            }
            .badge {
              align-self: center;
              color: $dark-gray;
            }
          }
        }
      }
    }
  }
  .email-left-aside {
    .card-body {
      padding: 20px;
    }
  }
  .email-right-aside {
    .email-body {
      .modal-content {
        .btn-close {
          top: 34px;
          right: 25px;
        }
      }
      .inbox {
        overflow: auto;
        overflow-x: hidden;
        .d-flex {
          position: relative;
          .flex-grow-1 {
            span {
              position: absolute;
              right: 20px;
            }
          }
        }
        svg {
          width: 18px;
          margin-right: 10px;
          stroke: $dark-gray;
          cursor: pointer;
          &.like {
            fill: $warning-color;
            stroke: $warning-color;
          }
        }
        .d-flex-size-email {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .flex-grow-1 {
          display: flex;
        }
        img {
          height: 20px;
        }
        .d-flex {
          padding: 12px 20px;
          transform: translateY(0) scale(1);
          transition: all 0.3s ease;
          &:hover {
            background-color: lighten($light-gray, 4%);
            transform: translateY(-1px) scale(1.01);
            transition: transform 0.3s ease;
          }
        }
      }
      .attachment {
        margin-top: 10px;
        ul {
          li {
            display: inline-block;
            img {
              width: 90px;
              height: 90px;
            }
          }
        }
      }
    }
    .email-profile {
      .inbox {
        p {
          color: $light-text;
          font-weight: 500;
          display: inline-block;
          width: calc(100% - 200px);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding-left: 15px;
          max-width: 600px;
        }
      }
      > div {
        &:first-child {
          .email-top {
            padding: 20px;
            .email-chek {
              margin-right: 20px;
            }
            .checkbox_animated {
              &:after {
                width: 32px;
                height: 32px;
                border-radius: 4px;
              }
              &:before {
                left: 0.45rem;
                top: 0.45rem;
                width: 1.1rem;
                height: 0.5rem;
              }
            }
          }
        }
      }
      .dropdown {
        position: relative;
        width: fit-content;
      }
      .btn {
        color: $white !important;
        padding: 7px 20px;
        &:after {
          right: 20px;
        }
      }
    }
  }
  .btn-mail {
    border-radius: 2px;
    text-align: left;
    padding: 13px 15px;
    margin: 20px 0 20px;
    letter-spacing: 1px;
    line-height: 12px !important;
    color: $white !important;
  }
  .dropdown-menu {
    background-color: $white;
    border-color: $light-gray;
    z-index: 1;
  }
  .cke_chrome {
    background-color: $light-background;
    .cke_top {
      background-color: $body-color;
    }
  }
  .cke_top {
    background-color: transparent;
  }
  p {
    margin-bottom: 0;
    color: $light-text;
  }
  .flex-grow-1 {
    h6 {
      margin-bottom: 2px;
      color: $primary-color;
      margin-top: 3px;
      font-weight: 600;
      display: inline-block;
      font-size: 14px;
      span {
        font-weight: 600;
      }
      small {
        span {
          color: $light-font;
        }
      }
    }
  }
  .email-top {
    padding: 20px;
    border-bottom: 1px solid $light-gray;
    h5 {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
    .email-right {
      padding: 13px 0;
      display: flex;
      justify-content: flex-end;
    }
  }
  .email-wrapper {
    padding: 20px;
    h5 {
      margin-bottom: 20px;
    }
    .emailread-group {
      &:nth-child(n + 2) {
        border-top: 1px solid $light-gray;
        padding-top: 20px;
        margin-top: 20px;
      }
      h3 {
        margin-bottom: 10px;
      }
      h6 {
        font-size: 14px;
      }
      .read-group {
        &:nth-child(n + 2) {
          margin-top: 15px;
        }
      }
      textarea {
        text-transform: capitalize;
        padding: 15px;
      }
      .action-wrapper {
        margin-top: 15px;
      }
    }
    h6 {
      margin-bottom: 10px;
      float: left;
    }
    .right-download {
      float: right;
    }
  }
  .email-content {
    .email-top {
      .user-emailid {
        &:after {
          content: "";
          position: relative;
          height: 20px;
          width: 1px;
          right: 0;
          top: 3px;
          z-index: 2;
          border: 1px solid $light-semi-gray;
          float: right;
          padding: 0;
          margin: 0 20px;
        }
      }
    }
  }
  .email-body {
    .attachment {
      ul {
        li {
          img {
            width: 80px;
            height: 80px;
          }
          &:nth-child(n + 2) {
            margin-left: 10px;
          }
        }
      }
      .list-inline {
        .list-inline-item {
          padding-right: 13px;
          margin-right: unset;
        }
      }
    }
    .email-compose {
      .cke_contents.cke_reset {
        max-height: 100px;
        width: 100%;
      }
      .cke_bottom{
        display: none;
      }
      .cke_inner {
        background-color: transparent;
      }
      .email-top {
        h4 {
          margin-bottom: 0;
        }
      }
      .compose-border {
        margin: -1px;
        .btn-middle {
          padding-top: 5px;
        }
      }
      .compose-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .dropzone {
        i {
          display: block;
          margin-bottom: 10px;
        }
        h6 {
          float: unset;
        }
        .needsclick {
          display: block;
          margin: 0;
        }
      }
    }
  }
  .actions {
    text-align: right;
    li {
      display: inline-block;
      &:nth-child(n + 2) {
        margin-left: 15px;
      }
    }
  }
  .email-aside-toggle {
    text-transform: capitalize;
    display: none;
  }
}
.cke_top {
  border-bottom: 1px solid $light-gray !important;
}
@media (max-width: 575px) {
  .email-wrap {
    .email-app-sidebar {
      .main-menu {
        > li {
          a {
            line-height: 2.6;
          }
        }
      }
    }
    .email-right-aside {
      .tab-content {
        .email-wrapper {
          h6 {
            font-size: 12px;
          }
          .right-download {
            font-size: 12px;
          }
        }
      }
    }
  }
}
/**=================================
    46.  Email Application  CSS Ends
====================================**/