#uploadBtn {
    
    font-family: calibri;
    width: 150px;
    padding: 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border: 1px dashed #BBB;
    text-align: center;
    background-color: #DDD;
    cursor: pointer;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 34px;
  }
  .calenderIcon{
    vertical-align: middle;
    position: a;
    position: absolute;
    margin-top: 25px;
    margin-left:250px;
    z-index: 1;
    height:15px;
    width:15px;
   
}
.calenderIcon:hover{
  z-index: 99;
  cursor:pointer
}
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #949292;
    -webkit-transition: .4s;
    transition: .4s;
    width:70px;
  }
  
  .slider::before {
    position: absolute;
    content: "";
    height: 26px;
    width: 35px;
    left: -10px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  
  input:checked + .slider {
    background-color: #2b7704;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2b7704;
  }
  
  input:checked + .slider::before {
    -webkit-transform: translateX(55px);
    -ms-transform: translateX(55px);
    transform: translateX(55px);
  }

  .btn-font {
    font-weight: 400 !important;
  }
  