/**=====================
    61. pagination CSS Start
==========================**/
/*test Nav bar color */
$page-name: theme, primary, secondary, success, danger, info, dark, warning;
$page-color: var(--theme-default), var(--theme-default), $secondary-color,
  $success-color, $danger-color, $info-color, $dark-color, $warning-color;
@each $var in $page-name {
  $i: index($page-name, $var);
  .pagination-#{$var} {
    .page-item {
      &:first-child {
        .page-link {
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
        }
      }
      &:last-child {
        .page-link {
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
      .page-link {
        color: nth($page-color, $i) !important;
        &:focus {
          box-shadow: none;
        }
        &:hover {
          background-color: nth($page-color, $i) !important;
          color: $white !important;
        }
      }
      &.active {
        .page-link {
          color: $white !important;
          background-color: nth($page-color, $i) !important;
          border-color: nth($page-color, $i);
        }
      }
    }
  }
}
/**=====================
    61. pagination CSS Ends
==========================**/