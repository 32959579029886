/* UI changes on 25 APR  */

@media screen and (max-width: 1056px) {
  .user_card .ml-50 {
    margin-left: 0px !important;
  }

}

.data-tables .icon-style {
  font-size: 2rem;
  color: #335e33;
  cursor: pointer;
}

.card_newview {
  width: 100%;
  background: #335e33;
  padding: 20px;
  border-radius: 15px;
}

.card_newview p {
  margin-bottom: 5px;
  color: #ffff !important;
  font-weight: 500;
  font-size: 16px;
}

.title_name {
  font-size: 24px;
  font-weight: 700 !important;
}

.vieworder .details .projetname {
  font-size: 20px;
  margin-top: 20px;
  color: #335e33;
  font-weight: 600;
}

.vieworder .header {
  font-weight: 500;
}

.vieworder .header span,
.target-settings .header span {
  color: #335e33;
  font-weight: 500;
}

.vieworder .otherdetails p {

  font-size: 14px;
  font-weight: 500;

}

.vieworder .otherdetails p span {
  color: #335e33;
}

.lg-mt-2 {
  margin-top: 15px !important;
}

.newtable_prject_view {
  background: #f4f4f4;
  border-radius: 10px;
  padding: 20px 35px;

}

.newtable_prject_view tr {
  border-bottom: none !important;
  border-style: none !important;
  border-color: rgb(43 119 4 / 0%) !important;
}

.newtable_prject_view td {
  border-bottom: none !important;
  border-style: none !important;
  border-color: rgb(43 119 4 / 0%) !important;
  font-weight: 400;
}

.back_table {
  background: #f5f5f5;
  border-radius: 10px;

}

.back_table tr {
  border-bottom: none !important;
  border-style: none !important;
  border-color: rgb(43 119 4 / 0%) !important;
}

.back_table td {
  border-bottom: none !important;
  border-style: none !important;
  border-color: rgb(43 119 4 / 0%) !important;
  font-weight: 400;
}

.clr_green {
  color: #335e33 !important;
  font-weight: 500 !important;
}

.projectDetails_new {
  padding: 10px 25px;
  /* padding-bottom: 25px !important; */
}

.details_off p {
  color: #545454 !important;
  font-weight: 500;
  font-size: 17px;
}

/* .newtable_prject_view  table tr td:first-child{
width: 25%;
} */
/* .details_off{
  border-bottom: 1px solid #f5f5f5;
} */
.boder_bottom {
  /* border-bottom: 1px solid #d4d4d4; */
  padding-bottom: 25px;
}

.details_off {
  border-bottom: 2px solid #f4f4f4;
}

.dates p {
  color: #335e33 !important;
  font-size: 20px;
  font-weight: 600;
}

.dates h6 {
  margin-top: 5px;
  font-size: 17px;
  font-weight: 500;
  color: #545454 !important;
  padding-left: 16px;
}

/* .project_data .rdt_TableHeadRow [data-column-id="7"] {
  flex-grow: 1.5 !important;

}

.project_data .rdt_TableBody [data-column-id="7"] {
  flex-grow: 1.5 !important;

} */

.rdt_TableBody .rdt_TableRow:hover {
  background-color: #eff3f9;
}

.react-datepicker__day--keyboard-selected {
  color: #fff !important;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #f9f9f9;
  color: #000;
  border: 1px solid #dddddd !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__month-select {
  padding: 2px 10px;
  border-radius: 5px;
}

.react-datepicker__header__dropdown {
  margin: 10px auto;
}

.react-datepicker__year-select {
  padding: 2px 10px;
  border-radius: 5px;
}

.data-tables .rdt_Table .rdt_TableHead .rdt_TableCol div input {

  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 2px solid #e5e5e5 !important;
  height: 27px !important;
  margin-bottom: 10px !important;
  padding: 6px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  -webkit-transition: 0.5s;
  transition: 0.3s;
  outline: none;
  margin-right: 30px;
  width: 5rem !important;
  display: flex !important;
}

.data-tables .rdt_Table .rdt_TableHead .rdt_TableCol div input:focus {
  border: 2px solid #777 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;

}

.status_dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  margin: 0px 4px 0px 0px;
  background: #fff;
  border-radius: 50%;

}

.action_button {
  background: #fff;
  display: inline;
  float: left;
  color: #335e33;
  border: 1px solid #335e33;
  padding: 5px 8px;
  border-radius: 10px;
  font-size: 13px !important;
  cursor: pointer;
  white-space: nowrap;
  /* font-size: 13px !important; */
}

.action_button:hover {
  color: #335e33;
}

.action_button i {
  color: #335e33 !important;
  font-size: 16px !important;
}

.action_button i:hover {
  color: #f5f5f5;
}

.newtable .rdt_TableHeadRow {
  background: #000000c4 !important;
  padding-top: 10px !important;
  padding: 10px 10px !important;
  border-radius: 10px 10px 0px 0px !important;
  color: #fff;
}

.newtable .rdt_TableBody {
  /* background: #f6f6f6 !important; */
  padding-top: 0px !important;
  padding: 6px 3px !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-img-section .sidebar-img-content {
  background-color: #ffffff !important;
  height: 100vh !important;
}

.f1 .f1-steps .f1-step.new-f1-step {
  width: 32% !important;
}

.methodology {
  font-size: 1.4rem;
  font-weight: bold;
  color: #000;
}

.f1 .f1-steps .f1-step {
  width: 25% !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #949292;
  transition: .4s;
  width: 49px;
  height: 27px;
}

.slider::before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 5.4px;
  background-color: white;
  transition: .4s;

}

input:checked+.slider {
  background-color: #335e33 !important;
}

input:focus+.slider {
  box-shadow: 0 0 1px #335e33;
}

input:checked+.slider::before {
  -webkit-transform: translateX(45px);
  -ms-transform: translateX(45px);
  transform: translateX(26px);
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 30%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 70%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked+.slider .on {
  display: block;
}

input:checked+.slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.f1 .f1-steps .f1-step.active .f1-step-icon {
  font-size: 12px !important;
}

.f1 .f1-steps .f1-step .f1-step-icon {
  font-size: 12px !important;
  padding: 12px 2px !important;
  width: 48px !important;
  height: 48px !important;
}

.banner {
  background: url("../../assets/images/banner/signup-banner.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-size: cover;
  background-position-x: right;
  min-height: 100vh;
  height: auto;
}

.logo-wrapper img {
  width: 40px !important;
  margin-top: 0rem;
}

.box {
  border: 1px solid #eee;
  padding: 20px 10px;
}

.input-calendar-ico {
  float: right;
  margin-top: -2rem;
  position: relative;
  margin-right: 1rem;
}

.thead-light {
  background: #b4b2ba;
  font-size: 16px;
}

.img-fluid {
  width: 8rem;
}

.green-card {
  /* background: #2b7704 !important; */
  border-radius: 1rem;
}

.green-card h3,
.green-card p {
  color: #ffff !important;
}

.show-icon {
  color: #898989;
  height: 20px !important;
  width: 16px !important;
}

.social-icons {
  font-size: 30px !important;
}

.required {
  color: red;
}

.f-16 {
  font-size: 16px;
}

.table-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.action-icon {
  font-size: 10px;
  color: #37474F;
  width: 18px !important;
  margin-top: 10px;
}

H1:hover,
H2:hover,
H3:hover,
H4:hover,
H5:hover,
H6:hover,
P:hover,
LI:hover {
  cursor: default;
}

.text-color {
  color: #335e33
}

.ml-55 {
  margin-left: 55rem;
  display: flex;
}

.ml-20 {
  margin-left: 20rem;
}

.f-15 {
  font-size: 15px;
}

.mtop-2 {
  margin-top: 2rem;
}

.ellips {
  position: absolute;
  top: 30px;
  right: 30px;
  opacity: 0.7;
}

.fo-7 {
  opacity: 0.7;
}

.card-style {
  height: 15rem !important;
  cursor: pointer;
}

.add-card {
  margin-top: 7rem;
  margin-left: 7rem;
}

.card-bg {
  background-color: #DCEDC8 !important;
}

.card-menu {
  left: 40% !important;
  top: 50px !important
}

.x-button {
  position: absolute;
  top: 20px !important;
  right: 20px !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.new_show_hide {
  float: right !important;
  display: inline-block !important;
  position: relative !important;
  top: -14px !important;
}

.hover-pointer {
  cursor: pointer !important;
}

.document-icon {
  font-size: 14px;
  color: #2b7704;
  width: 18px !important;
  margin-left: 1.5rem;
  cursor: pointer;
  margin-top: 10px;
}

.paynow-btn {
  margin-bottom: 1rem;
  margin-left: 64%;
}

.documnt-p {
  color: #000000DE !important;
  margin-left: 10px;
}

.download-icon {
  font-size: 10px;
  color: #2b7704;
  width: 18px !important;
  cursor: pointer;
}

.disable-download {
  font-size: 10px;
  color: #b4b2ba;
  width: 18px !important;
  cursor: none !important;
  pointer-events: none !important;
}


.table-head,
.table-head th {
  border-bottom: none !important;
}

.disable-icon {
  pointer-events: none !important;
  color: #b4b2ba !important;
  cursor: none !important;
  font-size: 10px;
  width: 18px;
}

.ml-50 {
  margin-left: 50rem;
}

.reset-password {
  float: right !important;
  display: inline-block !important;
  position: relative !important;
  top: -18px !important;
}

.bottom-none {
  border-bottom-width: 0px !important;
}

.mr-2 {
  margin-right: 2rem;
}

.td-width {
  width: 140px !important;
}

.filter-toggle-label {
  margin-left: 36rem;
  margin-top: 0.8rem;
}

.tile-font-size {
  font-size: 1.4rem;
  color: #ffffff !important;
}

.no-wrap {
  white-space: nowrap;
}

.mtop-1 {
  margin-top: 1rem !important;
}

.rdt_TableHeadRow {
  display: flex !important;
  align-items: baseline !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.td-bottom {
  border-bottom-width: 0px !important;
}

.br-10 {
  border-radius: 10px !important;
}

.send-icon {
  border-radius: 60px !important;
  margin-bottom: 0px !important;
  margin-top: 7.5rem;
}

.p-10 {
  padding: 10px !important;
}

.send-svg {
  margin-left: 5px;
  cursor: pointer;
}

.border_none th {
  border-bottom: none !important;
}

.border_none_data td {
  border-bottom: none !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.justify-between {
  justify-content: space-between !important;
}

.border-none {
  border-bottom: none !important;
}


/* @media screen and (max-width:1150px) {
  .responsive-view .geknmz {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .responsive-view .gwbdqO {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
} */

@media screen and (max-width:1024px) {
  .responsive-view .hbotQl {
    padding-right: 20px !important;
  }
}


.trans-button {
  position: absolute;
  right: 0px;
}

.mtop-34 {
  margin-top: 34px !important;
}

.text-right {
  text-align: right;
}

.react-dual-listbox .rdl-move-all-right,
.react-dual-listbox .rdl-move-all-left {
  display: none;
}

.shadow_apply .rdl-filter,
.rdl-control {
  border-radius: 10px !important;
  border: none !important;
  box-shadow: 1px 3px 9px 0px rgba(0, 0, 0, 0.20) !important;
  overflow: auto !important;
}

.shadow_apply .rdl-actions {
  margin: 0 26px !important;
}

.shadow_apply .rdl-control option {
  font-size: 16px !important;
  padding: 10px 14px;
  font-weight: 500 !important;
}

.shadow_apply .rdl-control {
  padding: 7px 0 0 0 !important;
}

.shadow_apply .rdl-btn {
  padding: 8px 14px;
  background: #ebebeb6e;
}

.mt-40 {
  margin-top: 40px !important;
}

.ml-6 {
  margin-left: 6rem !important;
}

.text-left {
  text-align: left !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

/* @media screen and (min-width:1500px) {
  .wallet-details .hbotQl {
    word-break: break-word !important;
    white-space: normal !important;
  }
}

@media screen and (max-width:1500px) {
  .wallet-details .hbotQl {
    word-break: break-word !important;
    white-space: normal !important;
  }
} */

.mr-1 {
  margin-right: 1rem;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.w-76 {
  width: 76% !important;
}

.trading_orders button {
  font-size: 14px;
}

.trading-sell {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 70px;
  font-size: 14px !important;
  height: 34px;
  margin-top: 6px;
}

.w_46 {
  width: 46% !important;
}

.money-value {
  text-align: right;
  margin-left: 2rem;
}


.trading-view .WQNxq {
  overflow-x: hidden !important;
}


.trading-view .gwbdqO {
  min-width: 140px !important;
}

.trading-view .chVlNX {
  min-width: 140px !important;
}


.title-color {
  color: #051a1a !important;
}

.sale-chart-card {
  height: 10rem !important;
}

.sale-card-title {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.sale-value {
  color: #051a1a;
  font-size: 28px;
}

.credit-card-icon {
  color: #fff;
  font-size: 40px;
  height: 40px;
  width: 40px;
  align-items: self-start;
  justify-content: flex-start;
  margin-bottom: 3rem;
  margin-left: 2rem
}

.btop-card {
  border-top: 6px solid !important;
}

.radio-but {
  margin-top: 0.75rem;
}

.view-page-table {}

.view-page-table table tr td {
  font-weight: 400 !important;
}

.plus-icon {
  height: 50px !important;
  width: 50px !important;
  fill: #335e33;
  stroke-width: 1;
  color: #fff
}

.ml-46 {
  margin-left: 46rem;
}

.fw-td-bold {
  font-weight: 500 !important;
}

.edit-icon {
  font-size: 10px;
  color: #051a1a;
  width: 18px !important;
  margin-top: 10px;
}

.toast-width {
  width: 400px !important;
}


/* UI Changes */


.trading_orders .sc-fLcnxK {

  width: 95% !important;

}

.trading_orders .rdt_TableHeadRow [data-column-id="2"] {
  flex-grow: 0.6 !important;
}

.trading_orders .rdt_TableBody [data-column-id="2"] {
  flex-grow: 0.6 !important;
}

.trading_orders .rdt_TableHeadRow [data-column-id="4"] {
  flex-grow: 0.5 !important;
}

.trading_orders .rdt_TableBody [data-column-id="4"] {
  flex-grow: 0.5 !important;
}

.trading_orders .rdt_TableHeadRow [data-column-id="5"] {
  flex-grow: 0.5 !important;
}

.trading_orders .rdt_TableBody [data-column-id="5"] {
  flex-grow: 0.5 !important;
}

.trading_orders .rdt_TableHeadRow [data-column-id="6"] {
  flex-grow: 0.6 !important;
}

.trading_orders .rdt_TableBody [data-column-id="6"] {
  flex-grow: 0.6 !important;
}

/* .trading_orders .rdt_TableHeadRow [data-column-id="1"] {
  flex-grow: 0.3 !important;
}

.trading_orders .rdt_TableBody [data-column-id="1"] {
  flex-grow: 0.3 !important;

}



.trading_orders .rdt_TableHeadRow [data-column-id="3"] {
  flex-grow: 0.3 !important;
}

.trading_orders .rdt_TableBody [data-column-id="3"] {
  flex-grow: 0.3 !important;

}

.trading_orders .rdt_TableHeadRow [data-column-id="6"] {
  flex-grow: 0.2 !important;
  padding-right: 10px;
}

.trading_orders .rdt_TableBody [data-column-id="6"] {
  flex-grow: 0.3 !important;

}

.trading_orders .rdt_TableHeadRow [data-column-id="7"] {
  flex-grow: 0.2 !important;
}

.trading_orders .rdt_TableBody [data-column-id="7"] {
  flex-grow: 0.2 !important;

}

.trading_orders .rdt_TableHeadRow [data-column-id="8"] {
  flex-grow: 0.3 !important;
}

.trading_orders .rdt_TableBody [data-column-id="8"] {
  flex-grow: 0.3 !important;
} */
.wallet-det .rdt_TableHeadRow [data-column-id="1"] {
  flex-grow: 0.6 !important;
}

.wallet-det .rdt_TableBody [data-column-id="1"] {
  flex-grow: 0.6 !important;
}

.wallet-det .rdt_TableHeadRow [data-column-id="2"] {
  flex-grow: 0.2 !important;
}

.wallet-det .rdt_TableBody [data-column-id="2"] {
  flex-grow: 0.2 !important;
}

.wallet-det .rdt_TableHeadRow [data-column-id="3"] {
  flex-grow: 0.6 !important;
}

.wallet-det .rdt_TableBody [data-column-id="3"] {
  flex-grow: 0.6 !important;
}

.wallet-det .rdt_TableHeadRow [data-column-id="4"] {
  flex-grow: 0.6 !important;
}

.wallet-det .rdt_TableBody [data-column-id="4"] {
  flex-grow: 0.6 !important;
}

.wallet-det .rdt_TableHeadRow [data-column-id="5"] {
  flex-grow: 0.8 !important;
}

.wallet-det .rdt_TableBody [data-column-id="5"] {
  flex-grow: 0.8 !important;
}

.wallet-det .rdt_TableHeadRow [data-column-id="8"] {
  flex-grow: 0.6 !important;
}

.wallet-det .rdt_TableBody [data-column-id="8"] {
  flex-grow: 0.6 !important;
}

.wallet-det .rdt_TableHeadRow [data-column-id="7"] {
  flex-grow: 0.4 !important;
}

.wallet-det .rdt_TableBody [data-column-id="7"] {
  flex-grow: 0.4 !important;
}

.wallet-det .rdt_TableHeadRow [data-column-id="6"] {
  flex-grow: 0.4 !important;
}

.wallet-det .rdt_TableBody [data-column-id="6"] {
  flex-grow: 0.4 !important;
}

.mbottom-2 {
  margin-bottom: 2rem;
}

.trash-icon {
  font-size: 10px;
  color: #DC3545;
  width: 18px !important;
  margin-top: 10px;
}


.react-datepicker__year-dropdown .react-datepicker__navigation {
  background-color: none !important;
}

.notes-card {
  box-shadow: 0px 0px 0px 1px rgba(12, 12, 12, 0.2) !important;
  margin-top: 2rem !important;
}


/* @media screen and (max-width:690px) {
  .trading-view .sc-dmctIk.WQNxq {
    overflow-x: scroll !important;
  }
} */

@media screen and (min-width: 1199px) and (max-width: 1320px) {
  .sale-content h3 {
    font-size: 20px !important;
  }
}

.new_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100vh;
  width: 100%;
  background: #fff;
  z-index: 4;
}

.order-trading .hZPyfA {
  flex-grow: 1 !important;
}

.process-button {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 10px !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.fill-button {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 10px !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.wallet-icon {
  height: 45px;
  width: 45px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width:768px) {
  .trading_orders .rdt_TableHeadRow [data-column-id="1"] {
    flex-grow: 0.8 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="1"] {
    flex-grow: 0.8 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="2"] {
    flex-grow: 0.8 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="2"] {
    flex-grow: 0.8 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="3"] {
    flex-grow: 0.6 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="3"] {
    flex-grow: 0.6 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="4"] {
    flex-grow: 1 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="4"] {
    flex-grow: 1 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="5"] {
    flex-grow: 1 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="5"] {
    flex-grow: 1 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="6"] {
    flex-grow: 0.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="6"] {
    flex-grow: 0.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="7"] {
    flex-grow: 1.2 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="7"] {
    flex-grow: 1.2 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="7"] button {
    /* display: flex; */
    width: 112px;
    font-size: 11px;
    height: 34px;
    padding: 10px;
  }
}

.view-details {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 10px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-weight: 400 !important;
}

.order-status {
  display: flex;
  justify-items: baseline;
  align-items: baseline;
}

.order-select {
  width: 20% !important;
  margin-left: 0.5rem !important;
}

.rdt_TableHeadRow {
  text-align: center !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}


@media screen and (max-width:560px) {
  .sale-chart-card {
    margin: 10px 20px;
  }

  .update_btn {
    margin-top: 10px !important;
    margin-left: 0 !important;
  }

  /* .trading-view .rdt_TableHeadRow [data-column-id="1"] {
    flex-grow: 1 !important;
  }

  .trading-view .rdt_TableBody [data-column-id="1"] {
    flex-grow: 1 !important;
  }

  .trading-view .rdt_TableHeadRow [data-column-id="2"] {
    flex-grow: 0.5 !important;
  }

  .trading-view .rdt_TableBody [data-column-id="2"] {
    flex-grow: 0.5 !important;
  } */

  .trading_orders .rdt_TableHeadRow [data-column-id="1"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="1"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="2"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="2"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="3"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="3"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="4"] {
    flex-grow: 10.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="4"] {
    flex-grow: 10.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="5"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="5"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="6"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="6"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="7"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="7"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="8"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="8"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="7"] button {
    /* display: flex; */
    width: 75px;
    font-size: 10px;
    height: 30px;
    padding: 3px !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="8"] button {
    /* display: flex; */
    width: 75px;
    font-size: 10px;
    height: 30px;
    padding: 3px !important;
  }

  .page-wrapper .page-body-wrapper .card {
    overflow-x: scroll !important;

  }

}

@media screen and (max-width:640px) {

  .sale-chart-card,
  .wallet_card,
  .details_card {
    margin: 10px 20px;
  }

  .wallet-details .rdt_Table .rdt_TableHead .rdt_TableHeadRow .bQdYbR {
    padding: 10px 20px !important;

  }

  .wallet-details .rdt_Table .rdt_TableHead .rdt_TableHeadRow .bQdYbR {
    padding: 10px 20px !important;

  }

  .wallet-details .rdt_TableBody .bQdYbR {
    padding: 10px 20px !important;
  }

  .trading_orders .rdt_Table .rdt_TableHead .rdt_TableHeadRow .bQdYbR {
    padding: 10px 20px !important;

  }

  .trading_orders .rdt_TableBody .bQdYbR {
    padding: 10px 20px !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="5"] {
    flex-grow: 8 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="5"] {
    flex-grow: 8 !important;
  }

  .newtable_prject_view table td {
    font-size: 13px !important;
  }

  .project_data .rdt_TableHeadRow [data-column-id="6"] {
    flex-grow: 1.5 !important;
  }

  .project_data .rdt_TableBody [data-column-id="6"] {
    flex-grow: 1.5 !important;
  }

}

@media screen and (max-width: 460px) {
  .trading_orders .rdt_TableHeadRow [data-column-id="4"] {
    flex-grow: 20.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="5"] {
    flex-grow: 20.5 !important;
  }
}

.emission-table {}

.emission-table .chVlNX {
  min-width: 100px !important;
}

.emission-table .gwbdqO {
  min-width: 100px !important;
}

.node circle {
  fill: #f19402 !important;
}

.text-class {
  white-space: pre-wrap !important;
  padding-bottom: 10% !important;
  color: red
}

.orgSummary-chart {
  margin-top: 5rem;
}

.orgSummary-chart svg {
  width: 100% !important;
}

.simplebar-content-wrapper .sidebar-list .sidebar-link .menu-title {
  display: flex;
}

.simplebar-content-wrapper .sidebar-list .sidebar-link .menu-title span {
  white-space: nowrap;
}

div.modal.fade.show div.modal-body>table>tbody>tr td>p {
  display: block !important;
}

@media screen and (min-width: 1045px) {
  .responsive-view .chVlNX {
    padding-right: 30px !important;
  }
}

#org-table th:last-child,
#org-table td:last-child {
  width: 10rem;
}

#org-table thead {
  background: #f6f6f6;
  padding: 10px 10px;
  border-radius: 10px 10px 0px 0px;
}

.creeua p:first-child {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iBSWG p:first-child {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trading_orders .creeua p:first-child {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trading_orders .iBSWG p:first-child {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-trading .dlUCce {
  overflow: hidden !important;
}

.order-trading .ftDSoT {
  min-width: 120px !important;
}

.order-trading .creeua {
  min-width: 120px !important;
}

.order-trading .uINPX {
  min-width: 120px !important;
}

.order-trading .iBSWG {
  min-width: 120px !important;
}

.mb-0 {
  margin-bottom: 0px;
}

.margin-inline {
  margin-inline-start: auto
}

.mb-22px {
  margin-bottom: 22px;
}

.margin-inline-end {
  margin-inline-end: auto !important;
}

/* .card .data-tables p:first-child {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.rage-input-label {
  margin-top: 1rem;
}

.project-progress .f1 .f1-steps .f1-step.new-f1-step {
  width: 32% !important;
}


.project-progress .f1 .f1-steps .f1-step {
  width: 11% !important;
}

.project-progress .f1 .f1-steps .f1-step.active .f1-step-icon {
  font-size: 10px !important;
}

.project-progress .f1 .f1-steps .f1-step .f1-step-icon {
  font-size: 12px !important;
  padding: 9px 2px !important;
  width: 40px !important;
  height: 40px !important;
}

.project-progress .f1 .f1-steps .f1-progress {
  position: absolute;
  top: 70px;
  width: 100%;
  height: 12px;
  background: rgba(43, 119, 4, 0.1019607843);
  border-radius: 8px;
}

.project-progress .f1 .f1-steps .f1-progress .f1-progress-line {
  height: 12px;
  background-image: linear-gradient(to right, #212529, #23343d, #1e444f, #11555d, #335e33);
  border-radius: 8px;
}

.project-progress .h-11 {
  height: 9rem !important;
  padding-top: 56px;
}

.tracker-active {
  background-color: #188674 !important;
  font-weight: 600;
}

.icon-active {
  background-color: #188674 !important;
  color: #fff !important;
}

.project-progress .top-p {
  margin-top: -35px;
  margin-bottom: 0px;
}

.mt-19 {
  margin-top: 19px !important;
}

.mt-24 {
  margin-top: 24px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-neg-3 {
  margin-top: -3px;
}

.carbon-emission .data-tables .iBSWG {
  min-width: 100px !important;
}

.carbon-emission .data-tables .uINPX {
  min-width: 100px !important;
}

.carbon-emission .data-tables .ftDSoT {
  min-width: 100px !important;
}

.carbon-emission .data-tables .creeua {
  min-width: 100px !important;
}

.data-tables .iBSWG {
  padding-left: 8px;
}

/* .location-list .data-tables .creeua {
  min-width: 57px !important;
} */

.location-list .data-tables .iBSWG {
  min-width: 57px !important;
}

.data-tables .creeua {
  padding-left: 8px;
  min-width: 60px !important;
}

.status-btn {
  margin-bottom: 1rem;
  float: right;
}

.project-transition {
  position: sticky;
  max-height: 30rem;
  overflow-y: auto;
}

.w-17 {
  width: 17rem;
}

.substate-tooltip {
  background-color: #335e33 !important;
  color: #fff !important;
}

.progress-bar-align {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu-li {}

/* .menu-li::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  background: #ffffff33;
  width: calc(100% - 40px);
  height: 1px;
  transform: translateX(-50%)
} */

ul.simple-list.sidebar-submenu.d-block.list-group {
  background-color: #f0f0f0;
  border-radius: 0px;
}

.project-init .f1 .f1-steps .f1-progress {
  position: absolute;
  top: 19px;
  width: 100%;
  height: 12px;
  background: rgba(43, 119, 4, 0.1019607843);
  border-radius: 8px;
}

.project-init .f1 .f1-steps .f1-progress .f1-progress-line {
  height: 12px;
  background-image: linear-gradient(to right, #212529, #23343d, #1e444f, #11555d, #335e33);
  border-radius: 8px;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content>li a svg {
  color: #242424 !important;
}

.active-svg {
  color: #335e33 !important;
}

.wallet-details .rdt_TableHeadRow,
.trading-view .rdt_TableHeadRow,
.trading_orders .rdt_TableHeadRow {
  background: #000000c4 !important;
  padding-top: 10px !important;
  padding: 10px 10px !important;
  border-radius: 10px 10px 0px 0px !important;
  color: #fff;
}

.wallet-details .rdt_TableBody,
.trading-view .rdt_TableBody,
.trading_orders .rdt_TableBody {
  /* background: #f6f6f6 !important; */
  padding-top: 0px !important;
  padding: 6px 3px !important;
}

.mr-13 {
  margin-right: 13px;
}

.table-header-bg {
  background: #fff !important;
  padding-top: 10px !important;
  padding: 10px 10px !important;
  border-radius: 10px 10px 0px 0px !important;
  text-align: center;
  color: #051a1a !important;
}

.table-body {
  padding-top: 0px !important;
  padding: 6px 3px !important;
  text-align: center;
}

.text-center {
  text-align: center !important;
}

.p-0 {
  padding: 0px !important;
}

.profile-icon {
  height: 30px !important;
  width: 30px !important;
  fill: #f5f5f5;
}

.notes-table-header {
  background: #fff !important;
  border-radius: 10px 10px 0px 0px !important;
}

.project-transition-table {
  background: #f4f4f4 !important;
  border-radius: 10px 10px 0px 0px !important;
}

.notes-table-body {
  padding-top: 0px !important;
  padding: 6px 3px !important;
}

.notes-card .table .th {
  color: #fff;
}

.modal-footer {
  justify-content: flex-start !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.modal-body {
  padding: 25px !important;
  background-color: #fff !important;
}

.modal-header {
  color: #051a1a !important;
  background: #fff;
}

.modal-title-color {
  color: #051a1a !important;
}

.f-17 {
  font-size: 17px;
}

.w-12 {
  width: 12rem !important;
}

.w-15 {
  width: 15rem !important;
}

.pl-4 {
  padding-left: 56px;
}

.module-subscribe .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #949292;
  transition: .4s;
  width: 49px;
  height: 27px;
}

.module-subscribe .slider::before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 5.4px;
  background-color: #335e33;
  transition: .4s;
}

.module-subscribe .slider::after {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 5.4px;
  background-color: #fff;
  transition: .4s;
  border-radius: 50px;
}


.module-subscribe input:checked+.slider {
  background-color: #fff !important;
  border: solid 1px #335e33 !important;
}

.module-subscribe input:focus+.slider {
  box-shadow: 0 0 1px #fff;
}

.pb-14 {
  padding-bottom: 15px !important;
}

.card-height {
  height: 385px !important;
}

.rm-margin {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.fw-4 {
  font-weight: 400 !important;
}

.pb-0 {
  padding-bottom: 0px;
}

.css-t3ipsp-control {
  box-shadow: 0 0 0 1px #335e33 !important;
}

.css-t3ipsp-control:active {
  box-shadow: 0 0 0 1px #335e33 !important;
}

.css-t3ipsp-control:hover {
  border-color: #335e33 !important;
}

.css-10wo9uf-option :hover {
  background-color: #335e33 !important;
}

.css-tr4s17-option {
  background-color: #335e33 !important;
}

/* .css-1nmdiq5-menu :hover {
  background-color: #335e33 !important;
} */

.tooltip-style {
  border-radius: 7px !important;
  background-color: #335e337d !important;
  color: #fff !important;
  opacity: 0.8 !important;
}

.tooltip-arrow {
  background-color: #335e33e6 !important;
}

.menu-tooltip {
  border-radius: 8px !important;
  background-color: #335e33 !important;
  color: #fff !important;
  opacity: 1 !important;
}

.mtop-hf {
  margin-top: 0.5rem;
}

/* 
@media screen and (max-width: 1530px) {
  #reportContainer {
    height: calc(1400px - 90vh);
    width: -webkit-fill-available;
  }
}

@media screen and (min-width: 1530px) and (max-width: 1600px) {
  #reportContainer {
    height: calc(1420px - 90vh);
    width: -webkit-fill-available;
  }
}

@media screen and (min-width: 1600px) and (max-width: 2000px) {
  #reportContainer {
    height: calc(1650px - 90vh);
    width: -webkit-fill-available;
  }
}

@media screen and (min-width: 2000px) and (max-width: 2650px) {
  #reportContainer {
    height: calc(1950px - 90vh);
    width: -webkit-fill-available;
  }
} */


#reportContainer {
  height: calc(90vh - 100px);
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.pl-21 {
  padding-left: 21px;
}

.mb-18 {
  margin-bottom: 18px !important;
}

.mr-36 {
  margin-right: 36px;
}

.mr-21 {
  margin-right: 21px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  color: #fff !important;
}

.table-txt-color {
  color: #000 !important;
}

.data-tables .ftDSoT {
  margin-top: 3px;
  min-width: 56px !important;
}

.data-tables .uINPX {
  margin-top: 3px;
  min-width: 56px !important;
}

.order-trading .data-tables .uINPX {
  margin-top: 3px;
  min-width: 115px !important;
}

.order-trading .data-tables .iBSWG {
  padding-left: 8px;
  min-width: 120px !important;
}

.order-trading .data-tables .ftDSoT {
  margin-top: 3px;
  min-width: 115px !important;
}

.order-trading .data-tables .creeua {
  padding-left: 8px;
  min-width: 120px !important;
}

.desc-card-height {
  height: 197px !important;
}

.pt-15 {
  padding-top: 15px;
}

.data-tables .iBSWG {
  padding-left: 8px;
  min-width: 60px !important;
}

.react-datepicker-popper {
  z-index: 99 !important;
}

.placeholder-text-color {
  color: #898989 !important;
  font-size: 13px;
  display: block;
}

.order-transition-but {
  position: absolute;
  right: 10px;
}

.credit-rates .rdt_TableHeadRow [data-column-id="8"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableBody [data-column-id="8"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableHeadRow [data-column-id="7"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableBody [data-column-id="7"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableHeadRow [data-column-id="6"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableBody [data-column-id="6"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableHeadRow [data-column-id="5"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableBody [data-column-id="5"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableHeadRow [data-column-id="4"] {
  flex-grow: 0.8 !important;
}

.credit-rates .rdt_TableBody [data-column-id="4"] {
  flex-grow: 0.8 !important;
}

.mr-27 {
  margin-right: 27rem;
}

.exchange-rates .rdt_TableHeadRow [data-column-id="4"] {
  flex-grow: 0.7 !important;
}

.exchange-rates .rdt_TableBody [data-column-id="4"] {
  flex-grow: 0.7 !important;
}

.exchange-rates .rdt_TableHeadRow [data-column-id="5"] {
  flex-grow: 0.7 !important;
}

.exchange-rates .rdt_TableBody [data-column-id="5"] {
  flex-grow: 0.7 !important;
}

.modal-spinner {
  margin-top: 1.5rem;
  margin-left: 22rem;
}

.highlighted-rate {
  color: #2b7704 !important;
  font-weight: 600 !important;
}

.manage-asm-but {
  position: absolute;
  right: 20px;
}

.aso-dropdown {
  position: absolute;
  right: 20px;
  width: 25%;
}

.disable-field {
  background-color: #f3f3f3 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="1"] {
  flex-grow: 0.5 !important;
}

.ev-charges .rdt_TableBody [data-column-id="1"] {
  flex-grow: 0.5 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="2"] {
  flex-grow: 0.8 !important;
}

.ev-charges .rdt_TableBody [data-column-id="2"] {
  flex-grow: 0.8 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="3"] {
  flex-grow: 0.6 !important;
}

.ev-charges .rdt_TableBody [data-column-id="3"] {
  flex-grow: 0.6 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="4"] {
  flex-grow: 0.6 !important;
}

.ev-charges .rdt_TableBody [data-column-id="4"] {
  flex-grow: 0.6 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="5"] {
  flex-grow: 0.5 !important;
}

.ev-charges .rdt_TableBody [data-column-id="5"] {
  flex-grow: 0.5 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="6"] {
  flex-grow: 0.5 !important;
}

.ev-charges .rdt_TableBody [data-column-id="6"] {
  flex-grow: 0.5 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="7"] {
  flex-grow: 1 !important;
}

.ev-charges .rdt_TableBody [data-column-id="7"] {
  flex-grow: 1 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="8"] {
  flex-grow: 0.5 !important;
}

.ev-charges .rdt_TableBody [data-column-id="8"] {
  flex-grow: 0.5 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="9"] {
  flex-grow: 0.3 !important;
}

.ev-charges .rdt_TableBody [data-column-id="9"] {
  flex-grow: 0.3 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="10"] {
  flex-grow: 0.5 !important;
}

.ev-charges .rdt_TableBody [data-column-id="10"] {
  flex-grow: 0.5 !important;
}


.ev-charges .data-tables .creeua {
  padding-left: 13px !important;
  min-width: 60px !important;
}

.ev-charges .data-tables .iBSWG {
  padding-left: 13px;
}

.ml-20px {
  margin-left: 20px !important;
}


.active-switch {
  float: right;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.active-switch-lable {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
}

.aso-org-dropdown {
  width: 25%;
  float: right;
}

.ev-icon {
  font-size: 25px;
  color: #000000c7;
  width: 25px !important;
  margin-top: 10px;
  margin-left: 22px;
}

.project_data .rdt_TableHeadRow [data-column-id="8"] {
  flex-grow: 1 !important;
}

.project_data .rdt_TableBody [data-column-id="8"] {
  flex-grow: 1 !important;
}

.project_data .rdt_TableHeadRow [data-column-id="7"] {
  flex-grow: 1.3 !important;
}

.project_data .rdt_TableBody [data-column-id="7"] {
  flex-grow: 1.3 !important;
}

.project_data .rdt_TableHeadRow [data-column-id="6"] {
  flex-grow: 1.3 !important;
}

.project_data .rdt_TableBody [data-column-id="6"] {
  flex-grow: 1.3 !important;
}

.project_data .rdt_TableHeadRow [data-column-id="1"] {
  flex-grow: 1.3 !important;
}

.project_data .rdt_TableBody [data-column-id="1"] {
  flex-grow: 1.3 !important;
}

.disable-ev-icon {
  pointer-events: none !important;
  color: #b4b2ba !important;
  cursor: none !important;
  font-size: 25px;
  width: 25px;
  margin-top: 3px;
  margin-left: 12px;
}

.paynow-btn {
  margin-bottom: 1rem;
  margin-left: 64%;
}

.fl-right {
  float: right !important;
}

.ag-header-cell {
  font-size: 13px;
  font-weight: 600;
  /* width: 150px !important; */
  color: #555;
}

.ev-consumption .ag-theme-material .ag-cell-inline-editing,
.ev-station-consumption .ag-theme-material .ag-cell-inline-editing {
  padding: 0px;
  height: 46px;
  background-color: #ebebeb;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
}

.ev-consumption .ag-theme-material input[class^=ag-][type=number],
.ev-station-consumption .ag-theme-material input[class^=ag-][type=number] {
  border-bottom: none !important;
  border-bottom-color: #fff;
  padding-bottom: 0px;
}

.ev-consumption .ag-theme-material .row-style,
.ev-station-consumption .ag-theme-material .row-style {
  background-color: #ebebeb;
}

.ev-consumption .ag-theme-material .text-input-field,
.ev-station-consumption .ag-theme-material .text-input-field {
  display: flex;
  padding: 0px;
  height: 40px;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
  margin-top: 5px;
  padding-left: 10px;
  align-items: center;
}

.action-plan-data .ag-theme-material .text-input-field {
  display: flex;
  padding: 0px;
  height: 80px;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
  margin-top: 5px;
  padding-left: 5px;
  align-items: center;
  line-height: 1.5 !important;
}

.category-field {
  line-height: 1.7 !important;
  margin-top: 10px !important;
}

.action-plan-data .ag-theme-material .text-input-field textarea[class^=ag-] {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-family: inherit;
  border: inherit;
  padding: 0px;
  height: 80px;
}

.ag-large-text-input {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 80px !important;
}

.ag-theme-material textarea[class^=ag-]:focus {}

/* .action-plan-data .ag-theme-material .ag-cell {
  line-height: 1;
} */

.ev-consumption .ag-theme-material .ag-has-focus .ag-cell-inline-editing,
.ev-station-consumption .ag-theme-material .ag-has-focus .ag-cell-inline-editing {
  border-color: #000 !important;
}

.ag-header {
  background-color: #fff !important;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.ag-theme-material {
  --ag-header-background-color: #fff !important;
  width: auto !important;
  --ag-background-color: #fff !important;
  --ag-odd-row-background-color: #fafafa !important;
  --ag-header-foreground-color: #000000de !important;
  --ag-font-family: 'Poppins', sans-serif !important;
}

@media (max-width: 768px) {
  .ev-consumption .ag-theme-alpine {
    height: 800px;
  }
}

.ag-header-cell:not(.ag-header-cell-auto-height) .ag-header-cell-comp-wrapper,
.ag-header-icon {
  color: #000000de !important;
  white-space: normal;
}

.ag-header-cell:not(.ag-column-resizing)+.ag-header-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover,
.ag-header-cell:not(.ag-column-hover):first-of-type:not(.ag-header-cell-moving):hover {
  background-color: #fff !important;
}

.ag-header-cell-menu-button:not(.ag-header-menu-always-show) {
  opacity: 1 !important;
}

.ev-consumption .ag-header {
  width: 100% !important;
  width: 100% !important;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

@media (max-width: 1080px) {
  .ev-consumption .ag-header {
    width: 100% !important;
  }
}

@media (max-width: 1280px) {
  .ev-consumption .ag-header {
    width: 96% !important;
  }
}

.mb-1 {
  margin-bottom: 1rem;
}

.ag-header-cell {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ag-cell {
  display: inline-flex !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  overflow: visible !important;
  font-weight: 400 !important;
}

.waste-collection .ag-theme-material .ag-cell-inline-editing {
  padding: 0px 0px 0px 0px !important;
  height: 46px;
  background-color: #ebebeb;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
}

.waste-collection .ag-theme-material input[class^=ag-][type=number] {
  border-bottom: none !important;
  border-bottom-color: #fff;
  padding-bottom: 0px;
}

.waste-collection .ag-theme-material .row-style {
  background-color: #ebebeb;
}

.waste-collection .ag-theme-material .text-input-field {
  display: flex;
  padding: 0px;
  height: 40px;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
  margin-top: 5px;
  padding-left: 10px;
  align-items: center;
  width: 177px !important;
}

.waste-collection .ag-theme-material .ag-cell-focus .ag-cell-inline-editing,
.biochar-monitoring .ag-theme-material .ag-cell-focus .ag-cell-inline-editing {
  border-color: #000 !important;
}

.ag-select .ag-picker-field-wrapper {
  padding: 0px 0px 0px 5px !important;
  height: 46px;
  background-color: #ebebeb;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
  cursor: default;
}

.waste-collection .ag-header {
  width: 83% !important;
}

.waste-collection .ag-paging-panel {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 55% !important;
}

.upload-icon {
  font-size: 46px;
  color: #37474F;
  width: 28px !important;
  margin-left: 33px;
}

.w-80 {
  width: 80%;
}

.ev-station-consumption .ag-header {
  width: 100% !important;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

@media (max-width: 1080px) {
  .ev-station-consumption .ag-header {
    width: 100% !important;
  }
}

@media (max-width: 1280px) {
  .ev-station-consumption .ag-header {
    width: 96% !important;
  }
}

.ag-theme-material .ag-layout-auto-height .ag-center-cols-clipper {
  min-height: 95px !important;
}

.waste-collection .ag-floating-bottom {
  border-top: none !important;
}

.right-1 {
  right: 1rem;
  position: absolute;
}

.waste-collection .dzu-dropzone {
  flex-direction: row;
  overflow: hidden;
  flex-wrap: wrap;
}

.waste-collection .dzu-dropzone .custom-preview {
  margin: 1rem;
}

.waste-collection .dzu-dropzone .dzu-inputLabelWithFiles {
  align-self: auto;
}

.waste-collection .dzu-dropzone .dzu-submitButtonContainer {
  margin: 24px 0;
  z-index: 1;
  margin-top: 38px;
  margin-left: 1rem;
}


@media (min-width: 1440px) {
  .waste-collection .ag-header {
    width: 100% !important;
  }

  .waste-collection-monthly .ag-header {
    width: 54% !important;
  }
}

.biochar-monitoring .ag-header {
  width: 100% !important;
}

.ag-overlay-loading-center {
  margin-top: 3rem !important;
}

.custom-loading-overlay {
  margin-top: 3rem;
  font-size: 20px;
}

.cell-overflow {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ag-theme-material .ag-standard-button {
  background-color: #335e33 !important;
  color: #fff !important;
  border-radius: 4px;
  padding: 5px !important;
}

.ag-filter-apply-panel {
  padding: 5px !important;
}

.ag-simple-filter-body-wrapper {
  padding: 10px !important;
  max-height: 100px !important;
  overflow-y: hidden !important;
  padding-bottom: 5px !important;
}

.ag-simple-filter-body-wrapper>* {
  margin-bottom: 5px !important;
}

.ag-menu {
  top: 60px !important;
  background-color: #fff !important;
}

.ag-filter .ag-picker-field {
  display: none !important;

}

.ag-filter-apply-panel {
  justify-content: center !important;
}

.ag-header-cell-filtered {
  background-color: #fff !important;
  color: #000000DE !important;
}

.ag-header-cell-filtered span {
  color: #000000DE !important;
}

.ag-icon-filter::before {
  content: "\f0b0" !important;
}

.waste-collection-upload .ag-header {
  width: 100%;
}

.taptop {
  text-align: center;
  cursor: pointer;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: fixed;
  bottom: 35px;
  right: 10px;
  z-index: 5;
  border: 1px solid #fff;
  background: var(--theme-default);
  padding: 7px;
  box-shadow: 0 0 10px var(--theme-default);
  opacity: .5;
  transition: all .3s ease;
}

.taptop:hover {
  transition: all .3s ease;
  opacity: 1;
}

.subOrgList .rdt_TableHeadRow [data-column-id="5"] {
  flex-grow: 1 !important;
}

.subOrgList .rdt_TableBody [data-column-id="5"] {
  flex-grow: 1 !important;
}

.ownership-icon {
  font-size: 23px;
  width: 25px !important;
  margin-top: 10px;
  margin-left: 22px;
}

.ownershipHistory .ag-body-horizontal-scroll-viewport {
  overflow-x: hidden;
}

.ownershipHistory .ag-body-horizontal-scroll {
  display: none;
}

/* @media screen and (min-width: 1440px) {
  .project-data .ag-header {
    width: 100% !important;
  }
} */

.text-overflow {
  white-space: nowrap;
  width: 140px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .ag-hidden {
  display: unset !important;
} */

.bs-none {
  box-shadow: none !important;
}

.pointer-none {
  pointer-events: none !important;
}

.details-table .ag-header-cell:not(.ag-header-cell-auto-height) .ag-header-cell-comp-wrapper,
.ag-header-icon,
.history-table .ag-header-cell:not(.ag-header-cell-auto-height) .ag-header-cell-comp-wrapper,
.ag-header-icon,
.station-table .ag-header-cell:not(.ag-header-cell-auto-height) .ag-header-cell-comp-wrapper,
.ag-header-icon {
  white-space: normal;
}

.text-col-wrap {
  width: 250px !important;
  white-space: normal !important;
  line-height: 17px !important;
}

.station-table .text-col-wrap {
  width: 180px !important;
  white-space: normal !important;
  line-height: 15px !important;
}

.ev-station-consumption {}

.ptop-2 {
  padding-top: 2rem;
}

.data-grid-container .data-grid .cell>input {
  outline: none !important;
  border: 2px solid rgb(33, 133, 208);
  text-align: right;
  width: calc(100% - 1px) !important;
  height: 100% !important;
  background: none;
  display: block;
}

.data-grid-container .data-grid .cell {
  height: 22px !important;
  background-color: #fff !important;
}

.menu-style {
  background: aliceblue !important;
  color: #335e33 !important;
}


.side-menu {
  background: aliceblue !important;
  color: #335e33 !important;
  text-align: right !important;
}

.total-row {
  font-size: 16px;
  font-weight: 500;
  color: #335e33 !important;
  text-align: right !important;
}

.tot-menu {
  font-size: 16px;
  font-weight: 500;
}


.datepicker-control {
  border-color: rgba(43, 119, 4, 0.1019607843);
  font-size: 14px;
  padding: 3px 15px;
  border-radius: 0px;
  border: 1px solid #ced4da !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  width: 75% !important;
  background-color: #fff;
}

.width-175px {
  width: 175px !important;
}

.word-text {
  overflow: visible !important
}

.justify-end {
  justify-content: flex-end;
}

@media screen and (min-width: 992px) {
  .modal-xl {
    --bs-modal-width: 1000px;
  }
}

.add-row {
  margin-top: -10px;
  width: 20px;
  height: 17px;
  margin-bottom: 12px;
  font-size: 25px;
}

.d-flex-row {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 576px) {
  .summary-modal .modal-xl {
    --bs-modal-width: 540px;
  }
}

.f-12 {
  font-size: 12px;
}

.ml-12 {
  margin-left: 12rem;
}

.mb-15 {
  margin: 15px;
}

.pro-card {
  height: 25rem;
  width: 30rem;
}

.d-inline-flex {
  display: inline-flex;
}

.card-title {
  font-size: 20px;
  font-weight: 700;
  color: #555;
  margin-bottom: 0px;
}

.card-certify {
  font-style: italic;
  font-size: 12px;
  color: #555;
  font-weight: 400;
  letter-spacing: 0px;
}

.m-5 {
  margin: 5px;
}

.card-margin {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  margin-left: 5px !important;
}

.m-0 {
  margin: 0px !important;
}

.card-outline {
  border-radius: 0px !important;
  border: 2px solid #000 !important;
}

.mtop-10 {
  margin-top: 10px;
}

.card-para {
  font-size: 12px;
  line-height: 1.5;
  text-align: justify;
}

.card-para-overflow {
  margin-bottom: 10px;
  height: 2.3rem;
  overflow: hidden;
}

.card-country {
  color: #110d8d;
  font-size: 11px;
  font-weight: 600;
}

.country-div {
  background: #b0b8da63;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 7px
}

.card-project-type {
  color: #620d8d;
  font-size: 11px;
  font-weight: 600;
}

.project-type-div {
  background: #c5b2d363;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 7px
}

.card-price {
  color: #0b6b0a;
  font-size: 11px;
  font-weight: 600;
}

.price-div {
  background: #96b3954a;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 7px
}

.card-img-div {
  height: 14rem;
  width: 24rem
}

.card-img {
  height: 12rem;
  width: 100% !important;
  object-fit: cover;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.card-col-outline {
  padding-left: 6px !important;
  padding-right: 0px !important;
}

.project-type {
  color: #956710f7;
  font-size: 11px;
  font-weight: 600;
}

.project-type-details {
  background: #f5cf867d;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 7px
}

.buyoffset-card-padding {
  padding: 20px 10px 20px 10px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.proj-details .css-t3ipsp-control:hover {
  border-color: #ced4da !important;
}

.proj-details .css-13cymwt-control {
  min-height: 47px;
  border-width: 1px;
  border-radius: 8px;
}

.proj-details .css-t3ipsp-control {
  min-height: 47px;
  border-width: 2px;
  border-radius: 8px;
}

.proj-details .css-t3ipsp-control:active {
  box-shadow: 0 0 0 1px #ced4da !important;
}

.proj-details .checkbox-style {
  margin-top: 3px;
}

.offset-details .project-desc {
  font-size: 15px;
  line-height: 1.7;
  text-align: justify;
}

.border-1 {
  border: 1px solid;
}

.price-details {
  font-size: 13px;
  font-weight: 800;
}

.line-div {
  position: relative;
  width: 100%;
  height: 2px;
  border-bottom: 2px solid black;
}

.available-style {
  font-size: 11px;
  letter-spacing: 0.5px;
}

.mb1 {
  margin-bottom: 1rem;
}

.f-11 {
  font-size: 11px !important;
}

.order-button {
  width: 93%;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.project-pic {
  width: 130px;
  /* padding: 10px; */
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border: 1px solid #335e33;
  text-align: center;
  background-color: #335e33;
  cursor: pointer;
  margin-left: 1rem;
  color: #fff;
  padding: 0.5rem 1rem;
  font-weight: 600;
  border-radius: 8px;
  font-size: 15px;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
}

.box-shadow-0 {
  box-shadow: 0 0 0 !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.f-18 {
  font-size: 18px;
}

.mtop-4 {
  margin-top: 4rem;
}

.ml-5 {
  margin-left: 5rem;
}

.ml-3r {
  margin-left: 3rem;
}

.emission-trash {
  font-size: 10px;
  color: #DC3545;
  width: 18px !important;
  margin-top: 10px;
}

.ag-select .ag-picker-field-wrapper {
  height: 35px !important;
  width: 6.5rem;
}

.ag-paging-panel>.ag-paging-page-size .ag-wrapper {
  min-width: 80px !important;
}

.p-15 {
  padding: 15px !important;
}

.buy-offset-shadow {
  box-shadow: 1px 6px 18px -4px rgba(0, 0, 0, 0.21) !important;
  transition: 0.2s all;
  cursor: pointer !important;
}

.buy-offset-shadow:hover {
  box-shadow: 1px 6px 37px -4px rgba(0, 0, 0, 0.43) !important;
}

.span-text {
  font-size: 15px;
  font-weight: 600;
}

.br-10 {
  border-radius: 10px;
}

.project-count {
  justify-content: flex-end;
  margin-top: 25px;
}

.project-count p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0px !important;
  font-style: italic;
  color: #555;
  letter-spacing: 0px;
}


/* .trading-table .ag-row-hover:not(.ag-full-width-row)::before,
.ag-row-hover.ag-full-width-row.ag-row-group::before {
  position: unset !important;
} */

.ag-icon-desc,
.ag-icon-menu,
.ag-icon-asc {
  color: #000000de !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #dfe8dd !important;
  font-weight: 600 !important;
  border-radius: 20px !important;
  color: #335e33 !important;
  letter-spacing: 0px !important;
}

.nav-link {
  color: #555;
  font-weight: 500;
  letter-spacing: 0px;
}

.nav-link:hover,
.nav-link:focus {
  color: #335e33 !important;
}

.overview-title {
  font-size: 13px;
  color: #545454;
}

.i-color {
  color: #545454cc !important;
}

.p-relative {
  position: relative;
}

.project-button-div {
  position: absolute;
  top: 0;
  right: 306px;
}

.w-200 {
  width: 200px;
}

.logo-pic {
  width: 170px;
  /* padding: 10px; */
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border: 1px solid #335e33;
  text-align: center;
  background-color: #335e33;
  cursor: pointer;
  margin-left: 1rem;
  color: #fff;
  padding: 0.5rem 1rem;
  font-weight: 600;
  border-radius: 8px;
  font-size: 15px;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
}

.logo-notes {
  color: #545454;
  line-height: 20px;
  font-size: 13px;
}

.mtop-2-half {
  margin-top: 2.5rem !important;
}

.test:after {
  content: '\2807';
  font-size: 100px;
}

/* .dropdown-toggle::after {
  display: none !important;
} */

.btn-none {
  background-color: #fff !important;
  border: none;
  color: #555;
}

.br-1 {
  border: 1px solid #555 !important;
}

.image_wrapper {
  position: relative;
  width: 75px !important;
  height: 40px !important;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #3a6e9c9a;
  overflow: hidden;
  width: 94%;
  height: 0;
  transition: .5s ease;
}

.image_wrapper:hover .overlay {
  bottom: 0;
  height: 100%;
}

.text {
  color: white;
  font-size: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.mt-5px {
  margin-top: 5px !important;
}

.ag-cell-value {
  display: inline-block !important;
}

.waste-collection .ag-paging-page-size {
  display: none !important;
}

.waste-collection-monthly .ag-theme-material .ag-cell-inline-editing,
.biochar-monitoring .ag-theme-material .ag-cell-inline-editing {
  padding: 0px 0px 0px 0px !important;
  height: 46px;
  background-color: #ebebeb;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
}

.waste-collection-monthly .ag-theme-material input[class^=ag-][type=number],
.waste-collection-monthly .ag-theme-material input[class^=ag-][type=number] {
  border-bottom: none !important;
  border-bottom-color: #fff;
  padding-bottom: 0px;
}

.waste-collection-monthly .ag-theme-material .row-style, .biochar-monitoring .ag-theme-material .row-style {
  background-color: #ebebeb;
}

.waste-collection-monthly .ag-theme-material .text-input-field  {
  display: flex;
  padding: 0px;
  height: 40px;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
  margin-top: 5px;
  padding-left: 10px;
  align-items: center;
  width: 177px !important;
}

.biochar-monitoring .ag-theme-material .text-input-field {
  display: flex;
  padding: 0px;
  height: 40px;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
  margin-top: 5px;
  padding-left: 10px;
  align-items: center;
  /* width: 10rem !important; */
}

.waste-collection-monthly .ag-theme-material .ag-has-focus .ag-cell-inline-editing,
.biochar-monitoring .ag-theme-material .ag-has-focus .ag-cell-inline-editing {
  border-color: #000 !important;
}

.ag-select .ag-picker-field-wrapper {
  padding: 0px 0px 0px 5px !important;
  height: 40px !important;
  background-color: #ebebeb;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
  cursor: default;
}

.waste-collection-monthly .ag-header {
  width: 51% !important;
}

.waste-collection-monthly .ag-paging-panel, .biochar-monitoring .ag-paging-panel {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 55% !important;
}

.waste-collection-monthly .ag-floating-bottom {
  width: 51% !important;
}


.biochar-monitoring .ag-floating-bottom {
  width: 100% !important;
}

@media screen and (max-width: 2600px) {
  .waste-collection .ag-row {
    width: 89% !important;
  }
}

.cell-wrap {
  white-space: normal !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center !important;
  display: flex !important;
  align-items: center;
}

.file-upload {
  position: absolute !important;
  left: 74% !important;
}

.mtop-6 {
  margin-top: 4rem !important;
}

.review-span {
  font-size: 11px;
  font-style: italic;
}

.w-14 {
  width: 14rem !important;
}

.ptop-1 {
  padding-top: 1rem !important;
}

.input-percent {}

.input-percent .input-group {
  flex-wrap: nowrap !important;
}

.input-percent .input-group-text {
  background: transparent !important;
  border: 1px solid #ced4da !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-radius: 0px !important;
  padding-left: 0px !important;
}

.percent-padding {
  padding: 3px 0px 3px 15px !important;
}

.right-button-view {
  position: absolute;
  right: 190px !important;
  top: 0px
}

.right-button-40px {
  position: absolute;
  right: 40px !important;
}

.text-right-imp {
  text-align: right !important;
  color: #514f4f !important;
}

.styles-module_tooltip__mnnfp {
  padding: 0px 5px !important;
  border-radius: 0px !important;
  font-size: 13px !important;
  width: max-content !important;
  /* background-color: #6689a699 !important; */
}

.minus-button {
  position: absolute;
  right: 20px;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.goal-box {
  padding: 5px 85px !important;
  font-size: 25px !important;
  font-weight: 700;
  background-color: #bcd8b4f7;
  border-radius: 5px;
}

.goal-name {
  margin-left: 2rem;
  font-size: 17px;
  font-weight: 700;
}

.mb-2rem {
  margin-bottom: 2rem;
}

.goal-item-card {
  background: #f4f4f4;
  padding-top: 15px;
  padding-bottom: 25px;
  padding-left: 25px;
  border-radius: 7px;
}

.loading-text {
  margin-top: 3rem;
  position: absolute;
  width: 100%;
}

.active-dd-color {
  color: #6ee070 !important;
}

.active-drop-color {
  color: #000 !important;
}

.mr-5px {
  margin-right: 5px;
}

.ag-theme-material input[class^=ag-]:not([type]),
.ag-theme-material input[class^=ag-][type=text],
.ag-theme-material input[class^=ag-][type=number],
.ag-theme-material input[class^=ag-][type=tel],
.ag-theme-material input[class^=ag-][type=date],
.ag-theme-material input[class^=ag-][type=datetime-local],
.ag-theme-material textarea[class^=ag-] {
  border-bottom-color: #335e33 !important;
}

.pad-5px {
  padding: 6px 3px 6px 6px;
}



.ml-1-5 {
  margin-left: 1.5rem;
}

.top-0 {
  top: 0px;
}

.data-grid-container .data-grid .cell.read-only {
  background: #f4f4f4 !important;
  color: #051a1a !important;
  font-weight: 500;
}

.offset-card-bg {
  background-color: #fff !important;
}

.padd-8px {
  padding: 8px;
}

.simplebar-content-wrapper {
  height: auto !important;
  overflow: auto !important;
}

.pad-top-25 {
  padding-top: 25px !important;
}

.ptop-0 {
  padding-top: 0px !important;
}

.logout-button {
  border: 1px solid #335e33;
  padding: 8px 15px;
  border-radius: 50px;
  color: #335e33;
}

.logout-button:hover {
  background-color: #335e33 !important;
  color: #fff !important;
}

.but-sec {
  background-color: #335e330f !important;
  border-color: #335e330f !important;
  color: #212529;
}

.project-img:hover .project-img-hover {
  opacity: 1;
  border-radius: 0;
  transform: scale(1);
  transition: all .3s ease;
}

.project-img .project-img-hover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .3);
  opacity: 0;
  border-radius: 100%;
  transform: scale(0);
  transition: all .3s ease;
}

.project-img .project-img-hover div {
  display: inline-block;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, .08);
  padding: 10px 13px;
  background-color: #fff;
  font-size: 18px;
  border-radius: 10px;
  height: 45px;
  width: 45px;
  margin: 0 3px;
  cursor: pointer;
}

.project-img .project-img-hover div:hover {
  background-color: var(--theme-default);
  transition: all .3s ease;
  color: #fff;
}

.data-grid-container .data-grid .cell {
  font-size: 12px;
}

.f-s-20 {
  font-size: 20px;
}

.mtop-0 {
  margin-top: 0 !important;
}

.welcome-content span {
  color: #52526ccc;
  font-size: 14px;
}

.credits-table {
  font-size: 20px;
  font-weight: 600;
  color: #051a1a
}

.location-body {
  text-align: left;
  padding: 3px 6px !important;
}

.location .table-bordered tr {
  font-size: 13px !important;
}

.location .table td,
.location .table th {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  font-size: 13px !important;
}

.location-link:hover {
  color: #335e33 !important;
}

.target-header {
  background: #3fb097 !important;
  color: #fff !important;
}

.summary-button {
  position: absolute;
  right: 20px;
  top: 30px
}

.map-pin {
  font-size: 18px;
  color: #335e33;
}

.map-pin:hover {
  color: #335e33;
}

.subscribe-card {
  display: flex;
  place-content: center;
  justify-content: center;
  align-items: center;
  min-height: 80vh
}

.w-55 {
  width: 55% !important;
}

.mr-4r {
  margin-right: 4rem;
}

.dashboard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sale-credit {
  width: 100%;
}

.sale-credit p {
  text-align: left;
  font-size: 16px;
}

.clr-white {
  color: #fff !important;
}

.sales-card {
  border-width: 2px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border-bottom: 1px solid;
}

.sales-card .bubbles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  top: 0;
  left: 0;
}

.sales-card .bubble {
  height: 25px;
  width: 25px;
  border-radius: 15px;
  /* background-color: #ffae1a1a; */
  position: absolute;
  animation: floating 10s ease-in infinite;
}


.sales-card .bubble :first-child {
  height: 25px;
  width: 25px;
  border-radius: 15px;
  left: 10%;
  animation-duration: 18s;
}

.sales-card .bubble:nth-child(2) {
  height: 45px;
  width: 45px;
  border-radius: 50px;
  left: 20%;
  animation-duration: 20s;
  animation-delay: 1s;
}

.sales-card .bubble:nth-child(3) {
  height: 35px;
  width: 35px;
  border-radius: 50px;
  left: 65%;
  animation-duration: 12s;
  animation-delay: 2s;
}

.sales-card .bubble:nth-child(4) {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  left: 34%;
  animation-duration: 6s;
  animation-delay: 1s;
}

.sales-card .bubble:nth-child(5) {
  height: 20px;
  width: 20px;
  border-radius: 15px;
  left: 5%;
  animation-duration: 10s;
  animation-delay: 1s;
}

.sales-card .bubble:nth-child(6) {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  left: 14%;
  animation-duration: 12s;
  animation-delay: 1s;
}

.sales-card .bubble:nth-child(7) {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  left: 41%;
  animation-duration: 16s;
  animation-delay: 1s;
}

.sales-card .bubble:nth-child(8) {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  left: 3%;
  animation-duration: 11s;
  animation-delay: 1s;
}

.sales-card .bubble:nth-child(9) {
  height: 25px;
  width: 25px;
  border-radius: 15px;
  left: 48%;
  animation-duration: 18s;
  animation-delay: 1s;

}

@keyframes floating {

  0% {
    top: -100px;
    transform: translateX(0);
  }

  50% {
    transform: translateX(50px);
  }

  100% {
    top: 156px;
    transform: translateY(0px);
  }
}

.f-s-30 {
  font-size: 30px;
}

.sales-card:hover .icon-shake .icon {
  animation-name: walShake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 5s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

@keyframes walShake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%,
  24% {
    transform: rotate(-18deg);
  }

  12%,
  28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%,
  100% {
    transform: rotate(0deg);
  }
}

.p-t-1-5r {
  padding-top: 1.5rem !important;
}

.minimize-icon {
  stroke: #86909c;
  fill: none;
  height: 20px;
  width: 16px !important;
}

.maximize ::before {
  content: "";
  top: 0;
  left: 39px;
  height: 30px;
  width: 1px;
  /* background-color: #e6e9eb; */
  position: absolute;
}

/* .list-group-item:last-child .nav-sub-childmenu::before {
  content: '';
  position: absolute;
  width: 10px !important;
  height: 84px !important;
  background-color: #335e33 !important;
  top: -16px;
  left: 18px;
} */

.ag-theme-material .ag-layout-auto-height .ag-center-cols-viewport,
.ag-theme-material .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-material .ag-layout-print .ag-center-cols-viewport,
.ag-theme-material .ag-layout-print .ag-center-cols-container {
  min-height: 50px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.project-img .project-img-hover .icon-div:hover svg {
  stroke: #fff;
}

.mtop-1-hf {
  margin-top: 1.5rem;
}

.cur-pointer {
  cursor: pointer;
}

.export-overlay {
  width: 150px;
  top: 60px;
  left: 15px;
  padding: 15px 10px;
  position: absolute;
  z-index: 8;
  background-color: #fff;
  transition: all linear 0.3s;
}

.export-overlay li {
  padding: 10px;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  color: #2c323f;
}

.f-s-17 {
  font-size: 17px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.coming-soon-text {
  display: flex;
  place-content: center;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.f-s-60 {
  font-size: 60px;
}

.bell-icon {
  color: #fbb52c !important;
}

.dashboard-content .fa-shake {
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 5s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.report-download-but {
  position: absolute;
  right: 20px;
  top: 25px;
}

.spinner-color {
  color: #335e33 !important;
}

.justify-center {
  justify-content: center !important;
}

.ml-0-25 {
  margin-left: 0.25rem !important;
}

.f-w-600 {
  font-weight: 600 !important;
}

.verticle-middle {
  vertical-align: middle !important;
}

.disabled-button {
  opacity: 0.65;
  pointer-events: none;
}

.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.mtop-5 {
  margin-top: 5px;
}

.line-div-5 {
  position: relative;
  width: 100%;
  height: 5px;
  border-bottom: 1px solid #a2a5a9;
}

.mtop-4px {
  margin-top: 4px;
}

.f-w-500 {
  font-weight: 500 !important;
}

.w-7rem {
  width: 7rem;
}

.w-21rem {
  width: 21rem;
}

.w-5-5 {
  width: 5.5rem;
}

.w-6 {
  width: 6rem;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #335e33 !important;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.project-form .react-datepicker-wrapper {
  width: 100% !important;
}

.round-badge {
  border-radius: 999px !important;
  padding: 2px 4px !important;
  vertical-align: top !important;
  position: absolute;
  /* left: 115px; */
  margin-top: 8px;
}

.evidence-icon {
  font-size: 14px;
  color: #2b7704;
  width: 15px !important;
  margin-left: 1.5rem;
  cursor: pointer;
  margin-top: 16px;

}

.w-39 {
  width: 39rem !important;
}

.template-download {
  text-decoration: underline;
  cursor: pointer;
}

.doc-download {
  font-size: 14px;
  width: 18px !important;
  cursor: pointer;
  color: #fff;
  margin-right: 0.5rem;
}

.mr-min-5 {
  margin-right: -5rem !important;
}

.min-w-75 {
  min-width: 75px !important;
}

.min-w-100 {
  min-width: 100px !important;
}

.min-w-200 {
  min-width: 200px !important;
}

.pos-fixed {
  position: fixed !important;
}

span.data-grid-container,
span.data-grid-container:focus {
  display: block;
  overflow-x: scroll;
  scrollbar-width: thin;
}

.min-w-80 {
  min-width: 80px !important;
}

.table-container {
  overflow-x: auto;
}

.table-container .scopeLabel {
  z-index: 3;
}

.scopeLabel {
  position: sticky;
  left: 0;
  z-index: 2;
}

.categoryLabel {
  position: sticky;
  left: 75px;
  z-index: 2;
}

.cat-title-label {
  position: sticky;
  left: 75px;
  z-index: 3 !important;
}

.scrolling-alert-container {
  width: 100%;
  overflow: hidden;
  padding: 0px;
  background-color: #fff;
  border-radius: 20px;
  height: 38px;
  border: 2px solid #335e33;

}

.scrolling-alert-news {
  white-space: nowrap;
  padding: 6px 0px;
  animation: marquee linear infinite;
}

.news-item {
  display: inline-block;
  margin-right: 30px;
  /* Adjust as needed */
}

@keyframes marquee {
  0% {
    transform: translateX(30%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.f-s-18 {
  font-size: 18px;
}

.news-title {
  padding: 8px 9px 8px 23px;
  background-color: #335e33;
  color: #fff;
  z-index: 9
}

.w-50 {
  width: 50%;
}

.scrolling-alert-news:hover {
  animation-play-state: paused;
}

.f-s-16 {
  font-size: 16px;
}

.scope1-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto auto;
  width: 300px;
}

.scope2-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  width: 120px;
  position: absolute;
  left: 558px;
}

.scope3-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto;
  width: 240px;
  position: absolute;
  left: 678px;

}


.item {
  background-color: #f0f0f0;
  border: 1px solid #333;
  padding: 15px;
  box-sizing: border-box;
}

.scope1-container .item:first-child {
  grid-column: span 5;
}

.scope2-container .item:first-child {
  grid-column: span 2;
}

.scope3-container .item:first-child {
  grid-column: span 4;
}

.box-bg {
  background-color: #ffd93f;
}

.com-box-bg {
  background-color: #15bb12 !important;
}

.pad-10 {
  padding: 10px !important;
}

.ml-10 {
  margin-left: 10px;
}

.ag-datasheet .ag-root-wrapper {
  border: 1px solid #000000c9 !important;
  border-right: 1px solid #00000000 !important;
}

.ag-datasheet .ag-header {
  min-height: 35px !important;
  height: 35px !important;
  border: solid 1px #000000c9 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.ag-datasheet .ag-theme-material {
  --ag-header-height: 50px !important;
}

.ag-datasheet .ag-header-group-cell-label,
.ag-datasheet .ag-header-cell-label {
  align-items: center !important;
  align-self: center !important;
  justify-content: center !important;
}

.ag-datasheet .ag-header-cell {
  font-weight: 500 !important;
  height: 35px !important;
  border-right: 1px solid #000 !important;
  background-color: #335e33 !important;
}

.ag-datasheet .ag-cell {
  display: flex !important;
  align-items: center !important;
  --ag-internal-padded-row-height: 20px !important;
  border-right: 1px solid #000000c9 !important;
}

.ag-datasheet .ag-theme-material .ag-cell-inline-editing {
  padding: 0px !important;
  height: 35px;
  border: 1px solid #335e33 !important;
}

.ag-datasheet .ag-input-field {
  min-height: 27px !important;
  height: 28px !important;
}

.ag-datasheet .ag-theme-material input[class^=ag-]:not([type]),
.ag-datasheet .ag-theme-material input[class^=ag-][type=text],
.ag-datasheet .ag-theme-material input[class^=ag-][type=number],
.ag-datasheet .ag-theme-material input[class^=ag-][type=tel],
.ag-datasheet .ag-theme-material input[class^=ag-][type=date],
.ag-datasheet .ag-theme-material input[class^=ag-][type=datetime-local],
.ag-datasheet .ag-theme-material textarea[class^=ag-] {
  /* border-bottom: 1px solid #dee2e6 !important; */
  border-bottom: 1px solid #335e33 !important;
  background-color: #fff !important;
  display: flex !important;
  height: 28px !important;
  padding-bottom: 5px !important;
  margin-top: 12px !important;

}

.ag-datasheet .ag-theme-material {
  --ag-border-color: color-mix(in srgb, transparent, var(--ag-foreground-color) 15%);
  --ag-row-border: solid 1px var(--ag-border-color);
  --ag-header-border: var(--ag-row-border);
  --ag-column-border: solid 1px var(--ag-border-color);
  --ag-column-header-border: var(--ag-column-border);
  --ag-foreground-color: #181d1f;
  --ag-odd-row-background-color: #fff !important;
}

[class^=ag-],
[class^=ag-]:focus {
  box-shadow: none !important;
}

.ag-datasheet .ag-theme-material .ag-has-focus .ag-cell-inline-editing {
  border-color: #335e33 !important;
}

.ag-custom-footer {
  background: #fafafa;
  padding: 15px;
  border: 1px solid #000000c9;
  border-top: none !important;
}

.add-row-button {
  border: 1px solid color-mix(in srgb, transparent, #181d1f 15%) !important;
  display: flex !important;
}

.mr-10 {
  margin-right: 10px;
}

.border-1px-solid {
  border: 1px solid #000;
}

.input-number {
  width: 3rem;
}

.mobile-diesel-percent {
  display: grid;
  justify-content: center;
}

.ml-42 {
  margin-left: 42px;
}

.ag-group-header .ag-datasheet .ag-header {
  min-height: 70px !important;

}

.ag-group-header .ag-datasheet .ag-header-group-cell {
  height: 35px !important;
  border-right: 1px solid #000 !important;
  background-color: #335e33 !important;
}

.ag-group-header .ag-datasheet .ag-header-cell:first-child {
  height: 70px !important;
}

.ag-group-header .ag-ref-simply-table .ag-datasheet .ag-header-cell:nth-child(2),
.ag-group-header .fs-simply-table .ag-datasheet .ag-header-cell:nth-child(2) {
  height: 70px !important;
}

.ag-group-header .ag-ref-simply-table .ag-datasheet .ag-header-cell:nth-child(7) {
  height: 70px !important;
}

.ag-group-header .ag-ref-screening .ag-datasheet .ag-header-cell:nth-child(2) {
  height: 70px !important;
}

.ag-group-header .ag-ref-screening .ag-datasheet .ag-header-cell:nth-child(7) {
  height: 70px !important;
}

.ag-group-header .ag-ref-screening .ag-datasheet .ag-header-cell:nth-last-child(3) {
  height: 70px !important;
}

.ag-group-header .ag-ref-simply-table .ag-datasheet .ag-header-cell:nth-last-child(4) {
  height: 35px !important;
}

.ag-group-header .ag-datasheet .ag-header-cell:nth-last-child(2),
.ag-group-header .ag-datasheet .ag-header-cell:nth-last-child(3) {
  height: 70px !important;
}

.ag-group-header .ag-datasheet .ag-header-cell:last-child {
  height: 70px !important;
}

.ag-group-header .table-3 .ag-datasheet .ag-header-cell:nth-child(3) {
  height: 70px !important;
}

.ag-group-header .table-3 .ag-datasheet .ag-header-cell:nth-child(4) {
  height: 35px !important;
}

/* 
.ag-group-header .electricity-table .ag-datasheet .ag-header-cell:last-child {
  height: 35px !important;
}

.ag-group-header .electricity-table .ag-datasheet .ag-header-cell:nth-child(2) {
  height: 70px !important;
}

.ag-group-header .electricity-table .ag-datasheet .ag-header-cell:nth-child(3) {
  height: 70px !important;
}

.ag-group-header .electricity-table .ag-datasheet .ag-header-cell:nth-child(4) {
  height: 70px !important;
}

.ag-group-header .electricity-table .ag-datasheet .ag-header-cell:nth-child(5) {
  height: 70px !important;
}

.ag-group-header .electricity-table .ag-datasheet .ag-header-cell:nth-last-child(2) {
  height: 70px !important;
} */

.ag-group-header .steam-table .ag-datasheet .ag-header-cell,
.ag-group-header .electricity-table .ag-datasheet .ag-header-cell {
  height: 70px !important;
  border-right: 1px solid #000 !important;
}

/* .ag-datasheet .ag-cell:last-child {
  border-right: none !important;
}

.ag-datasheet .ag-header-cell:last-child {
  border-right: none !important;
} */

.accordion-button:not(.collapsed) {
  color: #335e33 !important
}

.ag-select-list {
  max-height: 200px !important;
  scrollbar-width: thin;
}

.w-100 {
  width: 100%;
}

.disable-cell {
  background-color: #fafafa !important;
}

.notification-container {
  margin-top: 7rem !important;
}

.purchased-summary {
  position: absolute;
  left: 46rem;
}

.group-header-class .ag-header-cell-text {
  position: absolute;
  top: 10px;
}

.electricity-table .group-header-class .ag-header-cell-text {
  position: absolute;
  top: 1px;
}

.steam-table .group-header-class .ag-header-cell-text {
  position: absolute;
  top: 1px;
}

.group-header-class .ag-group-header .steam-table .ag-datasheet .ag-header-cell {
  height: 35px !important;
}

.icon-center {
  display: flex !important;
  justify-content: center !important;
}

.border-top-group-header-cell {
  border-top: solid 1px #000 !important;
}

.group-header-class {
  border-top: solid 1px #000 !important;
}

.group-header-align {
  display: flex !important;
  justify-content: center !important;
}

.egrid-notes {
  font-size: 13px;
  font-weight: 600;
}

.bg-f5f5f5 {
  background-color: #f5f5f5 !important;
}

.edit-percent {
  font-size: 18px;
  cursor: pointer;
}

.ag-cell-wrap-text {
  word-break: break-word !important;
}

.br-black {
  border-right: solid 1px #000 !important;
}

.ag-datasheet .ag-header-cell-text {
  color: #fff !important;
}

.ag-datasheet .ag-header-group-text {
  color: #fff !important;
  font-size: 13px !important;
}

.ag-datasheet .ag-header-cell:not(.ag-column-resizing)+.ag-header-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover,
.ag-datasheet .ag-header-cell:not(.ag-column-hover):first-of-type:not(.ag-header-cell-moving):hover {
  background-color: #335e33 !important;
}

.tick-center {
  display: flex !important;
  justify-content: center !important;
}

.ag-datasheet .ag-select-list {
  background-color: #dff4f4 !important
}

.f-s-15 {
  font-size: 15px;
}

.profile-pic {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.w-11-hf {
  width: 11.5rem !important;
}

.ml-10 {
  margin-left: 10px;
}

.ag-date-field-input {
  border-bottom: none !important;
  margin-top: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.customer-view .browser-table table tr td {
  font-size: 15px !important;
}

.checkbox-container .form-check-input[type=checkbox] {
  opacity: 1;
}

.checkbox-container .form-check-input:checked,
.checkbox-container .form-check-input[type=checkbox]:indeterminate {
  background-color: #335e33;
  border-color: #335e33;
}

.faq-icon-box {
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 5;
  border: 1px solid #fff;
  background: var(--theme-default);
  padding: 7px;
  box-shadow: 0 0 10px var(--theme-default);
}

.faq-icon {
  position: relative;
  color: #fff !important;
  top: 14px
}

.chat-icon {
  position: relative;
  width: 30px;
  height: 21px;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.chat-icon::after {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 19px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 0px 0px 7px;
  border-color: #fff transparent transparent transparent;
  transform: rotate(-31deg);
}

.question-mark {
  font-size: 12px;
  color: white;
  font-weight: bold;
  position: absolute;
}

.tooltip-arrow {
  background-color: transparent !important;
}

.tooltip.bs-tooltip-top .tooltip-arrow:before,
.tooltip.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow:before {
  border-top-color: #000000e6 !important;
}

.tooltip .tooltip-inner {
  background-color: #000000e6 !important;
  color: #fff !important;
}

.tooltip-inner {
  padding: 3px 6px !important;
  font-size: 12px;
}


.chat-popup {
  /* display: block; */
  position: fixed;
  bottom: 50px;
  right: 30px;
  width: 320px;
  max-width: 90%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1000;
  height: 24rem;
  background-color: #f2f7f7;
}

.chat-popup .show {
  display: block;
}

.chat-header {
  background-color: #188674;
  color: #fff;
  padding: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #188674;
}

.chat-header h2 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}

.chat-body {
  padding: 15px;
  max-height: 22rem;
  overflow-y: hidden;
}

.chat-body .messages {
  height: 16rem;
}

.chat-footer {
  padding: 8px 15px 30px 15px;

}

.close-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 12px;
  cursor: pointer !important;
  position: relative;
  bottom: 6px;
  right: -4px;
}

.chat-popup .chat-icon {
  background-color: #fff;
  border: 2px solid #fff;
}

.chat-popup .question-mark {
  color: #335e33;
}

.chat-popup p {
  font-size: 12px;
  line-height: 1.7;
  letter-spacing: 0.7px;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.chat-popup .question {
  color: #000000;
  font-weight: bold;
}

.chat-popup a {
  position: fixed;
  text-decoration: none;
  letter-spacing: 0.5px;
  color: #335e33 !important;
}

.chat-popup a:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.accordion-button {
  font-size: 13px !important;
}

.faq-card {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 14rem;
  height: 10rem !important;
  font-size: 17px;
  border: 1px solid #d8d0d0;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  vertical-align: middle;
  flex-direction: column;
}

.color-dark-primary {
  color: #043d3d !important;
}

.faq .accordion-button:not(.collapsed) {
  background-color: #fff !important;
  color: #000000 !important;
}

.faq .accordion-button {
  font-size: 15px !important;
  font-weight: 500;
}

.faq .accordion-button::after {
  background-image: url(../images/plus.svg);
  background-size: 13px;
}

.faq .accordion-button:not(.collapsed)::after {
  background-image: url(../images/minus.svg);
  background-size: 13px;
}

.progress-stick {
  position: relative;
  left: 245px;
  width: 20%;
  height: 12px;
  background: rgba(43, 119, 4, 0.1019607843);
  top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.t-125px {
  top: 125px !important;
}

.t-240px {
  top: 240px !important;
}

.t-356px {
  top: 356px !important;
}

.step-4 {
  width: 20% !important;
  left: 156px !important
}

.step-10 {
  top: 352px;
  left: 370px;
}

.t-352px {
  top: 352px;
}

.l-370px {
  left: 370px
}

.w-17 {
  width: 17%;
}

.bar-border-radius {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.l-155px {
  left: 155px !important
}

.pro-tracker {
  height: 22rem;
  margin-top: 3rem;
}

.step-9-text {
  position: absolute;
  width: 61%;
  left: 40px;
  word-wrap: break-word;
}

.step-11-text {
  position: absolute;
  width: 60%;
  left: 20px;
  word-wrap: break-word;
}

.right-rounded {
  width: 10%;
  height: 112px;
  background-color: transparent;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  border: 12px solid rgba(43, 119, 4, 0.1019607843);
  border-left: none;
  position: relative;
  left: 80.2%;
  top: 11px;
}

.left-rounded {
  width: 11%;
  height: 111px;
  background-color: transparent;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  border: 12px solid rgba(43, 119, 4, 0.1019607843);
  border-right: none;
  position: relative;
  right: -13%;
  top: -2px;
}

.progress-tracker .step-icon {
  background: #fff;
  border: 1px solid hsla(0, 0%, 50%, .761);
  color: hsla(0, 0%, 50%, .761);
  font-size: 12px !important;
  height: 50px !important;
  padding: 9px 2px !important;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.active-bound {
  border-color: #188674 !important;
}

.w-100px {
  width: 100px !important;
}

.w-94px {
  width: 94px !important;
}

.tracker-complete-icon {
  /* background: #188674 !important; */
  background-color: #de9300 !important;
}

.tracker-end {
  position: relative;
  background: #fff;
  border: 1px solid hsla(0, 0%, 50%, .761);
  color: hsla(0, 0%, 50%, .761);
  font-size: 12px !important;
  height: 75px !important;
  padding: 9px 2px !important;
  width: 75px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.t-323px {
  top: 323px;
}

.l-100px {
  left: 100px;
}

.r-40px {
  right: 40px
}

.step-11-verra {
  position: absolute;
  width: 63%;
  left: 42px;
  word-wrap: break-word;
}

.l-578px {
  left: 578px;
}


@-webkit-keyframes openbox {
  0% {
    height: 0px;
    width: 0px;
  }

  100% {
    height: 384px;
    width: 320px;
  }
}

@keyframes openbox {
  0% {
    height: 0px;
    width: 0px;
  }

  100% {
    height: 384px;
    width: 320px;
  }
}

@keyframes closebox {
  0% {
    height: 384px;
    width: 320px;
  }

  100% {
    height: 0px;
    width: 0px;
  }
}

.hideBox {
  /* -webkit-animation: closebox 0.5s ease-out; */
  /* Chrome, Safari, Opera */

  animation: closebox 0.5s ease-out;
  height: 0;
  width: 0;
  right: 60px;
  bottom: 55px;
}

/*executes the expand animation*/

.showBox {
  /* -webkit-animation: openbox 0.5s ease-in; */
  /* Chrome, Safari, Opera */
  animation: openbox 0.5s ease-in;
  height: 384px;
  width: 320px;
  right: 60px;
  bottom: 55px;
}

.step-4-text {
  position: absolute;
  width: 60%;
  left: 72px;
  bottom: 15px;
}

.step-5-text {
  position: absolute;
  width: 60%;
  left: 54px;
  bottom: 10px;
}

.step-8-text {
  position: absolute;
  left: 20px;
  bottom: 15px;
}

.stick-1 {
  position: absolute;
  /* width: 100%; */
  width: -webkit-fill-available;
}

.stick-2 {
  position: absolute;
  width: 100%;
  top: 78px;
  width: -webkit-fill-available;
}

.stick-3 {
  position: absolute;
  width: 100%;
  top: 60px;
  width: -webkit-fill-available;
}

.t--20px {
  top: -16px;
}

.t--28px {
  top: -28px;
}

.l-431px {
  left: 485px;
}

.l-729px {
  left: 729px;
}

.stick-4 {
  position: absolute;
  width: 100%;
  top: 76px;
  right: 106px;
}

.status-text {
  margin-bottom: 4rem;
  position: absolute;
  right: 7%;
  width: 65%;
}

.justify-flex-start {
  justify-content: flex-start !important;
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .right-rounded {
    left: 83.2%;
  }

  .left-rounded {
    right: -18%;
  }

  .l-431px {
    left: 431px;
  }
}

@media (min-width: 1201px) and (max-width: 1440px) {
  .right-rounded {
    left: 80.2%;
  }

  .left-rounded {
    right: -15%;
  }

  .l-431px {
    left: 485px;
  }
}

@media (min-width: 1441px) and (max-width: 1800px) {
  .right-rounded {
    left: 80.2%;
  }

  .left-rounded {
    right: -13%;
  }

  .l-431px {
    left: 500px;
  }
}


@media (min-width: 1801px) and (max-width: 2000px) {
  .right-rounded {
    left: 74.2%;
  }

  .left-rounded {
    right: -7%;
  }

  .l-431px {
    left: 600px;
  }
}

@media (min-width: 2001px) and (max-width: 2300px) {
  .right-rounded {
    left: 70.2%;
  }

  .left-rounded {
    right: -5%;
  }

  .l-431px {
    left: 636px;
  }
}


@media (min-width: 2300px) and (max-width: 2600px) {
  .right-rounded {
    left: 70.2%;
  }

  .left-rounded {
    right: -3%;
  }

  .l-431px {
    left: 700px;
  }
}

.f-s-13 {
  font-size: 13px;
}

.f-s-12 {
  font-size: 12px;
}

.accordion-body p {
  font-size: 14px;
}

.f-s-14 {
  font-size: 14px;
}

.ghg-data-preview .table thead th,
.ghg-data-preview .table td,
.ghg-data-preview .table {
  border: 1px solid #000 !important;
}

.v-timeline-tracker {
  position: relative;
  display: flex;
  gap: 1.5rem;

  &:last-child {
    .v-timeline-content {
      &:before {
        content: "";
        position: absolute;
        left: 20%;
        top: -47px;
        height: 5rem;
        width: 31px;
        background-color: lightgrey;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      &.active::before {
        background-color: #188674;
        z-index: 1;
      }

      &.active::after {
        content: "";
        font-size: 15px;
        color: green;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &:first-child {
    .v-timeline-content {
      &::before {
        content: "";
        position: absolute;
        left: 20%;
        top: 0;
        height: 2rem;
        width: 31px;
        background-color: lightgrey;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      &.active::before {
        background-color: #188674;
        z-index: 1;
      }

      &.active::after {
        content: "";
        font-size: 15px;
        color: green;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.v-timeline-content {
  flex: 1;
  position: relative;
  order: 1;
  padding-left: 1.5rem;
  padding-bottom: 2.5rem;

  &:before {
    content: "";
    position: absolute;
    left: 20%;
    top: -47px;
    height: 5rem;
    width: 31px;
    background-color: lightgrey;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 20.5%;
    top: 4px;
    width: 25px;
    height: 25px;
    background-color: #fff;
    z-index: 2;
    border: 2px solid lightgrey;
    border-radius: 50%;
  }

  &.active::before {
    background-color: #188674;
    z-index: 1;
  }

  &.active::after {
    content: "";
    font-size: 15px;
    color: green;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.v-timeline-title {
  position: relative;
  left: 25%;
  width: fit-content;
  font-size: 15px;
}

.v-timeline-desc {
  color: grey;
}

.v-timeline-title-active {
  font-weight: bold;
}


.emission-factor .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #fff !important;
  background-color: #869290 !important;
  border: 1px solid #000;
  border-radius: 4px;
}

.emission-factor .nav-item:last-child {
  border-bottom: none;
}

.w-70px {
  width: 70px !important;
}

.emission-factor .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  background-color: #f5f7f7 !important;
  /* margin: 10px 0px 10px 0px; */
  color: #335e33 !important;
  border: none !important;
  border-radius: 4px;
}

.disabled-button-border {
  border: 1px solid #c1c1c1;
}

.accord-container {
  position: relative;
  width: 100%;
  height: 100%;
  /* Adjust the width and height as needed */
}

.accord-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Other styling properties */
}

.accord-overlay-p {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1px solid #000;
  background: #fff;
}

.ag-datasheet .ag-theme-material .ag-layout-auto-height .ag-center-cols-viewport,
.ag-datasheet .ag-theme-material .ag-layout-auto-height .ag-center-cols-container,
.ag-datasheet .ag-theme-material .ag-layout-print .ag-center-cols-viewport,
.ag-datasheet .ag-theme-material .ag-layout-print .ag-center-cols-container {
  min-height: 35px !important;
}

.swal2-html-container {
  font-size: 16px;
  line-height: inherit;
}

.swal2-actions {
  margin: 1rem auto 0;
}

.swal2-styled {
  padding: 0.375rem 1.75rem;
  margin: .3125em 1rem;
}

.swal2-styled.swal2-cancel {
  background-color: #fff;
  border: 1px solid #335e33;
  color: #335e33;
}

.w-45 {
  width: 45%;
}

.maintanance {
  display: flex;
  place-content: center;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}


.company-profile .nav-tabs .nav-link.active,
.company-profile .nav-tabs .nav-item.show .nav-link {
  color: #335e33 !important;
  background-color: #eef3f3 !important;
  border: 0.5px solid #edebebde;
  border-radius: 0px;
  border-left: 3px solid #335e33;
  margin-bottom: 0px !important;
}

.company-profile .nav-tabs {
  border: none !important;
}

/* .company-profile .nav-tabs .nav-link {
  text-align: center !important;
} */

.company-profile .nav-item:last-child {
  border-bottom: none;
}

.company-profile .nav-tabs .nav-link:hover,
.company-profile .nav-tabs .nav-link:focus {
  color: #335e33 !important;
  background-color: #eef3f3 !important;
  border: 0.5px solid #edebebde;
  border-radius: 0px;
  border-left: 3px solid #335e33 !important;
}

.company-profile .subtab .nav-tabs .nav-link {
  text-align: right !important;
}

.w-20per {
  width: 20% !important;
}

.subtab .nav-tabs .nav-link.active,
.subtab .nav-tabs .nav-item.show .nav-link {
  color: #335e33 !important;
  background-color: #f0efef !important;
  border: 0.5px solid #edebebde;
  border-radius: 0px;
  border-right: 3px solid #fe6a40
}

.subtab .nav-tabs .nav-link:hover,
.subtab .nav-tabs .nav-link:focus {
  color: #335e33 !important;
  background-color: #f0efef !important;
  border: 0.5px solid #edebebde;
  border-radius: 0px;
  border-right: 3px solid #fe6a40 !important;
  border-left: none !important;
}

.w-82per {
  width: 80% !important;
}

.company-profile .accordion-body {
  padding: 0px !important;
}

.company-profile .accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: none;
  border-radius: 0px !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.company-profile .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.company-profile .accordion-button {
  padding: 13px 8px 13px 1rem;
}

.company-profile .accordion-button::after {
  width: 18px;
  height: 19px;
  background-size: 1rem;
  margin-bottom: 0px;
}

.company-profile .accordion-button:focus {
  border: none !important;
  box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.company-profile .accordion-button:not(.collapsed) {
  background-color: inherit;
  box-shadow: none;
}

.company-profile .accordion-item .accordion-button {
  box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.company-profile .accord-col {
  border-right: 1px solid #dee2e6 !important;
  padding-right: 0px !important;
}

.company-profile .ag-datasheet .ag-theme-material textarea[class^=ag-] {
  border-bottom: 1px solid #335e33 !important;
  background-color: #fff !important;
  display: flex !important;
  height: 58px !important;
  padding-bottom: 5px !important;
  margin-top: unset !important;
  width: unset !important;
}

.company-profile .ag-large-text-input {
  height: 60px !important;
  border: 1px solid #000;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.ag-group-header .employee .ag-datasheet .ag-header-cell:nth-last-child(1),
.ag-group-header .employee .ag-datasheet .ag-header-cell:nth-last-child(2),
.ag-group-header .employee .ag-datasheet .ag-header-cell:nth-last-child(3) {
  height: 35px !important;
}

.ag-group-header .employee .ag-datasheet .ag-header-cell:nth-child(2) {
  height: 70px !important;
}

.ag-group-header .employee-turnover .ag-datasheet .ag-header-cell:nth-last-child(1),
.ag-group-header .employee-turnover .ag-datasheet .ag-header-cell:nth-last-child(2),
.ag-group-header .employee-turnover .ag-datasheet .ag-header-cell:nth-last-child(3) {
  height: 35px !important;
}

.company-profile .checkbox-item {
  width: 100%;
  display: flex;
  justify-content: center;
}

.company-profile .ag-group-header .ngrbc-review .ag-datasheet .ag-header-cell:nth-child(2),
.company-profile .ag-group-header .ngrbc-review .ag-datasheet .ag-header-cell:nth-last-child(1),
.company-profile .ag-group-header .ngrbc-review .ag-datasheet .ag-header-cell:nth-last-child(2),
.company-profile .ag-group-header .ngrbc-review .ag-datasheet .ag-header-cell:nth-last-child(3) {
  height: 35px !important;
}

.vendor-add {
  z-index: 9;
  position: absolute;
  right: 20px;
  margin-top: 1rem;
}

.mtop-3 {
  margin-top: 3rem;
}

.company-profile .ag-group-header .recylce .ag-datasheet .ag-header-cell:nth-last-child(2),
.company-profile .ag-group-header .recylce .ag-datasheet .ag-header-cell:nth-last-child(1) {
  height: 35px !important;
}

.active-dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  margin: 0px 4px 0px 0px;
  background: #0cb023;
  border-radius: 50%;
}

.active-bg {
  background: #00ac4626;
  width: 5rem;
  text-align: center;
  padding: 2px 10px;
  height: 1.75rem;
  justify-content: center;
  align-items: center !important;
  border-radius: 0.375rem;
  color: #00ac46;
  font-weight: 500;
}

.company-profile .ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total) {
  border-top: 1px solid #000 !important;
}

.company-profile .ag-header-group-text {
  white-space: normal !important;
}

.w-150px {
  width: 150px;
}

.ownershipHistory .ag-theme-material .ag-cell-inline-editing {
  padding: 0px !important;
  height: 40px !important;
  border: 1px solid #335e33 !important;
}

.ownershipHistory .ag-cell {
  display: flex !important;
  align-items: center !important;
  --ag-internal-padded-row-height: 20px !important;
}

.ownershipHistory .ag-input-field {
  min-height: 27px !important;
  height: 45px !important;
}

.ownershipHistory .ag-theme-material input[class^=ag-]:not([type]),
.ownershipHistory .ag-theme-material input[class^=ag-][type=text],
.ownershipHistory .ag-theme-material input[class^=ag-][type=number],
.ownershipHistory .ag-theme-material input[class^=ag-][type=tel],
.ownershipHistory .ag-theme-material input[class^=ag-][type=date],
.ownershipHistory .ag-theme-material input[class^=ag-][type=datetime-local],
.ownershipHistory .ag-theme-material textarea[class^=ag-] {
  /* border-bottom: 1px solid #dee2e6 !important; */
  border-bottom: 1px solid #335e33 !important;
  background-color: #fff !important;
  display: flex !important;
  height: 45px !important;
  padding-bottom: 5px !important;
  margin-top: 12px !important;
}

.ownershipHistory .ag-select .ag-picker-field-wrapper {
  padding: 0px 0px 0px 5px !important;
  height: 44px;
  background-color: #ebebeb;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
  cursor: default;
  z-index: 99;
}

.ownershipHistory .ag-select .ag-select-list {
  background-color: #dff4f4 !important;
  border: 1px solid #000000 !important;

}

.custom-footer-area {
  padding-bottom: 1rem;
  border-bottom: 1px solid #e2e2e2
}

.rowSpan-style {
  background-color: #fff !important;
  border-bottom: 1px solid color-mix(in srgb, transparent, #181d1f 15%) !important;
  z-index: 7 !important;
}

.ag-popup {
  position: absolute;
  z-index: 999;
}

.data-capture .nav-underlines .nav-link:hover,
.data-capture .nav-underlines .nav-item .active {
  color: #335e33 !important;
  border-bottom: 2px solid #335e33 !important;
}

.ag-3layer-header .ag-group-header .ag-datasheet .ag-header {
  min-height: 105px !important;
}

.ag-3layer-header .ag-group-header .ag-datasheet .ag-header-cell:first-child,
.ag-3layer-header .ag-group-header .ag-datasheet .ag-header-cell:last-child {
  height: 105px !important;
}

.company-profile .ag-3layer-header .ag-group-header .ngrbc-review .ag-datasheet .ag-header-cell:nth-child(2),
.company-profile .ag-3layer-header .ag-group-header .ngrbc-review .ag-datasheet .ag-header-cell:nth-child(7) {
  height: 70px !important;
}

.company-profile .ag-header-row:not(:first-child) .ag-header-group-cell.ag-header-group-cell-with-group {
  border-top: 1px solid #000 !important;
}

.application-active {
  color: #335e33 !important;
  border-bottom: 2px solid #335e33 !important;
}

.fiscal-year .react-datepicker-wrapper {
  width: 100%;
}

.nav-pills .nav-link {
  font-size: 13px;

}

.f-13 {
  font-size: 13px;
}

.styles-module_light__Z6W-X {
  background-color: #fafafa !important;
  padding: 5px !important;
}

.accord-container .accordion-button {
  padding: 5px 1rem;
}

.CircularProgressbar {
  width: 5% !important;
  margin-left: 1rem;
  margin-right: 1rem;
}

.accordian-summary-button {
  width: 100%;
  justify-content: space-between;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #335e33 !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #335e33 !important;
  font-weight: 600;
}

.pg-viewer-wrapper .document-container {
  padding: 30px !important;
  width: 100% !important;
  background: #fff;
  margin: auto;
}

.pg-viewer-wrapper table {
  width: 100%;
  caption-side: bottom !important;
  border-left: 2px solid #2b77041a !important;
  border-collapse: collapse !important;
  border-top: 2px solid #2b77041a !important;
}

.pg-viewer-wrapper {
  overflow-y: hidden;
  border: 1px solid;
}

.faq-icon-w {
  width: 22px !important;
}

#docxViewer {
  border: 1px solid #ccc;
  padding: 10px;
  height: auto;
  overflow-y: auto;
}

.custom-docx {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
}

.custom-docx .docx-page {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
}

.docx-wrapper>section.docx {
  width: 100% !important;
}

.docx svg {
  margin-left: -71.8pt !important;
  width: 100% !important;
}

.docx-wrapper section:nth-child(3),
.docx-wrapper section:nth-child(4),
.docx-wrapper section:nth-child(5),
.docx-wrapper section:nth-child(6) {
  display: none;
}

.upload-btn {
  margin-bottom: 0px;
  font-size: 12px;
  padding: 0px 10px;
  background: #f0f0f0;
  border: 1px solid;
  color: #000
}

.user-form .nav-tab-active,
.user-form .nav-tabs .nav-link:focus {
  background: #dcdede36 !important;
  color: #335e33 !important;
  border: 1px solid #2b77041a !important;
  border-bottom: none !important;
}

.user-form-scroll {
  max-height: 25rem;
  overflow: auto;
  scrollbar-width: thin;
}

.align-baseline {
  align-items: baseline !important;
}

.ml-0 {
  margin-left: 0rem;
}

.w-10 {
  width: 10rem !important;
}

.div-line-1px {
  position: relative;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #00000040;
}

.w-45r {
  width: 45rem !important;
}

.rejected-status {
  padding: 3px 10px;
  background: #f894945e;
  border-radius: 8px;
  color: #ed0808;
  font-weight: 500
}

/* .side-modal .modal-dialog {
  position: fixed;
  right: 0;
  top: 0;
  margin: 0;
  height: 100%;
  max-width: 30%;
  transition: transform 0.3s ease-in-out;
}

.side-modal .modal-content {
  height: 100%;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  max-height: 100vh !important;
  position: fixed !important;
  right: 0 !important;
  width: 800px !important;
  top: 0 !important;
  margin-right: 0px !important;
  max-width: 30%;
  transition: transform 0.3s ease-in-out;
}

.modal-content {
  height: calc(100vh - 1px) !important;
}

.modal {
  --bs-modal-margin: 0px !important;
} */

.modal-buttom {
  position: absolute;
  bottom: 20px;
}


.stepper-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
  position: absolute;
  top: 30px;
}

.step {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 20px;
  cursor: pointer;
}

.step:not(:last-child)::after {
  content: '';
  position: absolute;
  left: 9px;
  top: 30px;
  width: 2px;
  height: 3rem;
  background-color: #ddd;
}

.step.completed::after {
  background-color: #335e33;
}

.step .circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ddd;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
}

.step.completed .circle {
  background-color: #335e33;
  border-color: #335e33;
  color: white;
}

.step.completed .circle::before {
  content: '✔';
  font-size: 10px;
  font-weight: bold;
}

.step.active .circle {
  border-color: #335e33;
}

.step-label {
  font-size: 12px;
  font-weight: 600;
}

.step.completed .step-label {
  color: #335e33;
}

/* .step.active .step-label {
  font-weight: bold;
} */

.step.incomplete .circle {
  border-color: #ddd;
}

.step.incomplete .step-label {
  color: #888;
}

.w-25per {
  width: 25% !important;
}

.w-75per {
  width: 75% !important;
}

.vertical-line-div {
  content: '';
  position: absolute;
  left: 23%;
  /* right: 15px; */
  top: 3px;
  width: 1px;
  height: calc(98% - 1px);
  background-color: #00000040;
}

.approval-status {
  background: #90ed9c7a;
  padding: 3px 10px;
  border-radius: 8px;
  font-weight: 500;
  color: #094110;
}

.gocYik {
  min-height: 40px !important;
}

.w-9 {
  width: 9.2rem;
}

.app-list-position {
  position: absolute;
  top: 5rem;
}

.app-img-div {
  width: 13rem;
  background: #fff;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  border-radius: 5px;
}

.app-tile {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  width: 14rem;
  height: 10rem !important;
  font-size: 17px;
  border: 1px solid #d8d0d0;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  vertical-align: middle;
  flex-direction: column;
}

.app-logo-img {
  padding: 5px;
  /* border: 1px solid #f0f0f0; */
  /* background: #fff; */
  border-radius: 5px;
}

.w-13 {
  width: 13rem;
}

.app-tile-font {
  margin-top: 7px;
  color: #626969;
  font-weight: 600;
  letter-spacing: 0.2px;
  font-size: 11px;
}

.bg-fff {
  background-color: #fff;
}

.grid-dots {
  font-size: 20px;
  color: #fff;
}

.onhover-app-list {
  top: 40px;
  width: 15rem;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  transition: all linear 0.3s;
}

.grid-dots-active {
  background: #9d9f9f99;
  padding: 5px;
  border-radius: 50px;
  color: #fff;
  font-size: 25px;
}

.airoi-logo {
  width: 96px;
  padding: 10px;
  display: flex;
}

.pad-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.pad-electricity {
  padding-left: 5px !important;
  padding-right: 6px !important;
}

.br-0 {
  border-radius: 0px !important;
}

.mtop-6px {
  margin-top: 6px;
}

.upload-file-icon {
  margin-bottom: 0px !important;
  margin-top: 6px !important;
  font-size: 20px;
}

.file-extract {
  font-size: 20px;
  margin-top: 6px;
  color: #126c6c;
}

.file-download {
  font-size: 20px;
  margin-top: 6px;
  stroke-width: 1;
  color: #126c6c;
}

.home-icon {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.bg-gray {
  background-color: #f0f0f0 !important;
}

.disable-cursor {
  cursor: default;
  pointer-events: none !important;
}

.app-dd {
  padding-left: 1rem;
  padding-top: 1rem;
  border-right: 1px solid #2b77041a;
  padding-bottom: 0.5rem;
  border-top: 1px solid #2b77041a;
}

.border-top-none {
  border-top: none !important
}

.pad-bot-75rem {
  padding-bottom: 0.75rem !important;
}

.history-icon {
  font-size: 18px;
  width: 18px !important;
  margin-left: 1.5rem;
  cursor: pointer;
  margin-top: 10px;
}

.file-icon {
  font-size: 23px;
  width: 23px !important;
  margin-left: 0.5rem;
  cursor: pointer;
  margin-top: 10px;
  color: #2b7704;
}

div[id^=font-picker] .dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 35px;
  padding: 0 10px;
  background: #fff !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 0.375rem;
}

div[id^=font-picker] {
  margin-left: 1rem;
  border-radius: 0.375rem !important;
  box-shadow: 0 0px 0px rgba(46, 35, 94, 0.07) !important;
}

div[id^=font-picker] ul li button.active-font {
  background: #d9f7d9 !important;
}

div[id^=font-picker] ul li button:hover,
div[id^=font-picker] ul li button:focus {
  background: #d9f7d9 !important;
}

div[id^=font-picker] ul {
  background-color: #fff !important;
}

.font-size-dropdown {
  width: 20% !important;
  display: inline-block !important;
  margin-left: 1rem;
}

.rcp {
  margin-top: 1rem;
}

.ownershipHistory .ag-theme-material .text-input-field {
  display: flex;
  padding: 0px;
  height: 40px;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
  margin-top: 5px;
  padding-left: 10px;
  align-items: center;
}

.bell-anime {
  animation: tada 1.5s ease infinite;
}

@keyframes tada {
  0% {
    transform: scaleX(1);
  }

  10% {
    transform: scale3d(.9, .9, .9) rotate(-3deg);
  }

  20% {
    transform: scale3d(.9, .9, .9) rotate(-3deg);
  }

  30% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }

  70% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }

  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }

  40% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }

  60% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }

  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }

  to {
    transform: scaleX(1);
  }
}

.tada {
  animation-name: tada;
}

.position-unset {
  position: unset !important;
}

.rc-time {
  position: absolute;
  right: 15px;
}

.notify-msg {
  color: #898989;
  margin-left: 2rem !important;
}

.notify-eclipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  display: block;
}


.btn-css {
  display: flex !important;
  align-items: center !important;
}

.mr-hf-rem {
  margin-right: 0.5rem !important;
}

.export-pdf {
  position: absolute;
  right: 30px;
  top: 10px;
}

.approve {
  padding: 5px 15px;
  background: #2faf2f33;
  border-radius: 8px;
  color: green;
}

.reject {
  padding: 5px 15px;
  background: #da303030;
  border-radius: 8px;
  color: #e90303;
}

.pending {
  padding: 5px 15px;
  background: #9ae0ee66;
  border-radius: 8px;
  color: #04697d;
}

.w-8rem {
  width: 8rem !important;
}

.w-11 {
  width: 11rem !important;
}

.w-9 {
  width: 9rem !important;
}

.biochar-monitoring .react-datepicker-wrapper {
  display: block !important;
}

.datacapture-list a:hover {
  color: #000000;
  cursor: pointer;
  text-decoration: underline;
}

.emission-Insight a:hover {
  color: #000000;
  cursor: pointer;
  text-decoration: underline;
}
