.apexcharts-legend {
    display: flex;
    overflow: visible !important;
    padding: 0 10px;
}

.card_dropdown p {
    margin-bottom: 0 !important;

}

.apexcharts-canvas svg {
    display: block;
    width: 100% !important;
}

.margin_p_bottom p {
    margin-bottom: 0 !important;
}