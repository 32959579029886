/**=====================
    29. Footer CSS Start
==========================**/
.footer {
  background-color: #fff;
  box-shadow: $footer_box_shadow;
  padding: 10px;
  bottom: 0;
  left: 0;
  margin-left: 165px;
  transition: 0.5s;
  color: #051a1a;
  &.footer-dark {
    background-color: $footer_dark_color;
    p {
      color: $white;
    }
  }
  .footer-left {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-family: 'Montserrat',sans-serif;
    }
  }
  .footer-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 1.2;
    i {
      font-size: 18px;
    }
  }
}
.footer-fix {
  width: calc(100% - $sidebar-compact);
  position: fixed;
}
@media (max-width: 767px) {
  // footer
  .footer {
    .footer-right {
      ul {
        &.color-varient {
          li {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .footer {
    .footer-right {
      i{
        font-size: 18px;
      }
      ul {
        &.color-varient {
          li {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .footer {
    p {
      font-size: 12px;
    }
    .footer-right {
      margin-top: 0;
      ul {
        &.color-varient {
          display: none;
          li {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}
/**=====================
     29. Footer CSS Ends
==========================**/