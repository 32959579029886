/**=====================
     22. Select 2 CSS Start
==========================**/
.select2 {
  max-width: 100%;
}
.add-post {
  #cke_text-box {
    border: 1px solid $light-semi-gray;
  }
  form {
    .m-checkbox-inline {
      label {
        margin-bottom: 0;
      }
    }
    .form-control {
      border: 1px solid $light-semi-gray;
    }
    .col-form-label {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
  .dropzone {
    margin-bottom: 30px;
  }
}
.select2-container {
  width: 100% !important;
}
.select2-drpdwn {
  .select2-selection {
    border-radius: 0 !important;
    border-color: $light-color !important;
    height: 40px !important;
    padding: 5px;
  }
  .form-control {
    border-radius: 5px;
  }
  .form-control-primary {
    border-color: var(--theme-default);
    color: var(--theme-default);
  }
  .form-control-secondary {
    border-color: var(--theme-secondary);
    color: var(--theme-secondary);
  }
  .form-control-success {
    border-color: $success-color;
    color: $success-color;
  }
  .form-control-info {
    border-color: $info-color;
    color: $info-color;
  }
  .form-control-warning {
    border-color: $warning-color;
    color: $warning-color;
  }
  .form-control-danger {
    border-color: $danger-color;
    color: $danger-color;
  }
  .form-control-inverse {
    border-color: $dark-color;
    color: $dark-color;
  }
  .form-control-primary-fill {
    background-color: var(--theme-default);
    color: $white;
  }
  .form-control-secondary-fill {
    background-color: var(--theme-secondary);
    color: $white;
  }
  .form-control-success-fill {
    background-color: $success-color;
    color: $white;
  }
  .form-control-info-fill {
    background-color: $info-color;
    color: $white;
  }
  .form-control-warning-fill {
    background-color: $warning-color;
    color: $white;
  }
  .form-control-danger-fill {
    background-color: $danger-color;
    color: $white;
  }
  .form-control-inverse-fill {
    background-color: $dark-color;
    color: $white;
  }
}
/**=====================
     22. Select 2 CSS Ends
==========================**/