/**=====================
    53. Job CSS start
==========================**/
.filter-cards-view {
  .job-filter {
    &:first-child {
      margin-bottom: 30px;
    }
  }

  .checkbox-animated {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  p {
    color: $light-text;
    line-height: 1.6;
    letter-spacing: 0.02em;
    font-size: 13px;
  }

  .filter-view-group {
    margin-bottom: 10px;
  }
}

.location-checkbox {
  span {
    color: $light-text;
    padding-left: 37px;
  }
}

.job-accordion {
  .card {
    margin-bottom: 30px;
  }

  .btn-block {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.job-sidebar {
  .job-toggle {
    display: none;
    width: fit-content;
  }
}

.job-search {
  p {
    margin-top: 20px;
    color: $light-text;
  }

  .d-flex {
    .flex-grow-1 {
      h3 {
        margin-bottom: 3px;
        font-size: 16px;
        span {
          font-size: 13px;
          font-weight: normal;
          &.badge {
            margin-top: 5px;
            color: $white;
            font-size: 13px;
            font-weight: 600;
          }
        }

        .btn {
          font-weight: 600;
        }
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 500;
        display: inline-block;
      }

      .job-apply-btn {
        position: absolute;
        top: 28px;
        right: 30px;
      }
    }
  }

  .rating {
    .fa {
      margin-left: 2px;
    }
  }

  .job-description {
    margin-top: 20px;
    border-top: 1px solid $light-gray;
    padding-top: 20px;

    p {
      margin-top: 0;
      margin-bottom: 6px;
      letter-spacing: 0.04em;
    }

    ul {
      list-style-type: disc;
      list-style-position: inside;

      li {
        margin-bottom: 10px;
        cursor: pointer;
        color: $light-text;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          color: $primary-color;
        }
      }
    }

    .theme-form {
      margin-top: 20px;
      margin-bottom: 30px;
      border-bottom: 1px solid $light-semi-gray;

      .row {
        div[class^="col-"] {
          +div[class^="col-"] {
            .select2-container {
              margin-top: 14px;
              max-width: 100%;
            }
          }

          .job-select2 {
            ~.select2-container {
              margin-top: 0 !important;
            }
          }

          .input-group {
            .datepicker-here {
              font-size: 14px;
              border: 1px solid $light-gray;
              padding-top: 7px;
              padding-bottom: 7px;
            }
          }
        }
      }
    }
  }
}

.job-pagination {
  margin-bottom: 30px;

  nav {
    ul {
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 1470px) {
  .job-search {
    .d-flex {
      .flex-grow-1 {
        .job-apply-btn {
          padding: 9px 17px;
          font-size: 13px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .xl-mt-job {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
  .job-search {
    .d-flex {
      .flex-grow-1 {
        h3 {
          span {
            font-size: 11px;
          }
        }

        .job-apply-btn {
          position: unset;
          width: fit-content;
          margin-top: 5px;
          display: block;
        }
      }
    }
  }

  .file-content {
    .folder {
      .folder-box {
        padding: 12px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .filter-cards-view {
    padding: 20px !important;

    .job-filter {
      &:first-child {
        margin-bottom: 20px;
      }
    }

    .checkbox-animated {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .job-accordion {
    .animate-chk {
      padding: 20px !important;
    }
  }

  .job-search {
    p {
      margin-top: 15px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .job-search {
    .d-flex {
      .flex-grow-1 {
        .job-apply-btn {
          position: unset;
          display: block;
          width: fit-content;
          margin-top: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .filter-cards-view {
    padding: 15px !important;

    .job-filter {
      &:first-child {
        margin-bottom: 15px;
      }
    }

    .checkbox-animated {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .job-accordion {
    .animate-chk {
      padding: 15px !important;
    }
  }

  .job-search {
    p {
      margin-top: 10px;
      text-align: center;
      font-size: 13px;
    }

    .d-flex {
      display: block !important;
      text-align: center;

      .flex-grow-1 {
        text-align: center;

        .job-apply-btn {
          margin-left: auto;
          margin-right: auto;
        }

        h6 {
          margin-bottom: unset;

          .pull-right {
            float: none;
            display: inline-block;
            margin-left: 15px;
          }

          span.badge {
            font-size: 10px;
            padding: 5px;
          }

          a {
            margin-bottom: 10px;
          }
        }

        p {
          span {
            +span {
              display: block;
            }
          }
        }
      }

      img {
        margin-bottom: 15px;
      }

      .m-r-20 {
        margin-right: 0;
      }
    }

    .job-description {
      margin-top: 15px;
      padding-top: 15px;

      label {
        display: block;
        text-align: left !important;
      }

      .select2-container {
        width: 100% !important;
      }

      .theme-form {
        margin-top: 15px;
        margin-bottom: 15px;

        .row {
          div[class^="col-"] {
            +div[class^="col-"] {
              .select2-container {
                margin-top: 2px;
              }
            }
          }
        }

        .xs-mt-period {
          margin-top: 15px;
        }
      }
    }

    .card-footer {
      padding: 20px;
    }
  }

  .job-pagination {
    margin-bottom: 15px;
  }

  .xl-mt-job {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 420px) {
  .job-search {
    .d-flex {
      .flex-grow-1 {
        h6 {
          span.badge {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .job-search {
    .job-description {
      .btn {
        padding: 6px 12px;
      }
    }
  }
}

/**=====================
     53. Job CSS end
==========================**/