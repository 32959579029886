/**=====================
     15. List CSS Start
==========================**/
.listings{
  .list-group-item.active {
    background-color: var(--theme-default);
    border-color: var(--theme-default);
  }
  .list-group {
    .list-group-item {
      i {
        margin-right: $list-group-margin;
      }
    }
    i {
      margin-right: $list-group-margin;
    }
    img {
      height: 40px;
      width: 40px;
      margin-right: $list-group-margin;
    }
  }
}
/**=====================
     15. List CSS Ends
==========================**/