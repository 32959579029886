/**=====================
    56. Language CSS start
==========================**/
.language-nav {
  background-color: transparent !important;
  width: 50px !important;
  .translate_wrapper {
    &.active {
      .more_lang {
        .lang {
          border-bottom: 1px solid #eaeaea;
        }
      }
    }
  }
  .current_lang {
    cursor: pointer;
    text-transform: uppercase;
    overflow: hidden;
  }
  .lang {
    &.selected {
      display: none;
    }
    span.lang-txt {
      display: inline-flex;
      margin-left: 8px;
      font-weight: 500;
    }
    span {
      span {
        color: #999;
        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  /*more lang*/
  .more_lang {
    transform: translateY(-20px);
    opacity: 0;
    cursor: pointer;
    display: none;
    transition: all 0.3s cubic-bezier(0.25, 1.15, 0.35, 1.15);
  }
  .translate_wrapper.active .more_lang {
    display: block;
    position: absolute;
    background-color: $white;
    top: 47px;
    right: 0;
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  }
  .more_lang {
    &.active {
      opacity: 1;
      transform: translateY(-0px);
    }
    .lang {
      padding: 10px 15px;
      display: flex;
      &:hover {
        background: var(--theme-default);
        color: $white;
        span {
          color: $white;
        }
      }
    }
  }
}
/**=====================
    56. Language CSS end
==========================**/