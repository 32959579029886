/**=====================
    31. Header CSS Starts
==========================**/
/**======Main Header css Start ======**/
.toggle-sidebar {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: auto;
    color: #424242;
    stroke-width: 1;
  }

  i {
    font-size: $header-wrapper-nav-icon-size;
    color: #242424;
  }

  &:hover {
    // background-color: #fafafa;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
    &:before {
      height: 40px;
      width: 40px;
      transform: scale(1);
      transition: all 0.3s ease;
    }

    >svg {
      color: #242424 !important;
      stroke: #242424 !important;
    }
  }
}

.toggle-sidebar:hover::before {
  height: 40px;
  width: 40px;
  transform: scale(1);
  transition: all 0.3s ease;
}

.toggle-sidebar::before {
  content: "";
  position: absolute;
  height: 40px;
  width: 40px;
  background-color: hsla(0, 0%, 100%, .1);
  border-radius: 100%;
  left: -8px;
  z-index: -2;
  top: -7px;
  transform: scale(0);
  transition: all .3s ease;
}

.onhover-dropdown {
  cursor: pointer;
  position: relative;

  &:hover {
    .onhover-show-div {
      @extend %for-animated-hover-box;
    }
  }

  .flip-card {
    .bookmark-dropdown {
      li {
        input {
          display: none;
        }
      }
    }
  }
}

.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 8;
  background-color: #fff;
  transition: all linear 0.3s;
  @extend %for-animated-hover;

  li {
    a {
      svg {
        margin-top: 0 !important;

        path,
        line {
          color: $theme-body-font-color !important;
        }
      }
    }
  }
}

/**======Main Header css Ends ======**/
.left-header {
  .input-group {
    padding: 5px 20px;
    border-radius: 8px;
    background-color: $light;

    .form-control {
      border: none;
      background-color: transparent;
      padding: 0;
      font-size: 14px;

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        font-weight: 300;
        color: $light-text;
      }
    }

    .input-group-text {
      background-color: transparent;
      border: none;
      padding: 0;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: auto;
        height: 22px;
      }
    }
  }
}

/**=====================
    31. Header CSS Ends
==========================**/