// variables
// --------------------------

$fa-css-prefix          : fa !default;
$fa-style               : 900 !default;
$fa-style-family        : "Font Awesome 6 Free" !default;

$fa-display             : inline-block !default;

$fa-fw-width            : fa-divide(20em, 16) !default;
$fa-inverse             : #fff !default;

$fa-border-color        : #eee !default;
$fa-border-padding      : .2em .25em .15em !default;
$fa-border-radius       : .1em !default;
$fa-border-style        : solid !default;
$fa-border-width        : .08em !default;

$fa-size-scale-2xs      : 10 !default;
$fa-size-scale-xs       : 12 !default;
$fa-size-scale-sm       : 14 !default;
$fa-size-scale-base     : 16 !default;
$fa-size-scale-lg       : 20 !default;
$fa-size-scale-xl       : 24 !default;
$fa-size-scale-2xl      : 32 !default;

$fa-sizes: (
  "2xs"                 : $fa-size-scale-2xs,
  "xs"                  : $fa-size-scale-xs,
  "sm"                  : $fa-size-scale-sm,
  "lg"                  : $fa-size-scale-lg,
  "xl"                  : $fa-size-scale-xl,
  "2xl"                 : $fa-size-scale-2xl
) !default;

$fa-li-width            : 2em !default;
$fa-li-margin           : $fa-li-width * fa-divide(5, 4) !default;

$fa-pull-margin         : .3em !default;

$fa-primary-opacity     : 1 !default;
$fa-secondary-opacity   : .4 !default;

$fa-stack-vertical-align: middle !default;
$fa-stack-width         : ($fa-fw-width * 2) !default;
$fa-stack-z-index       : auto !default;

$fa-font-display        : block !default;
$fa-font-path           : "../webfonts" !default;

$fa-var-0: "\30";
$fa-var-1: "\31";
$fa-var-2: "\32";
$fa-var-3: "\33";
$fa-var-4: "\34";
$fa-var-5: "\35";
$fa-var-6: "\36";
$fa-var-7: "\37";
$fa-var-8: "\38";
$fa-var-9: "\39";
$fa-var-fill-drip: "\f576";
$fa-var-arrows-to-circle: "\e4bd";
$fa-var-circle-chevron-right: "\f138";
$fa-var-chevron-circle-right: "\f138";
$fa-var-at: "\40";
$fa-var-trash-can: "\f2ed";
$fa-var-trash-alt: "\f2ed";
$fa-var-text-height: "\f034";
$fa-var-user-xmark: "\f235";
$fa-var-user-times: "\f235";
$fa-var-stethoscope: "\f0f1";
$fa-var-message: "\f27a";
$fa-var-comment-alt: "\f27a";
$fa-var-info: "\f129";
$fa-var-down-left-and-up-right-to-center: "\f422";
$fa-var-compress-alt: "\f422";
$fa-var-explosion: "\e4e9";
$fa-var-file-lines: "\f15c";
$fa-var-file-alt: "\f15c";
$fa-var-file-text: "\f15c";
$fa-var-wave-square: "\f83e";
$fa-var-ring: "\f70b";
$fa-var-building-un: "\e4d9";
$fa-var-dice-three: "\f527";
$fa-var-calendar-days: "\f073";
$fa-var-calendar-alt: "\f073";
$fa-var-anchor-circle-check: "\e4aa";
$fa-var-building-circle-arrow-right: "\e4d1";
$fa-var-volleyball: "\f45f";
$fa-var-volleyball-ball: "\f45f";
$fa-var-arrows-up-to-line: "\e4c2";
$fa-var-sort-down: "\f0dd";
$fa-var-sort-desc: "\f0dd";
$fa-var-circle-minus: "\f056";
$fa-var-minus-circle: "\f056";
$fa-var-door-open: "\f52b";
$fa-var-right-from-bracket: "\f2f5";
$fa-var-sign-out-alt: "\f2f5";
$fa-var-atom: "\f5d2";
$fa-var-soap: "\e06e";
$fa-var-icons: "\f86d";
$fa-var-heart-music-camera-bolt: "\f86d";
$fa-var-microphone-lines-slash: "\f539";
$fa-var-microphone-alt-slash: "\f539";
$fa-var-bridge-circle-check: "\e4c9";
$fa-var-pump-medical: "\e06a";
$fa-var-fingerprint: "\f577";
$fa-var-hand-point-right: "\f0a4";
$fa-var-magnifying-glass-location: "\f689";
$fa-var-search-location: "\f689";
$fa-var-forward-step: "\f051";
$fa-var-step-forward: "\f051";
$fa-var-face-smile-beam: "\f5b8";
$fa-var-smile-beam: "\f5b8";
$fa-var-flag-checkered: "\f11e";
$fa-var-football: "\f44e";
$fa-var-football-ball: "\f44e";
$fa-var-school-circle-exclamation: "\e56c";
$fa-var-crop: "\f125";
$fa-var-angles-down: "\f103";
$fa-var-angle-double-down: "\f103";
$fa-var-users-rectangle: "\e594";
$fa-var-people-roof: "\e537";
$fa-var-people-line: "\e534";
$fa-var-beer-mug-empty: "\f0fc";
$fa-var-beer: "\f0fc";
$fa-var-diagram-predecessor: "\e477";
$fa-var-arrow-up-long: "\f176";
$fa-var-long-arrow-up: "\f176";
$fa-var-fire-flame-simple: "\f46a";
$fa-var-burn: "\f46a";
$fa-var-person: "\f183";
$fa-var-male: "\f183";
$fa-var-laptop: "\f109";
$fa-var-file-csv: "\f6dd";
$fa-var-menorah: "\f676";
$fa-var-truck-plane: "\e58f";
$fa-var-record-vinyl: "\f8d9";
$fa-var-face-grin-stars: "\f587";
$fa-var-grin-stars: "\f587";
$fa-var-bong: "\f55c";
$fa-var-spaghetti-monster-flying: "\f67b";
$fa-var-pastafarianism: "\f67b";
$fa-var-arrow-down-up-across-line: "\e4af";
$fa-var-spoon: "\f2e5";
$fa-var-utensil-spoon: "\f2e5";
$fa-var-jar-wheat: "\e517";
$fa-var-envelopes-bulk: "\f674";
$fa-var-mail-bulk: "\f674";
$fa-var-file-circle-exclamation: "\e4eb";
$fa-var-circle-h: "\f47e";
$fa-var-hospital-symbol: "\f47e";
$fa-var-pager: "\f815";
$fa-var-address-book: "\f2b9";
$fa-var-contact-book: "\f2b9";
$fa-var-strikethrough: "\f0cc";
$fa-var-k: "\4b";
$fa-var-landmark-flag: "\e51c";
$fa-var-pencil: "\f303";
$fa-var-pencil-alt: "\f303";
$fa-var-backward: "\f04a";
$fa-var-caret-right: "\f0da";
$fa-var-comments: "\f086";
$fa-var-paste: "\f0ea";
$fa-var-file-clipboard: "\f0ea";
$fa-var-code-pull-request: "\e13c";
$fa-var-clipboard-list: "\f46d";
$fa-var-truck-ramp-box: "\f4de";
$fa-var-truck-loading: "\f4de";
$fa-var-user-check: "\f4fc";
$fa-var-vial-virus: "\e597";
$fa-var-sheet-plastic: "\e571";
$fa-var-blog: "\f781";
$fa-var-user-ninja: "\f504";
$fa-var-person-arrow-up-from-line: "\e539";
$fa-var-scroll-torah: "\f6a0";
$fa-var-torah: "\f6a0";
$fa-var-broom-ball: "\f458";
$fa-var-quidditch: "\f458";
$fa-var-quidditch-broom-ball: "\f458";
$fa-var-toggle-off: "\f204";
$fa-var-box-archive: "\f187";
$fa-var-archive: "\f187";
$fa-var-person-drowning: "\e545";
$fa-var-arrow-down-9-1: "\f886";
$fa-var-sort-numeric-desc: "\f886";
$fa-var-sort-numeric-down-alt: "\f886";
$fa-var-face-grin-tongue-squint: "\f58a";
$fa-var-grin-tongue-squint: "\f58a";
$fa-var-spray-can: "\f5bd";
$fa-var-truck-monster: "\f63b";
$fa-var-w: "\57";
$fa-var-earth-africa: "\f57c";
$fa-var-globe-africa: "\f57c";
$fa-var-rainbow: "\f75b";
$fa-var-circle-notch: "\f1ce";
$fa-var-tablet-screen-button: "\f3fa";
$fa-var-tablet-alt: "\f3fa";
$fa-var-paw: "\f1b0";
$fa-var-cloud: "\f0c2";
$fa-var-trowel-bricks: "\e58a";
$fa-var-face-flushed: "\f579";
$fa-var-flushed: "\f579";
$fa-var-hospital-user: "\f80d";
$fa-var-tent-arrow-left-right: "\e57f";
$fa-var-gavel: "\f0e3";
$fa-var-legal: "\f0e3";
$fa-var-binoculars: "\f1e5";
$fa-var-microphone-slash: "\f131";
$fa-var-box-tissue: "\e05b";
$fa-var-motorcycle: "\f21c";
$fa-var-bell-concierge: "\f562";
$fa-var-concierge-bell: "\f562";
$fa-var-pen-ruler: "\f5ae";
$fa-var-pencil-ruler: "\f5ae";
$fa-var-people-arrows: "\e068";
$fa-var-people-arrows-left-right: "\e068";
$fa-var-mars-and-venus-burst: "\e523";
$fa-var-square-caret-right: "\f152";
$fa-var-caret-square-right: "\f152";
$fa-var-scissors: "\f0c4";
$fa-var-cut: "\f0c4";
$fa-var-sun-plant-wilt: "\e57a";
$fa-var-toilets-portable: "\e584";
$fa-var-hockey-puck: "\f453";
$fa-var-table: "\f0ce";
$fa-var-magnifying-glass-arrow-right: "\e521";
$fa-var-tachograph-digital: "\f566";
$fa-var-digital-tachograph: "\f566";
$fa-var-users-slash: "\e073";
$fa-var-clover: "\e139";
$fa-var-reply: "\f3e5";
$fa-var-mail-reply: "\f3e5";
$fa-var-star-and-crescent: "\f699";
$fa-var-house-fire: "\e50c";
$fa-var-square-minus: "\f146";
$fa-var-minus-square: "\f146";
$fa-var-helicopter: "\f533";
$fa-var-compass: "\f14e";
$fa-var-square-caret-down: "\f150";
$fa-var-caret-square-down: "\f150";
$fa-var-file-circle-question: "\e4ef";
$fa-var-laptop-code: "\f5fc";
$fa-var-swatchbook: "\f5c3";
$fa-var-prescription-bottle: "\f485";
$fa-var-bars: "\f0c9";
$fa-var-navicon: "\f0c9";
$fa-var-people-group: "\e533";
$fa-var-hourglass-end: "\f253";
$fa-var-hourglass-3: "\f253";
$fa-var-heart-crack: "\f7a9";
$fa-var-heart-broken: "\f7a9";
$fa-var-square-up-right: "\f360";
$fa-var-external-link-square-alt: "\f360";
$fa-var-face-kiss-beam: "\f597";
$fa-var-kiss-beam: "\f597";
$fa-var-film: "\f008";
$fa-var-ruler-horizontal: "\f547";
$fa-var-people-robbery: "\e536";
$fa-var-lightbulb: "\f0eb";
$fa-var-caret-left: "\f0d9";
$fa-var-circle-exclamation: "\f06a";
$fa-var-exclamation-circle: "\f06a";
$fa-var-school-circle-xmark: "\e56d";
$fa-var-arrow-right-from-bracket: "\f08b";
$fa-var-sign-out: "\f08b";
$fa-var-circle-chevron-down: "\f13a";
$fa-var-chevron-circle-down: "\f13a";
$fa-var-unlock-keyhole: "\f13e";
$fa-var-unlock-alt: "\f13e";
$fa-var-cloud-showers-heavy: "\f740";
$fa-var-headphones-simple: "\f58f";
$fa-var-headphones-alt: "\f58f";
$fa-var-sitemap: "\f0e8";
$fa-var-circle-dollar-to-slot: "\f4b9";
$fa-var-donate: "\f4b9";
$fa-var-memory: "\f538";
$fa-var-road-spikes: "\e568";
$fa-var-fire-burner: "\e4f1";
$fa-var-flag: "\f024";
$fa-var-hanukiah: "\f6e6";
$fa-var-feather: "\f52d";
$fa-var-volume-low: "\f027";
$fa-var-volume-down: "\f027";
$fa-var-comment-slash: "\f4b3";
$fa-var-cloud-sun-rain: "\f743";
$fa-var-compress: "\f066";
$fa-var-wheat-awn: "\e2cd";
$fa-var-wheat-alt: "\e2cd";
$fa-var-ankh: "\f644";
$fa-var-hands-holding-child: "\e4fa";
$fa-var-asterisk: "\2a";
$fa-var-square-check: "\f14a";
$fa-var-check-square: "\f14a";
$fa-var-peseta-sign: "\e221";
$fa-var-heading: "\f1dc";
$fa-var-header: "\f1dc";
$fa-var-ghost: "\f6e2";
$fa-var-list: "\f03a";
$fa-var-list-squares: "\f03a";
$fa-var-square-phone-flip: "\f87b";
$fa-var-phone-square-alt: "\f87b";
$fa-var-cart-plus: "\f217";
$fa-var-gamepad: "\f11b";
$fa-var-circle-dot: "\f192";
$fa-var-dot-circle: "\f192";
$fa-var-face-dizzy: "\f567";
$fa-var-dizzy: "\f567";
$fa-var-egg: "\f7fb";
$fa-var-house-medical-circle-xmark: "\e513";
$fa-var-campground: "\f6bb";
$fa-var-folder-plus: "\f65e";
$fa-var-futbol: "\f1e3";
$fa-var-futbol-ball: "\f1e3";
$fa-var-soccer-ball: "\f1e3";
$fa-var-paintbrush: "\f1fc";
$fa-var-paint-brush: "\f1fc";
$fa-var-lock: "\f023";
$fa-var-gas-pump: "\f52f";
$fa-var-hot-tub-person: "\f593";
$fa-var-hot-tub: "\f593";
$fa-var-map-location: "\f59f";
$fa-var-map-marked: "\f59f";
$fa-var-house-flood-water: "\e50e";
$fa-var-tree: "\f1bb";
$fa-var-bridge-lock: "\e4cc";
$fa-var-sack-dollar: "\f81d";
$fa-var-pen-to-square: "\f044";
$fa-var-edit: "\f044";
$fa-var-car-side: "\f5e4";
$fa-var-share-nodes: "\f1e0";
$fa-var-share-alt: "\f1e0";
$fa-var-heart-circle-minus: "\e4ff";
$fa-var-hourglass-half: "\f252";
$fa-var-hourglass-2: "\f252";
$fa-var-microscope: "\f610";
$fa-var-sink: "\e06d";
$fa-var-bag-shopping: "\f290";
$fa-var-shopping-bag: "\f290";
$fa-var-arrow-down-z-a: "\f881";
$fa-var-sort-alpha-desc: "\f881";
$fa-var-sort-alpha-down-alt: "\f881";
$fa-var-mitten: "\f7b5";
$fa-var-person-rays: "\e54d";
$fa-var-users: "\f0c0";
$fa-var-eye-slash: "\f070";
$fa-var-flask-vial: "\e4f3";
$fa-var-hand: "\f256";
$fa-var-hand-paper: "\f256";
$fa-var-om: "\f679";
$fa-var-worm: "\e599";
$fa-var-house-circle-xmark: "\e50b";
$fa-var-plug: "\f1e6";
$fa-var-chevron-up: "\f077";
$fa-var-hand-spock: "\f259";
$fa-var-stopwatch: "\f2f2";
$fa-var-face-kiss: "\f596";
$fa-var-kiss: "\f596";
$fa-var-bridge-circle-xmark: "\e4cb";
$fa-var-face-grin-tongue: "\f589";
$fa-var-grin-tongue: "\f589";
$fa-var-chess-bishop: "\f43a";
$fa-var-face-grin-wink: "\f58c";
$fa-var-grin-wink: "\f58c";
$fa-var-ear-deaf: "\f2a4";
$fa-var-deaf: "\f2a4";
$fa-var-deafness: "\f2a4";
$fa-var-hard-of-hearing: "\f2a4";
$fa-var-road-circle-check: "\e564";
$fa-var-dice-five: "\f523";
$fa-var-square-rss: "\f143";
$fa-var-rss-square: "\f143";
$fa-var-land-mine-on: "\e51b";
$fa-var-i-cursor: "\f246";
$fa-var-stamp: "\f5bf";
$fa-var-stairs: "\e289";
$fa-var-i: "\49";
$fa-var-hryvnia-sign: "\f6f2";
$fa-var-hryvnia: "\f6f2";
$fa-var-pills: "\f484";
$fa-var-face-grin-wide: "\f581";
$fa-var-grin-alt: "\f581";
$fa-var-tooth: "\f5c9";
$fa-var-v: "\56";
$fa-var-bangladeshi-taka-sign: "\e2e6";
$fa-var-bicycle: "\f206";
$fa-var-staff-snake: "\e579";
$fa-var-rod-asclepius: "\e579";
$fa-var-rod-snake: "\e579";
$fa-var-staff-aesculapius: "\e579";
$fa-var-head-side-cough-slash: "\e062";
$fa-var-truck-medical: "\f0f9";
$fa-var-ambulance: "\f0f9";
$fa-var-wheat-awn-circle-exclamation: "\e598";
$fa-var-snowman: "\f7d0";
$fa-var-mortar-pestle: "\f5a7";
$fa-var-road-barrier: "\e562";
$fa-var-school: "\f549";
$fa-var-igloo: "\f7ae";
$fa-var-joint: "\f595";
$fa-var-angle-right: "\f105";
$fa-var-horse: "\f6f0";
$fa-var-q: "\51";
$fa-var-g: "\47";
$fa-var-notes-medical: "\f481";
$fa-var-temperature-half: "\f2c9";
$fa-var-temperature-2: "\f2c9";
$fa-var-thermometer-2: "\f2c9";
$fa-var-thermometer-half: "\f2c9";
$fa-var-dong-sign: "\e169";
$fa-var-capsules: "\f46b";
$fa-var-poo-storm: "\f75a";
$fa-var-poo-bolt: "\f75a";
$fa-var-face-frown-open: "\f57a";
$fa-var-frown-open: "\f57a";
$fa-var-hand-point-up: "\f0a6";
$fa-var-money-bill: "\f0d6";
$fa-var-bookmark: "\f02e";
$fa-var-align-justify: "\f039";
$fa-var-umbrella-beach: "\f5ca";
$fa-var-helmet-un: "\e503";
$fa-var-bullseye: "\f140";
$fa-var-bacon: "\f7e5";
$fa-var-hand-point-down: "\f0a7";
$fa-var-arrow-up-from-bracket: "\e09a";
$fa-var-folder: "\f07b";
$fa-var-folder-blank: "\f07b";
$fa-var-file-waveform: "\f478";
$fa-var-file-medical-alt: "\f478";
$fa-var-radiation: "\f7b9";
$fa-var-chart-simple: "\e473";
$fa-var-mars-stroke: "\f229";
$fa-var-vial: "\f492";
$fa-var-gauge: "\f624";
$fa-var-dashboard: "\f624";
$fa-var-gauge-med: "\f624";
$fa-var-tachometer-alt-average: "\f624";
$fa-var-wand-magic-sparkles: "\e2ca";
$fa-var-magic-wand-sparkles: "\e2ca";
$fa-var-e: "\45";
$fa-var-pen-clip: "\f305";
$fa-var-pen-alt: "\f305";
$fa-var-bridge-circle-exclamation: "\e4ca";
$fa-var-user: "\f007";
$fa-var-school-circle-check: "\e56b";
$fa-var-dumpster: "\f793";
$fa-var-van-shuttle: "\f5b6";
$fa-var-shuttle-van: "\f5b6";
$fa-var-building-user: "\e4da";
$fa-var-square-caret-left: "\f191";
$fa-var-caret-square-left: "\f191";
$fa-var-highlighter: "\f591";
$fa-var-key: "\f084";
$fa-var-bullhorn: "\f0a1";
$fa-var-globe: "\f0ac";
$fa-var-synagogue: "\f69b";
$fa-var-person-half-dress: "\e548";
$fa-var-road-bridge: "\e563";
$fa-var-location-arrow: "\f124";
$fa-var-c: "\43";
$fa-var-tablet-button: "\f10a";
$fa-var-building-lock: "\e4d6";
$fa-var-pizza-slice: "\f818";
$fa-var-money-bill-wave: "\f53a";
$fa-var-chart-area: "\f1fe";
$fa-var-area-chart: "\f1fe";
$fa-var-house-flag: "\e50d";
$fa-var-person-circle-minus: "\e540";
$fa-var-ban: "\f05e";
$fa-var-cancel: "\f05e";
$fa-var-camera-rotate: "\e0d8";
$fa-var-spray-can-sparkles: "\f5d0";
$fa-var-air-freshener: "\f5d0";
$fa-var-star: "\f005";
$fa-var-repeat: "\f363";
$fa-var-cross: "\f654";
$fa-var-box: "\f466";
$fa-var-venus-mars: "\f228";
$fa-var-arrow-pointer: "\f245";
$fa-var-mouse-pointer: "\f245";
$fa-var-maximize: "\f31e";
$fa-var-expand-arrows-alt: "\f31e";
$fa-var-charging-station: "\f5e7";
$fa-var-shapes: "\f61f";
$fa-var-triangle-circle-square: "\f61f";
$fa-var-shuffle: "\f074";
$fa-var-random: "\f074";
$fa-var-person-running: "\f70c";
$fa-var-running: "\f70c";
$fa-var-mobile-retro: "\e527";
$fa-var-grip-lines-vertical: "\f7a5";
$fa-var-spider: "\f717";
$fa-var-hands-bound: "\e4f9";
$fa-var-file-invoice-dollar: "\f571";
$fa-var-plane-circle-exclamation: "\e556";
$fa-var-x-ray: "\f497";
$fa-var-spell-check: "\f891";
$fa-var-slash: "\f715";
$fa-var-computer-mouse: "\f8cc";
$fa-var-mouse: "\f8cc";
$fa-var-arrow-right-to-bracket: "\f090";
$fa-var-sign-in: "\f090";
$fa-var-shop-slash: "\e070";
$fa-var-store-alt-slash: "\e070";
$fa-var-server: "\f233";
$fa-var-virus-covid-slash: "\e4a9";
$fa-var-shop-lock: "\e4a5";
$fa-var-hourglass-start: "\f251";
$fa-var-hourglass-1: "\f251";
$fa-var-blender-phone: "\f6b6";
$fa-var-building-wheat: "\e4db";
$fa-var-person-breastfeeding: "\e53a";
$fa-var-right-to-bracket: "\f2f6";
$fa-var-sign-in-alt: "\f2f6";
$fa-var-venus: "\f221";
$fa-var-passport: "\f5ab";
$fa-var-heart-pulse: "\f21e";
$fa-var-heartbeat: "\f21e";
$fa-var-people-carry-box: "\f4ce";
$fa-var-people-carry: "\f4ce";
$fa-var-temperature-high: "\f769";
$fa-var-microchip: "\f2db";
$fa-var-crown: "\f521";
$fa-var-weight-hanging: "\f5cd";
$fa-var-xmarks-lines: "\e59a";
$fa-var-file-prescription: "\f572";
$fa-var-weight-scale: "\f496";
$fa-var-weight: "\f496";
$fa-var-user-group: "\f500";
$fa-var-user-friends: "\f500";
$fa-var-arrow-up-a-z: "\f15e";
$fa-var-sort-alpha-up: "\f15e";
$fa-var-chess-knight: "\f441";
$fa-var-face-laugh-squint: "\f59b";
$fa-var-laugh-squint: "\f59b";
$fa-var-wheelchair: "\f193";
$fa-var-circle-arrow-up: "\f0aa";
$fa-var-arrow-circle-up: "\f0aa";
$fa-var-toggle-on: "\f205";
$fa-var-person-walking: "\f554";
$fa-var-walking: "\f554";
$fa-var-l: "\4c";
$fa-var-fire: "\f06d";
$fa-var-bed-pulse: "\f487";
$fa-var-procedures: "\f487";
$fa-var-shuttle-space: "\f197";
$fa-var-space-shuttle: "\f197";
$fa-var-face-laugh: "\f599";
$fa-var-laugh: "\f599";
$fa-var-folder-open: "\f07c";
$fa-var-heart-circle-plus: "\e500";
$fa-var-code-fork: "\e13b";
$fa-var-city: "\f64f";
$fa-var-microphone-lines: "\f3c9";
$fa-var-microphone-alt: "\f3c9";
$fa-var-pepper-hot: "\f816";
$fa-var-unlock: "\f09c";
$fa-var-colon-sign: "\e140";
$fa-var-headset: "\f590";
$fa-var-store-slash: "\e071";
$fa-var-road-circle-xmark: "\e566";
$fa-var-user-minus: "\f503";
$fa-var-mars-stroke-up: "\f22a";
$fa-var-mars-stroke-v: "\f22a";
$fa-var-champagne-glasses: "\f79f";
$fa-var-glass-cheers: "\f79f";
$fa-var-clipboard: "\f328";
$fa-var-house-circle-exclamation: "\e50a";
$fa-var-file-arrow-up: "\f574";
$fa-var-file-upload: "\f574";
$fa-var-wifi: "\f1eb";
$fa-var-wifi-3: "\f1eb";
$fa-var-wifi-strong: "\f1eb";
$fa-var-bath: "\f2cd";
$fa-var-bathtub: "\f2cd";
$fa-var-underline: "\f0cd";
$fa-var-user-pen: "\f4ff";
$fa-var-user-edit: "\f4ff";
$fa-var-signature: "\f5b7";
$fa-var-stroopwafel: "\f551";
$fa-var-bold: "\f032";
$fa-var-anchor-lock: "\e4ad";
$fa-var-building-ngo: "\e4d7";
$fa-var-manat-sign: "\e1d5";
$fa-var-not-equal: "\f53e";
$fa-var-border-top-left: "\f853";
$fa-var-border-style: "\f853";
$fa-var-map-location-dot: "\f5a0";
$fa-var-map-marked-alt: "\f5a0";
$fa-var-jedi: "\f669";
$fa-var-square-poll-vertical: "\f681";
$fa-var-poll: "\f681";
$fa-var-mug-hot: "\f7b6";
$fa-var-car-battery: "\f5df";
$fa-var-battery-car: "\f5df";
$fa-var-gift: "\f06b";
$fa-var-dice-two: "\f528";
$fa-var-chess-queen: "\f445";
$fa-var-glasses: "\f530";
$fa-var-chess-board: "\f43c";
$fa-var-building-circle-check: "\e4d2";
$fa-var-person-chalkboard: "\e53d";
$fa-var-mars-stroke-right: "\f22b";
$fa-var-mars-stroke-h: "\f22b";
$fa-var-hand-back-fist: "\f255";
$fa-var-hand-rock: "\f255";
$fa-var-square-caret-up: "\f151";
$fa-var-caret-square-up: "\f151";
$fa-var-cloud-showers-water: "\e4e4";
$fa-var-chart-bar: "\f080";
$fa-var-bar-chart: "\f080";
$fa-var-hands-bubbles: "\e05e";
$fa-var-hands-wash: "\e05e";
$fa-var-less-than-equal: "\f537";
$fa-var-train: "\f238";
$fa-var-eye-low-vision: "\f2a8";
$fa-var-low-vision: "\f2a8";
$fa-var-crow: "\f520";
$fa-var-sailboat: "\e445";
$fa-var-window-restore: "\f2d2";
$fa-var-square-plus: "\f0fe";
$fa-var-plus-square: "\f0fe";
$fa-var-torii-gate: "\f6a1";
$fa-var-frog: "\f52e";
$fa-var-bucket: "\e4cf";
$fa-var-image: "\f03e";
$fa-var-microphone: "\f130";
$fa-var-cow: "\f6c8";
$fa-var-caret-up: "\f0d8";
$fa-var-screwdriver: "\f54a";
$fa-var-folder-closed: "\e185";
$fa-var-house-tsunami: "\e515";
$fa-var-square-nfi: "\e576";
$fa-var-arrow-up-from-ground-water: "\e4b5";
$fa-var-martini-glass: "\f57b";
$fa-var-glass-martini-alt: "\f57b";
$fa-var-rotate-left: "\f2ea";
$fa-var-rotate-back: "\f2ea";
$fa-var-rotate-backward: "\f2ea";
$fa-var-undo-alt: "\f2ea";
$fa-var-table-columns: "\f0db";
$fa-var-columns: "\f0db";
$fa-var-lemon: "\f094";
$fa-var-head-side-mask: "\e063";
$fa-var-handshake: "\f2b5";
$fa-var-gem: "\f3a5";
$fa-var-dolly: "\f472";
$fa-var-dolly-box: "\f472";
$fa-var-smoking: "\f48d";
$fa-var-minimize: "\f78c";
$fa-var-compress-arrows-alt: "\f78c";
$fa-var-monument: "\f5a6";
$fa-var-snowplow: "\f7d2";
$fa-var-angles-right: "\f101";
$fa-var-angle-double-right: "\f101";
$fa-var-cannabis: "\f55f";
$fa-var-circle-play: "\f144";
$fa-var-play-circle: "\f144";
$fa-var-tablets: "\f490";
$fa-var-ethernet: "\f796";
$fa-var-euro-sign: "\f153";
$fa-var-eur: "\f153";
$fa-var-euro: "\f153";
$fa-var-chair: "\f6c0";
$fa-var-circle-check: "\f058";
$fa-var-check-circle: "\f058";
$fa-var-circle-stop: "\f28d";
$fa-var-stop-circle: "\f28d";
$fa-var-compass-drafting: "\f568";
$fa-var-drafting-compass: "\f568";
$fa-var-plate-wheat: "\e55a";
$fa-var-icicles: "\f7ad";
$fa-var-person-shelter: "\e54f";
$fa-var-neuter: "\f22c";
$fa-var-id-badge: "\f2c1";
$fa-var-marker: "\f5a1";
$fa-var-face-laugh-beam: "\f59a";
$fa-var-laugh-beam: "\f59a";
$fa-var-helicopter-symbol: "\e502";
$fa-var-universal-access: "\f29a";
$fa-var-circle-chevron-up: "\f139";
$fa-var-chevron-circle-up: "\f139";
$fa-var-lari-sign: "\e1c8";
$fa-var-volcano: "\f770";
$fa-var-person-walking-dashed-line-arrow-right: "\e553";
$fa-var-sterling-sign: "\f154";
$fa-var-gbp: "\f154";
$fa-var-pound-sign: "\f154";
$fa-var-viruses: "\e076";
$fa-var-square-person-confined: "\e577";
$fa-var-user-tie: "\f508";
$fa-var-arrow-down-long: "\f175";
$fa-var-long-arrow-down: "\f175";
$fa-var-tent-arrow-down-to-line: "\e57e";
$fa-var-certificate: "\f0a3";
$fa-var-reply-all: "\f122";
$fa-var-mail-reply-all: "\f122";
$fa-var-suitcase: "\f0f2";
$fa-var-person-skating: "\f7c5";
$fa-var-skating: "\f7c5";
$fa-var-filter-circle-dollar: "\f662";
$fa-var-funnel-dollar: "\f662";
$fa-var-camera-retro: "\f083";
$fa-var-circle-arrow-down: "\f0ab";
$fa-var-arrow-circle-down: "\f0ab";
$fa-var-file-import: "\f56f";
$fa-var-arrow-right-to-file: "\f56f";
$fa-var-square-arrow-up-right: "\f14c";
$fa-var-external-link-square: "\f14c";
$fa-var-box-open: "\f49e";
$fa-var-scroll: "\f70e";
$fa-var-spa: "\f5bb";
$fa-var-location-pin-lock: "\e51f";
$fa-var-pause: "\f04c";
$fa-var-hill-avalanche: "\e507";
$fa-var-temperature-empty: "\f2cb";
$fa-var-temperature-0: "\f2cb";
$fa-var-thermometer-0: "\f2cb";
$fa-var-thermometer-empty: "\f2cb";
$fa-var-bomb: "\f1e2";
$fa-var-registered: "\f25d";
$fa-var-address-card: "\f2bb";
$fa-var-contact-card: "\f2bb";
$fa-var-vcard: "\f2bb";
$fa-var-scale-unbalanced-flip: "\f516";
$fa-var-balance-scale-right: "\f516";
$fa-var-subscript: "\f12c";
$fa-var-diamond-turn-right: "\f5eb";
$fa-var-directions: "\f5eb";
$fa-var-burst: "\e4dc";
$fa-var-house-laptop: "\e066";
$fa-var-laptop-house: "\e066";
$fa-var-face-tired: "\f5c8";
$fa-var-tired: "\f5c8";
$fa-var-money-bills: "\e1f3";
$fa-var-smog: "\f75f";
$fa-var-crutch: "\f7f7";
$fa-var-cloud-arrow-up: "\f0ee";
$fa-var-cloud-upload: "\f0ee";
$fa-var-cloud-upload-alt: "\f0ee";
$fa-var-palette: "\f53f";
$fa-var-arrows-turn-right: "\e4c0";
$fa-var-vest: "\e085";
$fa-var-ferry: "\e4ea";
$fa-var-arrows-down-to-people: "\e4b9";
$fa-var-seedling: "\f4d8";
$fa-var-sprout: "\f4d8";
$fa-var-left-right: "\f337";
$fa-var-arrows-alt-h: "\f337";
$fa-var-boxes-packing: "\e4c7";
$fa-var-circle-arrow-left: "\f0a8";
$fa-var-arrow-circle-left: "\f0a8";
$fa-var-group-arrows-rotate: "\e4f6";
$fa-var-bowl-food: "\e4c6";
$fa-var-candy-cane: "\f786";
$fa-var-arrow-down-wide-short: "\f160";
$fa-var-sort-amount-asc: "\f160";
$fa-var-sort-amount-down: "\f160";
$fa-var-cloud-bolt: "\f76c";
$fa-var-thunderstorm: "\f76c";
$fa-var-text-slash: "\f87d";
$fa-var-remove-format: "\f87d";
$fa-var-face-smile-wink: "\f4da";
$fa-var-smile-wink: "\f4da";
$fa-var-file-word: "\f1c2";
$fa-var-file-powerpoint: "\f1c4";
$fa-var-arrows-left-right: "\f07e";
$fa-var-arrows-h: "\f07e";
$fa-var-house-lock: "\e510";
$fa-var-cloud-arrow-down: "\f0ed";
$fa-var-cloud-download: "\f0ed";
$fa-var-cloud-download-alt: "\f0ed";
$fa-var-children: "\e4e1";
$fa-var-chalkboard: "\f51b";
$fa-var-blackboard: "\f51b";
$fa-var-user-large-slash: "\f4fa";
$fa-var-user-alt-slash: "\f4fa";
$fa-var-envelope-open: "\f2b6";
$fa-var-handshake-simple-slash: "\e05f";
$fa-var-handshake-alt-slash: "\e05f";
$fa-var-mattress-pillow: "\e525";
$fa-var-guarani-sign: "\e19a";
$fa-var-arrows-rotate: "\f021";
$fa-var-refresh: "\f021";
$fa-var-sync: "\f021";
$fa-var-fire-extinguisher: "\f134";
$fa-var-cruzeiro-sign: "\e152";
$fa-var-greater-than-equal: "\f532";
$fa-var-shield-halved: "\f3ed";
$fa-var-shield-alt: "\f3ed";
$fa-var-book-atlas: "\f558";
$fa-var-atlas: "\f558";
$fa-var-virus: "\e074";
$fa-var-envelope-circle-check: "\e4e8";
$fa-var-layer-group: "\f5fd";
$fa-var-arrows-to-dot: "\e4be";
$fa-var-archway: "\f557";
$fa-var-heart-circle-check: "\e4fd";
$fa-var-house-chimney-crack: "\f6f1";
$fa-var-house-damage: "\f6f1";
$fa-var-file-zipper: "\f1c6";
$fa-var-file-archive: "\f1c6";
$fa-var-square: "\f0c8";
$fa-var-martini-glass-empty: "\f000";
$fa-var-glass-martini: "\f000";
$fa-var-couch: "\f4b8";
$fa-var-cedi-sign: "\e0df";
$fa-var-italic: "\f033";
$fa-var-church: "\f51d";
$fa-var-comments-dollar: "\f653";
$fa-var-democrat: "\f747";
$fa-var-z: "\5a";
$fa-var-person-skiing: "\f7c9";
$fa-var-skiing: "\f7c9";
$fa-var-road-lock: "\e567";
$fa-var-a: "\41";
$fa-var-temperature-arrow-down: "\e03f";
$fa-var-temperature-down: "\e03f";
$fa-var-feather-pointed: "\f56b";
$fa-var-feather-alt: "\f56b";
$fa-var-p: "\50";
$fa-var-snowflake: "\f2dc";
$fa-var-newspaper: "\f1ea";
$fa-var-rectangle-ad: "\f641";
$fa-var-ad: "\f641";
$fa-var-circle-arrow-right: "\f0a9";
$fa-var-arrow-circle-right: "\f0a9";
$fa-var-filter-circle-xmark: "\e17b";
$fa-var-locust: "\e520";
$fa-var-sort: "\f0dc";
$fa-var-unsorted: "\f0dc";
$fa-var-list-ol: "\f0cb";
$fa-var-list-1-2: "\f0cb";
$fa-var-list-numeric: "\f0cb";
$fa-var-person-dress-burst: "\e544";
$fa-var-money-check-dollar: "\f53d";
$fa-var-money-check-alt: "\f53d";
$fa-var-vector-square: "\f5cb";
$fa-var-bread-slice: "\f7ec";
$fa-var-language: "\f1ab";
$fa-var-face-kiss-wink-heart: "\f598";
$fa-var-kiss-wink-heart: "\f598";
$fa-var-filter: "\f0b0";
$fa-var-question: "\3f";
$fa-var-file-signature: "\f573";
$fa-var-up-down-left-right: "\f0b2";
$fa-var-arrows-alt: "\f0b2";
$fa-var-house-chimney-user: "\e065";
$fa-var-hand-holding-heart: "\f4be";
$fa-var-puzzle-piece: "\f12e";
$fa-var-money-check: "\f53c";
$fa-var-star-half-stroke: "\f5c0";
$fa-var-star-half-alt: "\f5c0";
$fa-var-code: "\f121";
$fa-var-whiskey-glass: "\f7a0";
$fa-var-glass-whiskey: "\f7a0";
$fa-var-building-circle-exclamation: "\e4d3";
$fa-var-magnifying-glass-chart: "\e522";
$fa-var-arrow-up-right-from-square: "\f08e";
$fa-var-external-link: "\f08e";
$fa-var-cubes-stacked: "\e4e6";
$fa-var-won-sign: "\f159";
$fa-var-krw: "\f159";
$fa-var-won: "\f159";
$fa-var-virus-covid: "\e4a8";
$fa-var-austral-sign: "\e0a9";
$fa-var-f: "\46";
$fa-var-leaf: "\f06c";
$fa-var-road: "\f018";
$fa-var-taxi: "\f1ba";
$fa-var-cab: "\f1ba";
$fa-var-person-circle-plus: "\e541";
$fa-var-chart-pie: "\f200";
$fa-var-pie-chart: "\f200";
$fa-var-bolt-lightning: "\e0b7";
$fa-var-sack-xmark: "\e56a";
$fa-var-file-excel: "\f1c3";
$fa-var-file-contract: "\f56c";
$fa-var-fish-fins: "\e4f2";
$fa-var-building-flag: "\e4d5";
$fa-var-face-grin-beam: "\f582";
$fa-var-grin-beam: "\f582";
$fa-var-object-ungroup: "\f248";
$fa-var-poop: "\f619";
$fa-var-location-pin: "\f041";
$fa-var-map-marker: "\f041";
$fa-var-kaaba: "\f66b";
$fa-var-toilet-paper: "\f71e";
$fa-var-helmet-safety: "\f807";
$fa-var-hard-hat: "\f807";
$fa-var-hat-hard: "\f807";
$fa-var-eject: "\f052";
$fa-var-circle-right: "\f35a";
$fa-var-arrow-alt-circle-right: "\f35a";
$fa-var-plane-circle-check: "\e555";
$fa-var-face-rolling-eyes: "\f5a5";
$fa-var-meh-rolling-eyes: "\f5a5";
$fa-var-object-group: "\f247";
$fa-var-chart-line: "\f201";
$fa-var-line-chart: "\f201";
$fa-var-mask-ventilator: "\e524";
$fa-var-arrow-right: "\f061";
$fa-var-signs-post: "\f277";
$fa-var-map-signs: "\f277";
$fa-var-cash-register: "\f788";
$fa-var-person-circle-question: "\e542";
$fa-var-h: "\48";
$fa-var-tarp: "\e57b";
$fa-var-screwdriver-wrench: "\f7d9";
$fa-var-tools: "\f7d9";
$fa-var-arrows-to-eye: "\e4bf";
$fa-var-plug-circle-bolt: "\e55b";
$fa-var-heart: "\f004";
$fa-var-mars-and-venus: "\f224";
$fa-var-house-user: "\e1b0";
$fa-var-home-user: "\e1b0";
$fa-var-dumpster-fire: "\f794";
$fa-var-house-crack: "\e3b1";
$fa-var-martini-glass-citrus: "\f561";
$fa-var-cocktail: "\f561";
$fa-var-face-surprise: "\f5c2";
$fa-var-surprise: "\f5c2";
$fa-var-bottle-water: "\e4c5";
$fa-var-circle-pause: "\f28b";
$fa-var-pause-circle: "\f28b";
$fa-var-toilet-paper-slash: "\e072";
$fa-var-apple-whole: "\f5d1";
$fa-var-apple-alt: "\f5d1";
$fa-var-kitchen-set: "\e51a";
$fa-var-r: "\52";
$fa-var-temperature-quarter: "\f2ca";
$fa-var-temperature-1: "\f2ca";
$fa-var-thermometer-1: "\f2ca";
$fa-var-thermometer-quarter: "\f2ca";
$fa-var-cube: "\f1b2";
$fa-var-bitcoin-sign: "\e0b4";
$fa-var-shield-dog: "\e573";
$fa-var-solar-panel: "\f5ba";
$fa-var-lock-open: "\f3c1";
$fa-var-elevator: "\e16d";
$fa-var-money-bill-transfer: "\e528";
$fa-var-money-bill-trend-up: "\e529";
$fa-var-house-flood-water-circle-arrow-right: "\e50f";
$fa-var-square-poll-horizontal: "\f682";
$fa-var-poll-h: "\f682";
$fa-var-circle: "\f111";
$fa-var-backward-fast: "\f049";
$fa-var-fast-backward: "\f049";
$fa-var-recycle: "\f1b8";
$fa-var-user-astronaut: "\f4fb";
$fa-var-plane-slash: "\e069";
$fa-var-trademark: "\f25c";
$fa-var-basketball: "\f434";
$fa-var-basketball-ball: "\f434";
$fa-var-satellite-dish: "\f7c0";
$fa-var-circle-up: "\f35b";
$fa-var-arrow-alt-circle-up: "\f35b";
$fa-var-mobile-screen-button: "\f3cd";
$fa-var-mobile-alt: "\f3cd";
$fa-var-volume-high: "\f028";
$fa-var-volume-up: "\f028";
$fa-var-users-rays: "\e593";
$fa-var-wallet: "\f555";
$fa-var-clipboard-check: "\f46c";
$fa-var-file-audio: "\f1c7";
$fa-var-burger: "\f805";
$fa-var-hamburger: "\f805";
$fa-var-wrench: "\f0ad";
$fa-var-bugs: "\e4d0";
$fa-var-rupee-sign: "\f156";
$fa-var-rupee: "\f156";
$fa-var-file-image: "\f1c5";
$fa-var-circle-question: "\f059";
$fa-var-question-circle: "\f059";
$fa-var-plane-departure: "\f5b0";
$fa-var-handshake-slash: "\e060";
$fa-var-book-bookmark: "\e0bb";
$fa-var-code-branch: "\f126";
$fa-var-hat-cowboy: "\f8c0";
$fa-var-bridge: "\e4c8";
$fa-var-phone-flip: "\f879";
$fa-var-phone-alt: "\f879";
$fa-var-truck-front: "\e2b7";
$fa-var-cat: "\f6be";
$fa-var-anchor-circle-exclamation: "\e4ab";
$fa-var-truck-field: "\e58d";
$fa-var-route: "\f4d7";
$fa-var-clipboard-question: "\e4e3";
$fa-var-panorama: "\e209";
$fa-var-comment-medical: "\f7f5";
$fa-var-teeth-open: "\f62f";
$fa-var-file-circle-minus: "\e4ed";
$fa-var-tags: "\f02c";
$fa-var-wine-glass: "\f4e3";
$fa-var-forward-fast: "\f050";
$fa-var-fast-forward: "\f050";
$fa-var-face-meh-blank: "\f5a4";
$fa-var-meh-blank: "\f5a4";
$fa-var-square-parking: "\f540";
$fa-var-parking: "\f540";
$fa-var-house-signal: "\e012";
$fa-var-bars-progress: "\f828";
$fa-var-tasks-alt: "\f828";
$fa-var-faucet-drip: "\e006";
$fa-var-cart-flatbed: "\f474";
$fa-var-dolly-flatbed: "\f474";
$fa-var-ban-smoking: "\f54d";
$fa-var-smoking-ban: "\f54d";
$fa-var-terminal: "\f120";
$fa-var-mobile-button: "\f10b";
$fa-var-house-medical-flag: "\e514";
$fa-var-basket-shopping: "\f291";
$fa-var-shopping-basket: "\f291";
$fa-var-tape: "\f4db";
$fa-var-bus-simple: "\f55e";
$fa-var-bus-alt: "\f55e";
$fa-var-eye: "\f06e";
$fa-var-face-sad-cry: "\f5b3";
$fa-var-sad-cry: "\f5b3";
$fa-var-audio-description: "\f29e";
$fa-var-person-military-to-person: "\e54c";
$fa-var-file-shield: "\e4f0";
$fa-var-user-slash: "\f506";
$fa-var-pen: "\f304";
$fa-var-tower-observation: "\e586";
$fa-var-file-code: "\f1c9";
$fa-var-signal: "\f012";
$fa-var-signal-5: "\f012";
$fa-var-signal-perfect: "\f012";
$fa-var-bus: "\f207";
$fa-var-heart-circle-xmark: "\e501";
$fa-var-house-chimney: "\e3af";
$fa-var-home-lg: "\e3af";
$fa-var-window-maximize: "\f2d0";
$fa-var-face-frown: "\f119";
$fa-var-frown: "\f119";
$fa-var-prescription: "\f5b1";
$fa-var-shop: "\f54f";
$fa-var-store-alt: "\f54f";
$fa-var-floppy-disk: "\f0c7";
$fa-var-save: "\f0c7";
$fa-var-vihara: "\f6a7";
$fa-var-scale-unbalanced: "\f515";
$fa-var-balance-scale-left: "\f515";
$fa-var-sort-up: "\f0de";
$fa-var-sort-asc: "\f0de";
$fa-var-comment-dots: "\f4ad";
$fa-var-commenting: "\f4ad";
$fa-var-plant-wilt: "\e5aa";
$fa-var-diamond: "\f219";
$fa-var-face-grin-squint: "\f585";
$fa-var-grin-squint: "\f585";
$fa-var-hand-holding-dollar: "\f4c0";
$fa-var-hand-holding-usd: "\f4c0";
$fa-var-bacterium: "\e05a";
$fa-var-hand-pointer: "\f25a";
$fa-var-drum-steelpan: "\f56a";
$fa-var-hand-scissors: "\f257";
$fa-var-hands-praying: "\f684";
$fa-var-praying-hands: "\f684";
$fa-var-arrow-rotate-right: "\f01e";
$fa-var-arrow-right-rotate: "\f01e";
$fa-var-arrow-rotate-forward: "\f01e";
$fa-var-redo: "\f01e";
$fa-var-biohazard: "\f780";
$fa-var-location-crosshairs: "\f601";
$fa-var-location: "\f601";
$fa-var-mars-double: "\f227";
$fa-var-child-dress: "\e59c";
$fa-var-users-between-lines: "\e591";
$fa-var-lungs-virus: "\e067";
$fa-var-face-grin-tears: "\f588";
$fa-var-grin-tears: "\f588";
$fa-var-phone: "\f095";
$fa-var-calendar-xmark: "\f273";
$fa-var-calendar-times: "\f273";
$fa-var-child-reaching: "\e59d";
$fa-var-head-side-virus: "\e064";
$fa-var-user-gear: "\f4fe";
$fa-var-user-cog: "\f4fe";
$fa-var-arrow-up-1-9: "\f163";
$fa-var-sort-numeric-up: "\f163";
$fa-var-door-closed: "\f52a";
$fa-var-shield-virus: "\e06c";
$fa-var-dice-six: "\f526";
$fa-var-mosquito-net: "\e52c";
$fa-var-bridge-water: "\e4ce";
$fa-var-person-booth: "\f756";
$fa-var-text-width: "\f035";
$fa-var-hat-wizard: "\f6e8";
$fa-var-pen-fancy: "\f5ac";
$fa-var-person-digging: "\f85e";
$fa-var-digging: "\f85e";
$fa-var-trash: "\f1f8";
$fa-var-gauge-simple: "\f629";
$fa-var-gauge-simple-med: "\f629";
$fa-var-tachometer-average: "\f629";
$fa-var-book-medical: "\f7e6";
$fa-var-poo: "\f2fe";
$fa-var-quote-right: "\f10e";
$fa-var-quote-right-alt: "\f10e";
$fa-var-shirt: "\f553";
$fa-var-t-shirt: "\f553";
$fa-var-tshirt: "\f553";
$fa-var-cubes: "\f1b3";
$fa-var-divide: "\f529";
$fa-var-tenge-sign: "\f7d7";
$fa-var-tenge: "\f7d7";
$fa-var-headphones: "\f025";
$fa-var-hands-holding: "\f4c2";
$fa-var-hands-clapping: "\e1a8";
$fa-var-republican: "\f75e";
$fa-var-arrow-left: "\f060";
$fa-var-person-circle-xmark: "\e543";
$fa-var-ruler: "\f545";
$fa-var-align-left: "\f036";
$fa-var-dice-d6: "\f6d1";
$fa-var-restroom: "\f7bd";
$fa-var-j: "\4a";
$fa-var-users-viewfinder: "\e595";
$fa-var-file-video: "\f1c8";
$fa-var-up-right-from-square: "\f35d";
$fa-var-external-link-alt: "\f35d";
$fa-var-table-cells: "\f00a";
$fa-var-th: "\f00a";
$fa-var-file-pdf: "\f1c1";
$fa-var-book-bible: "\f647";
$fa-var-bible: "\f647";
$fa-var-o: "\4f";
$fa-var-suitcase-medical: "\f0fa";
$fa-var-medkit: "\f0fa";
$fa-var-user-secret: "\f21b";
$fa-var-otter: "\f700";
$fa-var-person-dress: "\f182";
$fa-var-female: "\f182";
$fa-var-comment-dollar: "\f651";
$fa-var-business-time: "\f64a";
$fa-var-briefcase-clock: "\f64a";
$fa-var-table-cells-large: "\f009";
$fa-var-th-large: "\f009";
$fa-var-book-tanakh: "\f827";
$fa-var-tanakh: "\f827";
$fa-var-phone-volume: "\f2a0";
$fa-var-volume-control-phone: "\f2a0";
$fa-var-hat-cowboy-side: "\f8c1";
$fa-var-clipboard-user: "\f7f3";
$fa-var-child: "\f1ae";
$fa-var-lira-sign: "\f195";
$fa-var-satellite: "\f7bf";
$fa-var-plane-lock: "\e558";
$fa-var-tag: "\f02b";
$fa-var-comment: "\f075";
$fa-var-cake-candles: "\f1fd";
$fa-var-birthday-cake: "\f1fd";
$fa-var-cake: "\f1fd";
$fa-var-envelope: "\f0e0";
$fa-var-angles-up: "\f102";
$fa-var-angle-double-up: "\f102";
$fa-var-paperclip: "\f0c6";
$fa-var-arrow-right-to-city: "\e4b3";
$fa-var-ribbon: "\f4d6";
$fa-var-lungs: "\f604";
$fa-var-arrow-up-9-1: "\f887";
$fa-var-sort-numeric-up-alt: "\f887";
$fa-var-litecoin-sign: "\e1d3";
$fa-var-border-none: "\f850";
$fa-var-circle-nodes: "\e4e2";
$fa-var-parachute-box: "\f4cd";
$fa-var-indent: "\f03c";
$fa-var-truck-field-un: "\e58e";
$fa-var-hourglass: "\f254";
$fa-var-hourglass-empty: "\f254";
$fa-var-mountain: "\f6fc";
$fa-var-user-doctor: "\f0f0";
$fa-var-user-md: "\f0f0";
$fa-var-circle-info: "\f05a";
$fa-var-info-circle: "\f05a";
$fa-var-cloud-meatball: "\f73b";
$fa-var-camera: "\f030";
$fa-var-camera-alt: "\f030";
$fa-var-square-virus: "\e578";
$fa-var-meteor: "\f753";
$fa-var-car-on: "\e4dd";
$fa-var-sleigh: "\f7cc";
$fa-var-arrow-down-1-9: "\f162";
$fa-var-sort-numeric-asc: "\f162";
$fa-var-sort-numeric-down: "\f162";
$fa-var-hand-holding-droplet: "\f4c1";
$fa-var-hand-holding-water: "\f4c1";
$fa-var-water: "\f773";
$fa-var-calendar-check: "\f274";
$fa-var-braille: "\f2a1";
$fa-var-prescription-bottle-medical: "\f486";
$fa-var-prescription-bottle-alt: "\f486";
$fa-var-landmark: "\f66f";
$fa-var-truck: "\f0d1";
$fa-var-crosshairs: "\f05b";
$fa-var-person-cane: "\e53c";
$fa-var-tent: "\e57d";
$fa-var-vest-patches: "\e086";
$fa-var-check-double: "\f560";
$fa-var-arrow-down-a-z: "\f15d";
$fa-var-sort-alpha-asc: "\f15d";
$fa-var-sort-alpha-down: "\f15d";
$fa-var-money-bill-wheat: "\e52a";
$fa-var-cookie: "\f563";
$fa-var-arrow-rotate-left: "\f0e2";
$fa-var-arrow-left-rotate: "\f0e2";
$fa-var-arrow-rotate-back: "\f0e2";
$fa-var-arrow-rotate-backward: "\f0e2";
$fa-var-undo: "\f0e2";
$fa-var-hard-drive: "\f0a0";
$fa-var-hdd: "\f0a0";
$fa-var-face-grin-squint-tears: "\f586";
$fa-var-grin-squint-tears: "\f586";
$fa-var-dumbbell: "\f44b";
$fa-var-rectangle-list: "\f022";
$fa-var-list-alt: "\f022";
$fa-var-tarp-droplet: "\e57c";
$fa-var-house-medical-circle-check: "\e511";
$fa-var-person-skiing-nordic: "\f7ca";
$fa-var-skiing-nordic: "\f7ca";
$fa-var-calendar-plus: "\f271";
$fa-var-plane-arrival: "\f5af";
$fa-var-circle-left: "\f359";
$fa-var-arrow-alt-circle-left: "\f359";
$fa-var-train-subway: "\f239";
$fa-var-subway: "\f239";
$fa-var-chart-gantt: "\e0e4";
$fa-var-indian-rupee-sign: "\e1bc";
$fa-var-indian-rupee: "\e1bc";
$fa-var-inr: "\e1bc";
$fa-var-crop-simple: "\f565";
$fa-var-crop-alt: "\f565";
$fa-var-money-bill-1: "\f3d1";
$fa-var-money-bill-alt: "\f3d1";
$fa-var-left-long: "\f30a";
$fa-var-long-arrow-alt-left: "\f30a";
$fa-var-dna: "\f471";
$fa-var-virus-slash: "\e075";
$fa-var-minus: "\f068";
$fa-var-subtract: "\f068";
$fa-var-chess: "\f439";
$fa-var-arrow-left-long: "\f177";
$fa-var-long-arrow-left: "\f177";
$fa-var-plug-circle-check: "\e55c";
$fa-var-street-view: "\f21d";
$fa-var-franc-sign: "\e18f";
$fa-var-volume-off: "\f026";
$fa-var-hands-asl-interpreting: "\f2a3";
$fa-var-american-sign-language-interpreting: "\f2a3";
$fa-var-asl-interpreting: "\f2a3";
$fa-var-hands-american-sign-language-interpreting: "\f2a3";
$fa-var-gear: "\f013";
$fa-var-cog: "\f013";
$fa-var-droplet-slash: "\f5c7";
$fa-var-tint-slash: "\f5c7";
$fa-var-mosque: "\f678";
$fa-var-mosquito: "\e52b";
$fa-var-star-of-david: "\f69a";
$fa-var-person-military-rifle: "\e54b";
$fa-var-cart-shopping: "\f07a";
$fa-var-shopping-cart: "\f07a";
$fa-var-vials: "\f493";
$fa-var-plug-circle-plus: "\e55f";
$fa-var-place-of-worship: "\f67f";
$fa-var-grip-vertical: "\f58e";
$fa-var-arrow-turn-up: "\f148";
$fa-var-level-up: "\f148";
$fa-var-u: "\55";
$fa-var-square-root-variable: "\f698";
$fa-var-square-root-alt: "\f698";
$fa-var-clock: "\f017";
$fa-var-clock-four: "\f017";
$fa-var-backward-step: "\f048";
$fa-var-step-backward: "\f048";
$fa-var-pallet: "\f482";
$fa-var-faucet: "\e005";
$fa-var-baseball-bat-ball: "\f432";
$fa-var-s: "\53";
$fa-var-timeline: "\e29c";
$fa-var-keyboard: "\f11c";
$fa-var-caret-down: "\f0d7";
$fa-var-house-chimney-medical: "\f7f2";
$fa-var-clinic-medical: "\f7f2";
$fa-var-temperature-three-quarters: "\f2c8";
$fa-var-temperature-3: "\f2c8";
$fa-var-thermometer-3: "\f2c8";
$fa-var-thermometer-three-quarters: "\f2c8";
$fa-var-mobile-screen: "\f3cf";
$fa-var-mobile-android-alt: "\f3cf";
$fa-var-plane-up: "\e22d";
$fa-var-piggy-bank: "\f4d3";
$fa-var-battery-half: "\f242";
$fa-var-battery-3: "\f242";
$fa-var-mountain-city: "\e52e";
$fa-var-coins: "\f51e";
$fa-var-khanda: "\f66d";
$fa-var-sliders: "\f1de";
$fa-var-sliders-h: "\f1de";
$fa-var-folder-tree: "\f802";
$fa-var-network-wired: "\f6ff";
$fa-var-map-pin: "\f276";
$fa-var-hamsa: "\f665";
$fa-var-cent-sign: "\e3f5";
$fa-var-flask: "\f0c3";
$fa-var-person-pregnant: "\e31e";
$fa-var-wand-sparkles: "\f72b";
$fa-var-ellipsis-vertical: "\f142";
$fa-var-ellipsis-v: "\f142";
$fa-var-ticket: "\f145";
$fa-var-power-off: "\f011";
$fa-var-right-long: "\f30b";
$fa-var-long-arrow-alt-right: "\f30b";
$fa-var-flag-usa: "\f74d";
$fa-var-laptop-file: "\e51d";
$fa-var-tty: "\f1e4";
$fa-var-teletype: "\f1e4";
$fa-var-diagram-next: "\e476";
$fa-var-person-rifle: "\e54e";
$fa-var-house-medical-circle-exclamation: "\e512";
$fa-var-closed-captioning: "\f20a";
$fa-var-person-hiking: "\f6ec";
$fa-var-hiking: "\f6ec";
$fa-var-venus-double: "\f226";
$fa-var-images: "\f302";
$fa-var-calculator: "\f1ec";
$fa-var-people-pulling: "\e535";
$fa-var-n: "\4e";
$fa-var-cable-car: "\f7da";
$fa-var-tram: "\f7da";
$fa-var-cloud-rain: "\f73d";
$fa-var-building-circle-xmark: "\e4d4";
$fa-var-ship: "\f21a";
$fa-var-arrows-down-to-line: "\e4b8";
$fa-var-download: "\f019";
$fa-var-face-grin: "\f580";
$fa-var-grin: "\f580";
$fa-var-delete-left: "\f55a";
$fa-var-backspace: "\f55a";
$fa-var-eye-dropper: "\f1fb";
$fa-var-eye-dropper-empty: "\f1fb";
$fa-var-eyedropper: "\f1fb";
$fa-var-file-circle-check: "\e5a0";
$fa-var-forward: "\f04e";
$fa-var-mobile: "\f3ce";
$fa-var-mobile-android: "\f3ce";
$fa-var-mobile-phone: "\f3ce";
$fa-var-face-meh: "\f11a";
$fa-var-meh: "\f11a";
$fa-var-align-center: "\f037";
$fa-var-book-skull: "\f6b7";
$fa-var-book-dead: "\f6b7";
$fa-var-id-card: "\f2c2";
$fa-var-drivers-license: "\f2c2";
$fa-var-outdent: "\f03b";
$fa-var-dedent: "\f03b";
$fa-var-heart-circle-exclamation: "\e4fe";
$fa-var-house: "\f015";
$fa-var-home: "\f015";
$fa-var-home-alt: "\f015";
$fa-var-home-lg-alt: "\f015";
$fa-var-calendar-week: "\f784";
$fa-var-laptop-medical: "\f812";
$fa-var-b: "\42";
$fa-var-file-medical: "\f477";
$fa-var-dice-one: "\f525";
$fa-var-kiwi-bird: "\f535";
$fa-var-arrow-right-arrow-left: "\f0ec";
$fa-var-exchange: "\f0ec";
$fa-var-rotate-right: "\f2f9";
$fa-var-redo-alt: "\f2f9";
$fa-var-rotate-forward: "\f2f9";
$fa-var-utensils: "\f2e7";
$fa-var-cutlery: "\f2e7";
$fa-var-arrow-up-wide-short: "\f161";
$fa-var-sort-amount-up: "\f161";
$fa-var-mill-sign: "\e1ed";
$fa-var-bowl-rice: "\e2eb";
$fa-var-skull: "\f54c";
$fa-var-tower-broadcast: "\f519";
$fa-var-broadcast-tower: "\f519";
$fa-var-truck-pickup: "\f63c";
$fa-var-up-long: "\f30c";
$fa-var-long-arrow-alt-up: "\f30c";
$fa-var-stop: "\f04d";
$fa-var-code-merge: "\f387";
$fa-var-upload: "\f093";
$fa-var-hurricane: "\f751";
$fa-var-mound: "\e52d";
$fa-var-toilet-portable: "\e583";
$fa-var-compact-disc: "\f51f";
$fa-var-file-arrow-down: "\f56d";
$fa-var-file-download: "\f56d";
$fa-var-caravan: "\f8ff";
$fa-var-shield-cat: "\e572";
$fa-var-bolt: "\f0e7";
$fa-var-zap: "\f0e7";
$fa-var-glass-water: "\e4f4";
$fa-var-oil-well: "\e532";
$fa-var-vault: "\e2c5";
$fa-var-mars: "\f222";
$fa-var-toilet: "\f7d8";
$fa-var-plane-circle-xmark: "\e557";
$fa-var-yen-sign: "\f157";
$fa-var-cny: "\f157";
$fa-var-jpy: "\f157";
$fa-var-rmb: "\f157";
$fa-var-yen: "\f157";
$fa-var-ruble-sign: "\f158";
$fa-var-rouble: "\f158";
$fa-var-rub: "\f158";
$fa-var-ruble: "\f158";
$fa-var-sun: "\f185";
$fa-var-guitar: "\f7a6";
$fa-var-face-laugh-wink: "\f59c";
$fa-var-laugh-wink: "\f59c";
$fa-var-horse-head: "\f7ab";
$fa-var-bore-hole: "\e4c3";
$fa-var-industry: "\f275";
$fa-var-circle-down: "\f358";
$fa-var-arrow-alt-circle-down: "\f358";
$fa-var-arrows-turn-to-dots: "\e4c1";
$fa-var-florin-sign: "\e184";
$fa-var-arrow-down-short-wide: "\f884";
$fa-var-sort-amount-desc: "\f884";
$fa-var-sort-amount-down-alt: "\f884";
$fa-var-less-than: "\3c";
$fa-var-angle-down: "\f107";
$fa-var-car-tunnel: "\e4de";
$fa-var-head-side-cough: "\e061";
$fa-var-grip-lines: "\f7a4";
$fa-var-thumbs-down: "\f165";
$fa-var-user-lock: "\f502";
$fa-var-arrow-right-long: "\f178";
$fa-var-long-arrow-right: "\f178";
$fa-var-anchor-circle-xmark: "\e4ac";
$fa-var-ellipsis: "\f141";
$fa-var-ellipsis-h: "\f141";
$fa-var-chess-pawn: "\f443";
$fa-var-kit-medical: "\f479";
$fa-var-first-aid: "\f479";
$fa-var-person-through-window: "\e5a9";
$fa-var-toolbox: "\f552";
$fa-var-hands-holding-circle: "\e4fb";
$fa-var-bug: "\f188";
$fa-var-credit-card: "\f09d";
$fa-var-credit-card-alt: "\f09d";
$fa-var-car: "\f1b9";
$fa-var-automobile: "\f1b9";
$fa-var-hand-holding-hand: "\e4f7";
$fa-var-book-open-reader: "\f5da";
$fa-var-book-reader: "\f5da";
$fa-var-mountain-sun: "\e52f";
$fa-var-arrows-left-right-to-line: "\e4ba";
$fa-var-dice-d20: "\f6cf";
$fa-var-truck-droplet: "\e58c";
$fa-var-file-circle-xmark: "\e5a1";
$fa-var-temperature-arrow-up: "\e040";
$fa-var-temperature-up: "\e040";
$fa-var-medal: "\f5a2";
$fa-var-bed: "\f236";
$fa-var-square-h: "\f0fd";
$fa-var-h-square: "\f0fd";
$fa-var-podcast: "\f2ce";
$fa-var-temperature-full: "\f2c7";
$fa-var-temperature-4: "\f2c7";
$fa-var-thermometer-4: "\f2c7";
$fa-var-thermometer-full: "\f2c7";
$fa-var-bell: "\f0f3";
$fa-var-superscript: "\f12b";
$fa-var-plug-circle-xmark: "\e560";
$fa-var-star-of-life: "\f621";
$fa-var-phone-slash: "\f3dd";
$fa-var-paint-roller: "\f5aa";
$fa-var-handshake-angle: "\f4c4";
$fa-var-hands-helping: "\f4c4";
$fa-var-location-dot: "\f3c5";
$fa-var-map-marker-alt: "\f3c5";
$fa-var-file: "\f15b";
$fa-var-greater-than: "\3e";
$fa-var-person-swimming: "\f5c4";
$fa-var-swimmer: "\f5c4";
$fa-var-arrow-down: "\f063";
$fa-var-droplet: "\f043";
$fa-var-tint: "\f043";
$fa-var-eraser: "\f12d";
$fa-var-earth-americas: "\f57d";
$fa-var-earth: "\f57d";
$fa-var-earth-america: "\f57d";
$fa-var-globe-americas: "\f57d";
$fa-var-person-burst: "\e53b";
$fa-var-dove: "\f4ba";
$fa-var-battery-empty: "\f244";
$fa-var-battery-0: "\f244";
$fa-var-socks: "\f696";
$fa-var-inbox: "\f01c";
$fa-var-section: "\e447";
$fa-var-gauge-high: "\f625";
$fa-var-tachometer-alt: "\f625";
$fa-var-tachometer-alt-fast: "\f625";
$fa-var-envelope-open-text: "\f658";
$fa-var-hospital: "\f0f8";
$fa-var-hospital-alt: "\f0f8";
$fa-var-hospital-wide: "\f0f8";
$fa-var-wine-bottle: "\f72f";
$fa-var-chess-rook: "\f447";
$fa-var-bars-staggered: "\f550";
$fa-var-reorder: "\f550";
$fa-var-stream: "\f550";
$fa-var-dharmachakra: "\f655";
$fa-var-hotdog: "\f80f";
$fa-var-person-walking-with-cane: "\f29d";
$fa-var-blind: "\f29d";
$fa-var-drum: "\f569";
$fa-var-ice-cream: "\f810";
$fa-var-heart-circle-bolt: "\e4fc";
$fa-var-fax: "\f1ac";
$fa-var-paragraph: "\f1dd";
$fa-var-check-to-slot: "\f772";
$fa-var-vote-yea: "\f772";
$fa-var-star-half: "\f089";
$fa-var-boxes-stacked: "\f468";
$fa-var-boxes: "\f468";
$fa-var-boxes-alt: "\f468";
$fa-var-link: "\f0c1";
$fa-var-chain: "\f0c1";
$fa-var-ear-listen: "\f2a2";
$fa-var-assistive-listening-systems: "\f2a2";
$fa-var-tree-city: "\e587";
$fa-var-play: "\f04b";
$fa-var-font: "\f031";
$fa-var-rupiah-sign: "\e23d";
$fa-var-magnifying-glass: "\f002";
$fa-var-search: "\f002";
$fa-var-table-tennis-paddle-ball: "\f45d";
$fa-var-ping-pong-paddle-ball: "\f45d";
$fa-var-table-tennis: "\f45d";
$fa-var-person-dots-from-line: "\f470";
$fa-var-diagnoses: "\f470";
$fa-var-trash-can-arrow-up: "\f82a";
$fa-var-trash-restore-alt: "\f82a";
$fa-var-naira-sign: "\e1f6";
$fa-var-cart-arrow-down: "\f218";
$fa-var-walkie-talkie: "\f8ef";
$fa-var-file-pen: "\f31c";
$fa-var-file-edit: "\f31c";
$fa-var-receipt: "\f543";
$fa-var-square-pen: "\f14b";
$fa-var-pen-square: "\f14b";
$fa-var-pencil-square: "\f14b";
$fa-var-suitcase-rolling: "\f5c1";
$fa-var-person-circle-exclamation: "\e53f";
$fa-var-chevron-down: "\f078";
$fa-var-battery-full: "\f240";
$fa-var-battery: "\f240";
$fa-var-battery-5: "\f240";
$fa-var-skull-crossbones: "\f714";
$fa-var-code-compare: "\e13a";
$fa-var-list-ul: "\f0ca";
$fa-var-list-dots: "\f0ca";
$fa-var-school-lock: "\e56f";
$fa-var-tower-cell: "\e585";
$fa-var-down-long: "\f309";
$fa-var-long-arrow-alt-down: "\f309";
$fa-var-ranking-star: "\e561";
$fa-var-chess-king: "\f43f";
$fa-var-person-harassing: "\e549";
$fa-var-brazilian-real-sign: "\e46c";
$fa-var-landmark-dome: "\f752";
$fa-var-landmark-alt: "\f752";
$fa-var-arrow-up: "\f062";
$fa-var-tv: "\f26c";
$fa-var-television: "\f26c";
$fa-var-tv-alt: "\f26c";
$fa-var-shrimp: "\e448";
$fa-var-list-check: "\f0ae";
$fa-var-tasks: "\f0ae";
$fa-var-jug-detergent: "\e519";
$fa-var-circle-user: "\f2bd";
$fa-var-user-circle: "\f2bd";
$fa-var-user-shield: "\f505";
$fa-var-wind: "\f72e";
$fa-var-car-burst: "\f5e1";
$fa-var-car-crash: "\f5e1";
$fa-var-y: "\59";
$fa-var-person-snowboarding: "\f7ce";
$fa-var-snowboarding: "\f7ce";
$fa-var-truck-fast: "\f48b";
$fa-var-shipping-fast: "\f48b";
$fa-var-fish: "\f578";
$fa-var-user-graduate: "\f501";
$fa-var-circle-half-stroke: "\f042";
$fa-var-adjust: "\f042";
$fa-var-clapperboard: "\e131";
$fa-var-circle-radiation: "\f7ba";
$fa-var-radiation-alt: "\f7ba";
$fa-var-baseball: "\f433";
$fa-var-baseball-ball: "\f433";
$fa-var-jet-fighter-up: "\e518";
$fa-var-diagram-project: "\f542";
$fa-var-project-diagram: "\f542";
$fa-var-copy: "\f0c5";
$fa-var-volume-xmark: "\f6a9";
$fa-var-volume-mute: "\f6a9";
$fa-var-volume-times: "\f6a9";
$fa-var-hand-sparkles: "\e05d";
$fa-var-grip: "\f58d";
$fa-var-grip-horizontal: "\f58d";
$fa-var-share-from-square: "\f14d";
$fa-var-share-square: "\f14d";
$fa-var-child-combatant: "\e4e0";
$fa-var-child-rifle: "\e4e0";
$fa-var-gun: "\e19b";
$fa-var-square-phone: "\f098";
$fa-var-phone-square: "\f098";
$fa-var-plus: "\2b";
$fa-var-add: "\2b";
$fa-var-expand: "\f065";
$fa-var-computer: "\e4e5";
$fa-var-xmark: "\f00d";
$fa-var-close: "\f00d";
$fa-var-multiply: "\f00d";
$fa-var-remove: "\f00d";
$fa-var-times: "\f00d";
$fa-var-arrows-up-down-left-right: "\f047";
$fa-var-arrows: "\f047";
$fa-var-chalkboard-user: "\f51c";
$fa-var-chalkboard-teacher: "\f51c";
$fa-var-peso-sign: "\e222";
$fa-var-building-shield: "\e4d8";
$fa-var-baby: "\f77c";
$fa-var-users-line: "\e592";
$fa-var-quote-left: "\f10d";
$fa-var-quote-left-alt: "\f10d";
$fa-var-tractor: "\f722";
$fa-var-trash-arrow-up: "\f829";
$fa-var-trash-restore: "\f829";
$fa-var-arrow-down-up-lock: "\e4b0";
$fa-var-lines-leaning: "\e51e";
$fa-var-ruler-combined: "\f546";
$fa-var-copyright: "\f1f9";
$fa-var-equals: "\3d";
$fa-var-blender: "\f517";
$fa-var-teeth: "\f62e";
$fa-var-shekel-sign: "\f20b";
$fa-var-ils: "\f20b";
$fa-var-shekel: "\f20b";
$fa-var-sheqel: "\f20b";
$fa-var-sheqel-sign: "\f20b";
$fa-var-map: "\f279";
$fa-var-rocket: "\f135";
$fa-var-photo-film: "\f87c";
$fa-var-photo-video: "\f87c";
$fa-var-folder-minus: "\f65d";
$fa-var-store: "\f54e";
$fa-var-arrow-trend-up: "\e098";
$fa-var-plug-circle-minus: "\e55e";
$fa-var-sign-hanging: "\f4d9";
$fa-var-sign: "\f4d9";
$fa-var-bezier-curve: "\f55b";
$fa-var-bell-slash: "\f1f6";
$fa-var-tablet: "\f3fb";
$fa-var-tablet-android: "\f3fb";
$fa-var-school-flag: "\e56e";
$fa-var-fill: "\f575";
$fa-var-angle-up: "\f106";
$fa-var-drumstick-bite: "\f6d7";
$fa-var-holly-berry: "\f7aa";
$fa-var-chevron-left: "\f053";
$fa-var-bacteria: "\e059";
$fa-var-hand-lizard: "\f258";
$fa-var-notdef: "\e1fe";
$fa-var-disease: "\f7fa";
$fa-var-briefcase-medical: "\f469";
$fa-var-genderless: "\f22d";
$fa-var-chevron-right: "\f054";
$fa-var-retweet: "\f079";
$fa-var-car-rear: "\f5de";
$fa-var-car-alt: "\f5de";
$fa-var-pump-soap: "\e06b";
$fa-var-video-slash: "\f4e2";
$fa-var-battery-quarter: "\f243";
$fa-var-battery-2: "\f243";
$fa-var-radio: "\f8d7";
$fa-var-baby-carriage: "\f77d";
$fa-var-carriage-baby: "\f77d";
$fa-var-traffic-light: "\f637";
$fa-var-thermometer: "\f491";
$fa-var-vr-cardboard: "\f729";
$fa-var-hand-middle-finger: "\f806";
$fa-var-percent: "\25";
$fa-var-percentage: "\25";
$fa-var-truck-moving: "\f4df";
$fa-var-glass-water-droplet: "\e4f5";
$fa-var-display: "\e163";
$fa-var-face-smile: "\f118";
$fa-var-smile: "\f118";
$fa-var-thumbtack: "\f08d";
$fa-var-thumb-tack: "\f08d";
$fa-var-trophy: "\f091";
$fa-var-person-praying: "\f683";
$fa-var-pray: "\f683";
$fa-var-hammer: "\f6e3";
$fa-var-hand-peace: "\f25b";
$fa-var-rotate: "\f2f1";
$fa-var-sync-alt: "\f2f1";
$fa-var-spinner: "\f110";
$fa-var-robot: "\f544";
$fa-var-peace: "\f67c";
$fa-var-gears: "\f085";
$fa-var-cogs: "\f085";
$fa-var-warehouse: "\f494";
$fa-var-arrow-up-right-dots: "\e4b7";
$fa-var-splotch: "\f5bc";
$fa-var-face-grin-hearts: "\f584";
$fa-var-grin-hearts: "\f584";
$fa-var-dice-four: "\f524";
$fa-var-sim-card: "\f7c4";
$fa-var-transgender: "\f225";
$fa-var-transgender-alt: "\f225";
$fa-var-mercury: "\f223";
$fa-var-arrow-turn-down: "\f149";
$fa-var-level-down: "\f149";
$fa-var-person-falling-burst: "\e547";
$fa-var-award: "\f559";
$fa-var-ticket-simple: "\f3ff";
$fa-var-ticket-alt: "\f3ff";
$fa-var-building: "\f1ad";
$fa-var-angles-left: "\f100";
$fa-var-angle-double-left: "\f100";
$fa-var-qrcode: "\f029";
$fa-var-clock-rotate-left: "\f1da";
$fa-var-history: "\f1da";
$fa-var-face-grin-beam-sweat: "\f583";
$fa-var-grin-beam-sweat: "\f583";
$fa-var-file-export: "\f56e";
$fa-var-arrow-right-from-file: "\f56e";
$fa-var-shield: "\f132";
$fa-var-shield-blank: "\f132";
$fa-var-arrow-up-short-wide: "\f885";
$fa-var-sort-amount-up-alt: "\f885";
$fa-var-house-medical: "\e3b2";
$fa-var-golf-ball-tee: "\f450";
$fa-var-golf-ball: "\f450";
$fa-var-circle-chevron-left: "\f137";
$fa-var-chevron-circle-left: "\f137";
$fa-var-house-chimney-window: "\e00d";
$fa-var-pen-nib: "\f5ad";
$fa-var-tent-arrow-turn-left: "\e580";
$fa-var-tents: "\e582";
$fa-var-wand-magic: "\f0d0";
$fa-var-magic: "\f0d0";
$fa-var-dog: "\f6d3";
$fa-var-carrot: "\f787";
$fa-var-moon: "\f186";
$fa-var-wine-glass-empty: "\f5ce";
$fa-var-wine-glass-alt: "\f5ce";
$fa-var-cheese: "\f7ef";
$fa-var-yin-yang: "\f6ad";
$fa-var-music: "\f001";
$fa-var-code-commit: "\f386";
$fa-var-temperature-low: "\f76b";
$fa-var-person-biking: "\f84a";
$fa-var-biking: "\f84a";
$fa-var-broom: "\f51a";
$fa-var-shield-heart: "\e574";
$fa-var-gopuram: "\f664";
$fa-var-earth-oceania: "\e47b";
$fa-var-globe-oceania: "\e47b";
$fa-var-square-xmark: "\f2d3";
$fa-var-times-square: "\f2d3";
$fa-var-xmark-square: "\f2d3";
$fa-var-hashtag: "\23";
$fa-var-up-right-and-down-left-from-center: "\f424";
$fa-var-expand-alt: "\f424";
$fa-var-oil-can: "\f613";
$fa-var-t: "\54";
$fa-var-hippo: "\f6ed";
$fa-var-chart-column: "\e0e3";
$fa-var-infinity: "\f534";
$fa-var-vial-circle-check: "\e596";
$fa-var-person-arrow-down-to-line: "\e538";
$fa-var-voicemail: "\f897";
$fa-var-fan: "\f863";
$fa-var-person-walking-luggage: "\e554";
$fa-var-up-down: "\f338";
$fa-var-arrows-alt-v: "\f338";
$fa-var-cloud-moon-rain: "\f73c";
$fa-var-calendar: "\f133";
$fa-var-trailer: "\e041";
$fa-var-bahai: "\f666";
$fa-var-haykal: "\f666";
$fa-var-sd-card: "\f7c2";
$fa-var-dragon: "\f6d5";
$fa-var-shoe-prints: "\f54b";
$fa-var-circle-plus: "\f055";
$fa-var-plus-circle: "\f055";
$fa-var-face-grin-tongue-wink: "\f58b";
$fa-var-grin-tongue-wink: "\f58b";
$fa-var-hand-holding: "\f4bd";
$fa-var-plug-circle-exclamation: "\e55d";
$fa-var-link-slash: "\f127";
$fa-var-chain-broken: "\f127";
$fa-var-chain-slash: "\f127";
$fa-var-unlink: "\f127";
$fa-var-clone: "\f24d";
$fa-var-person-walking-arrow-loop-left: "\e551";
$fa-var-arrow-up-z-a: "\f882";
$fa-var-sort-alpha-up-alt: "\f882";
$fa-var-fire-flame-curved: "\f7e4";
$fa-var-fire-alt: "\f7e4";
$fa-var-tornado: "\f76f";
$fa-var-file-circle-plus: "\e494";
$fa-var-book-quran: "\f687";
$fa-var-quran: "\f687";
$fa-var-anchor: "\f13d";
$fa-var-border-all: "\f84c";
$fa-var-face-angry: "\f556";
$fa-var-angry: "\f556";
$fa-var-cookie-bite: "\f564";
$fa-var-arrow-trend-down: "\e097";
$fa-var-rss: "\f09e";
$fa-var-feed: "\f09e";
$fa-var-draw-polygon: "\f5ee";
$fa-var-scale-balanced: "\f24e";
$fa-var-balance-scale: "\f24e";
$fa-var-gauge-simple-high: "\f62a";
$fa-var-tachometer: "\f62a";
$fa-var-tachometer-fast: "\f62a";
$fa-var-shower: "\f2cc";
$fa-var-desktop: "\f390";
$fa-var-desktop-alt: "\f390";
$fa-var-m: "\4d";
$fa-var-table-list: "\f00b";
$fa-var-th-list: "\f00b";
$fa-var-comment-sms: "\f7cd";
$fa-var-sms: "\f7cd";
$fa-var-book: "\f02d";
$fa-var-user-plus: "\f234";
$fa-var-check: "\f00c";
$fa-var-battery-three-quarters: "\f241";
$fa-var-battery-4: "\f241";
$fa-var-house-circle-check: "\e509";
$fa-var-angle-left: "\f104";
$fa-var-diagram-successor: "\e47a";
$fa-var-truck-arrow-right: "\e58b";
$fa-var-arrows-split-up-and-left: "\e4bc";
$fa-var-hand-fist: "\f6de";
$fa-var-fist-raised: "\f6de";
$fa-var-cloud-moon: "\f6c3";
$fa-var-briefcase: "\f0b1";
$fa-var-person-falling: "\e546";
$fa-var-image-portrait: "\f3e0";
$fa-var-portrait: "\f3e0";
$fa-var-user-tag: "\f507";
$fa-var-rug: "\e569";
$fa-var-earth-europe: "\f7a2";
$fa-var-globe-europe: "\f7a2";
$fa-var-cart-flatbed-suitcase: "\f59d";
$fa-var-luggage-cart: "\f59d";
$fa-var-rectangle-xmark: "\f410";
$fa-var-rectangle-times: "\f410";
$fa-var-times-rectangle: "\f410";
$fa-var-window-close: "\f410";
$fa-var-baht-sign: "\e0ac";
$fa-var-book-open: "\f518";
$fa-var-book-journal-whills: "\f66a";
$fa-var-journal-whills: "\f66a";
$fa-var-handcuffs: "\e4f8";
$fa-var-triangle-exclamation: "\f071";
$fa-var-exclamation-triangle: "\f071";
$fa-var-warning: "\f071";
$fa-var-database: "\f1c0";
$fa-var-share: "\f064";
$fa-var-mail-forward: "\f064";
$fa-var-bottle-droplet: "\e4c4";
$fa-var-mask-face: "\e1d7";
$fa-var-hill-rockslide: "\e508";
$fa-var-right-left: "\f362";
$fa-var-exchange-alt: "\f362";
$fa-var-paper-plane: "\f1d8";
$fa-var-road-circle-exclamation: "\e565";
$fa-var-dungeon: "\f6d9";
$fa-var-align-right: "\f038";
$fa-var-money-bill-1-wave: "\f53b";
$fa-var-money-bill-wave-alt: "\f53b";
$fa-var-life-ring: "\f1cd";
$fa-var-hands: "\f2a7";
$fa-var-sign-language: "\f2a7";
$fa-var-signing: "\f2a7";
$fa-var-calendar-day: "\f783";
$fa-var-water-ladder: "\f5c5";
$fa-var-ladder-water: "\f5c5";
$fa-var-swimming-pool: "\f5c5";
$fa-var-arrows-up-down: "\f07d";
$fa-var-arrows-v: "\f07d";
$fa-var-face-grimace: "\f57f";
$fa-var-grimace: "\f57f";
$fa-var-wheelchair-move: "\e2ce";
$fa-var-wheelchair-alt: "\e2ce";
$fa-var-turn-down: "\f3be";
$fa-var-level-down-alt: "\f3be";
$fa-var-person-walking-arrow-right: "\e552";
$fa-var-square-envelope: "\f199";
$fa-var-envelope-square: "\f199";
$fa-var-dice: "\f522";
$fa-var-bowling-ball: "\f436";
$fa-var-brain: "\f5dc";
$fa-var-bandage: "\f462";
$fa-var-band-aid: "\f462";
$fa-var-calendar-minus: "\f272";
$fa-var-circle-xmark: "\f057";
$fa-var-times-circle: "\f057";
$fa-var-xmark-circle: "\f057";
$fa-var-gifts: "\f79c";
$fa-var-hotel: "\f594";
$fa-var-earth-asia: "\f57e";
$fa-var-globe-asia: "\f57e";
$fa-var-id-card-clip: "\f47f";
$fa-var-id-card-alt: "\f47f";
$fa-var-magnifying-glass-plus: "\f00e";
$fa-var-search-plus: "\f00e";
$fa-var-thumbs-up: "\f164";
$fa-var-user-clock: "\f4fd";
$fa-var-hand-dots: "\f461";
$fa-var-allergies: "\f461";
$fa-var-file-invoice: "\f570";
$fa-var-window-minimize: "\f2d1";
$fa-var-mug-saucer: "\f0f4";
$fa-var-coffee: "\f0f4";
$fa-var-brush: "\f55d";
$fa-var-mask: "\f6fa";
$fa-var-magnifying-glass-minus: "\f010";
$fa-var-search-minus: "\f010";
$fa-var-ruler-vertical: "\f548";
$fa-var-user-large: "\f406";
$fa-var-user-alt: "\f406";
$fa-var-train-tram: "\e5b4";
$fa-var-user-nurse: "\f82f";
$fa-var-syringe: "\f48e";
$fa-var-cloud-sun: "\f6c4";
$fa-var-stopwatch-20: "\e06f";
$fa-var-square-full: "\f45c";
$fa-var-magnet: "\f076";
$fa-var-jar: "\e516";
$fa-var-note-sticky: "\f249";
$fa-var-sticky-note: "\f249";
$fa-var-bug-slash: "\e490";
$fa-var-arrow-up-from-water-pump: "\e4b6";
$fa-var-bone: "\f5d7";
$fa-var-user-injured: "\f728";
$fa-var-face-sad-tear: "\f5b4";
$fa-var-sad-tear: "\f5b4";
$fa-var-plane: "\f072";
$fa-var-tent-arrows-down: "\e581";
$fa-var-exclamation: "\21";
$fa-var-arrows-spin: "\e4bb";
$fa-var-print: "\f02f";
$fa-var-turkish-lira-sign: "\e2bb";
$fa-var-try: "\e2bb";
$fa-var-turkish-lira: "\e2bb";
$fa-var-dollar-sign: "\24";
$fa-var-dollar: "\24";
$fa-var-usd: "\24";
$fa-var-x: "\58";
$fa-var-magnifying-glass-dollar: "\f688";
$fa-var-search-dollar: "\f688";
$fa-var-users-gear: "\f509";
$fa-var-users-cog: "\f509";
$fa-var-person-military-pointing: "\e54a";
$fa-var-building-columns: "\f19c";
$fa-var-bank: "\f19c";
$fa-var-institution: "\f19c";
$fa-var-museum: "\f19c";
$fa-var-university: "\f19c";
$fa-var-umbrella: "\f0e9";
$fa-var-trowel: "\e589";
$fa-var-d: "\44";
$fa-var-stapler: "\e5af";
$fa-var-masks-theater: "\f630";
$fa-var-theater-masks: "\f630";
$fa-var-kip-sign: "\e1c4";
$fa-var-hand-point-left: "\f0a5";
$fa-var-handshake-simple: "\f4c6";
$fa-var-handshake-alt: "\f4c6";
$fa-var-jet-fighter: "\f0fb";
$fa-var-fighter-jet: "\f0fb";
$fa-var-square-share-nodes: "\f1e1";
$fa-var-share-alt-square: "\f1e1";
$fa-var-barcode: "\f02a";
$fa-var-plus-minus: "\e43c";
$fa-var-video: "\f03d";
$fa-var-video-camera: "\f03d";
$fa-var-graduation-cap: "\f19d";
$fa-var-mortar-board: "\f19d";
$fa-var-hand-holding-medical: "\e05c";
$fa-var-person-circle-check: "\e53e";
$fa-var-turn-up: "\f3bf";
$fa-var-level-up-alt: "\f3bf";

$fa-var-monero: "\f3d0";
$fa-var-hooli: "\f427";
$fa-var-yelp: "\f1e9";
$fa-var-cc-visa: "\f1f0";
$fa-var-lastfm: "\f202";
$fa-var-shopware: "\f5b5";
$fa-var-creative-commons-nc: "\f4e8";
$fa-var-aws: "\f375";
$fa-var-redhat: "\f7bc";
$fa-var-yoast: "\f2b1";
$fa-var-cloudflare: "\e07d";
$fa-var-ups: "\f7e0";
$fa-var-pixiv: "\e640";
$fa-var-wpexplorer: "\f2de";
$fa-var-dyalog: "\f399";
$fa-var-bity: "\f37a";
$fa-var-stackpath: "\f842";
$fa-var-buysellads: "\f20d";
$fa-var-first-order: "\f2b0";
$fa-var-modx: "\f285";
$fa-var-guilded: "\e07e";
$fa-var-vnv: "\f40b";
$fa-var-square-js: "\f3b9";
$fa-var-js-square: "\f3b9";
$fa-var-microsoft: "\f3ca";
$fa-var-qq: "\f1d6";
$fa-var-orcid: "\f8d2";
$fa-var-java: "\f4e4";
$fa-var-invision: "\f7b0";
$fa-var-creative-commons-pd-alt: "\f4ed";
$fa-var-centercode: "\f380";
$fa-var-glide-g: "\f2a6";
$fa-var-drupal: "\f1a9";
$fa-var-hire-a-helper: "\f3b0";
$fa-var-creative-commons-by: "\f4e7";
$fa-var-unity: "\e049";
$fa-var-whmcs: "\f40d";
$fa-var-rocketchat: "\f3e8";
$fa-var-vk: "\f189";
$fa-var-untappd: "\f405";
$fa-var-mailchimp: "\f59e";
$fa-var-css3-alt: "\f38b";
$fa-var-square-reddit: "\f1a2";
$fa-var-reddit-square: "\f1a2";
$fa-var-vimeo-v: "\f27d";
$fa-var-contao: "\f26d";
$fa-var-square-font-awesome: "\e5ad";
$fa-var-deskpro: "\f38f";
$fa-var-brave: "\e63c";
$fa-var-sistrix: "\f3ee";
$fa-var-square-instagram: "\e055";
$fa-var-instagram-square: "\e055";
$fa-var-battle-net: "\f835";
$fa-var-the-red-yeti: "\f69d";
$fa-var-square-hacker-news: "\f3af";
$fa-var-hacker-news-square: "\f3af";
$fa-var-edge: "\f282";
$fa-var-threads: "\e618";
$fa-var-napster: "\f3d2";
$fa-var-square-snapchat: "\f2ad";
$fa-var-snapchat-square: "\f2ad";
$fa-var-google-plus-g: "\f0d5";
$fa-var-artstation: "\f77a";
$fa-var-markdown: "\f60f";
$fa-var-sourcetree: "\f7d3";
$fa-var-google-plus: "\f2b3";
$fa-var-diaspora: "\f791";
$fa-var-foursquare: "\f180";
$fa-var-stack-overflow: "\f16c";
$fa-var-github-alt: "\f113";
$fa-var-phoenix-squadron: "\f511";
$fa-var-pagelines: "\f18c";
$fa-var-algolia: "\f36c";
$fa-var-red-river: "\f3e3";
$fa-var-creative-commons-sa: "\f4ef";
$fa-var-safari: "\f267";
$fa-var-google: "\f1a0";
$fa-var-square-font-awesome-stroke: "\f35c";
$fa-var-font-awesome-alt: "\f35c";
$fa-var-atlassian: "\f77b";
$fa-var-linkedin-in: "\f0e1";
$fa-var-digital-ocean: "\f391";
$fa-var-nimblr: "\f5a8";
$fa-var-chromecast: "\f838";
$fa-var-evernote: "\f839";
$fa-var-hacker-news: "\f1d4";
$fa-var-creative-commons-sampling: "\f4f0";
$fa-var-adversal: "\f36a";
$fa-var-creative-commons: "\f25e";
$fa-var-watchman-monitoring: "\e087";
$fa-var-fonticons: "\f280";
$fa-var-weixin: "\f1d7";
$fa-var-shirtsinbulk: "\f214";
$fa-var-codepen: "\f1cb";
$fa-var-git-alt: "\f841";
$fa-var-lyft: "\f3c3";
$fa-var-rev: "\f5b2";
$fa-var-windows: "\f17a";
$fa-var-wizards-of-the-coast: "\f730";
$fa-var-square-viadeo: "\f2aa";
$fa-var-viadeo-square: "\f2aa";
$fa-var-meetup: "\f2e0";
$fa-var-centos: "\f789";
$fa-var-adn: "\f170";
$fa-var-cloudsmith: "\f384";
$fa-var-opensuse: "\e62b";
$fa-var-pied-piper-alt: "\f1a8";
$fa-var-square-dribbble: "\f397";
$fa-var-dribbble-square: "\f397";
$fa-var-codiepie: "\f284";
$fa-var-node: "\f419";
$fa-var-mix: "\f3cb";
$fa-var-steam: "\f1b6";
$fa-var-cc-apple-pay: "\f416";
$fa-var-scribd: "\f28a";
$fa-var-debian: "\e60b";
$fa-var-openid: "\f19b";
$fa-var-instalod: "\e081";
$fa-var-expeditedssl: "\f23e";
$fa-var-sellcast: "\f2da";
$fa-var-square-twitter: "\f081";
$fa-var-twitter-square: "\f081";
$fa-var-r-project: "\f4f7";
$fa-var-delicious: "\f1a5";
$fa-var-freebsd: "\f3a4";
$fa-var-vuejs: "\f41f";
$fa-var-accusoft: "\f369";
$fa-var-ioxhost: "\f208";
$fa-var-fonticons-fi: "\f3a2";
$fa-var-app-store: "\f36f";
$fa-var-cc-mastercard: "\f1f1";
$fa-var-itunes-note: "\f3b5";
$fa-var-golang: "\e40f";
$fa-var-kickstarter: "\f3bb";
$fa-var-grav: "\f2d6";
$fa-var-weibo: "\f18a";
$fa-var-uncharted: "\e084";
$fa-var-firstdraft: "\f3a1";
$fa-var-square-youtube: "\f431";
$fa-var-youtube-square: "\f431";
$fa-var-wikipedia-w: "\f266";
$fa-var-wpressr: "\f3e4";
$fa-var-rendact: "\f3e4";
$fa-var-angellist: "\f209";
$fa-var-galactic-republic: "\f50c";
$fa-var-nfc-directional: "\e530";
$fa-var-skype: "\f17e";
$fa-var-joget: "\f3b7";
$fa-var-fedora: "\f798";
$fa-var-stripe-s: "\f42a";
$fa-var-meta: "\e49b";
$fa-var-laravel: "\f3bd";
$fa-var-hotjar: "\f3b1";
$fa-var-bluetooth-b: "\f294";
$fa-var-square-letterboxd: "\e62e";
$fa-var-sticker-mule: "\f3f7";
$fa-var-creative-commons-zero: "\f4f3";
$fa-var-hips: "\f452";
$fa-var-behance: "\f1b4";
$fa-var-reddit: "\f1a1";
$fa-var-discord: "\f392";
$fa-var-chrome: "\f268";
$fa-var-app-store-ios: "\f370";
$fa-var-cc-discover: "\f1f2";
$fa-var-wpbeginner: "\f297";
$fa-var-confluence: "\f78d";
$fa-var-shoelace: "\e60c";
$fa-var-mdb: "\f8ca";
$fa-var-dochub: "\f394";
$fa-var-accessible-icon: "\f368";
$fa-var-ebay: "\f4f4";
$fa-var-amazon: "\f270";
$fa-var-unsplash: "\e07c";
$fa-var-yarn: "\f7e3";
$fa-var-square-steam: "\f1b7";
$fa-var-steam-square: "\f1b7";
$fa-var-500px: "\f26e";
$fa-var-square-vimeo: "\f194";
$fa-var-vimeo-square: "\f194";
$fa-var-asymmetrik: "\f372";
$fa-var-font-awesome: "\f2b4";
$fa-var-font-awesome-flag: "\f2b4";
$fa-var-font-awesome-logo-full: "\f2b4";
$fa-var-gratipay: "\f184";
$fa-var-apple: "\f179";
$fa-var-hive: "\e07f";
$fa-var-gitkraken: "\f3a6";
$fa-var-keybase: "\f4f5";
$fa-var-apple-pay: "\f415";
$fa-var-padlet: "\e4a0";
$fa-var-amazon-pay: "\f42c";
$fa-var-square-github: "\f092";
$fa-var-github-square: "\f092";
$fa-var-stumbleupon: "\f1a4";
$fa-var-fedex: "\f797";
$fa-var-phoenix-framework: "\f3dc";
$fa-var-shopify: "\e057";
$fa-var-neos: "\f612";
$fa-var-square-threads: "\e619";
$fa-var-hackerrank: "\f5f7";
$fa-var-researchgate: "\f4f8";
$fa-var-swift: "\f8e1";
$fa-var-angular: "\f420";
$fa-var-speakap: "\f3f3";
$fa-var-angrycreative: "\f36e";
$fa-var-y-combinator: "\f23b";
$fa-var-empire: "\f1d1";
$fa-var-envira: "\f299";
$fa-var-google-scholar: "\e63b";
$fa-var-square-gitlab: "\e5ae";
$fa-var-gitlab-square: "\e5ae";
$fa-var-studiovinari: "\f3f8";
$fa-var-pied-piper: "\f2ae";
$fa-var-wordpress: "\f19a";
$fa-var-product-hunt: "\f288";
$fa-var-firefox: "\f269";
$fa-var-linode: "\f2b8";
$fa-var-goodreads: "\f3a8";
$fa-var-square-odnoklassniki: "\f264";
$fa-var-odnoklassniki-square: "\f264";
$fa-var-jsfiddle: "\f1cc";
$fa-var-sith: "\f512";
$fa-var-themeisle: "\f2b2";
$fa-var-page4: "\f3d7";
$fa-var-hashnode: "\e499";
$fa-var-react: "\f41b";
$fa-var-cc-paypal: "\f1f4";
$fa-var-squarespace: "\f5be";
$fa-var-cc-stripe: "\f1f5";
$fa-var-creative-commons-share: "\f4f2";
$fa-var-bitcoin: "\f379";
$fa-var-keycdn: "\f3ba";
$fa-var-opera: "\f26a";
$fa-var-itch-io: "\f83a";
$fa-var-umbraco: "\f8e8";
$fa-var-galactic-senate: "\f50d";
$fa-var-ubuntu: "\f7df";
$fa-var-draft2digital: "\f396";
$fa-var-stripe: "\f429";
$fa-var-houzz: "\f27c";
$fa-var-gg: "\f260";
$fa-var-dhl: "\f790";
$fa-var-square-pinterest: "\f0d3";
$fa-var-pinterest-square: "\f0d3";
$fa-var-xing: "\f168";
$fa-var-blackberry: "\f37b";
$fa-var-creative-commons-pd: "\f4ec";
$fa-var-playstation: "\f3df";
$fa-var-quinscape: "\f459";
$fa-var-less: "\f41d";
$fa-var-blogger-b: "\f37d";
$fa-var-opencart: "\f23d";
$fa-var-vine: "\f1ca";
$fa-var-signal-messenger: "\e663";
$fa-var-paypal: "\f1ed";
$fa-var-gitlab: "\f296";
$fa-var-typo3: "\f42b";
$fa-var-reddit-alien: "\f281";
$fa-var-yahoo: "\f19e";
$fa-var-dailymotion: "\e052";
$fa-var-affiliatetheme: "\f36b";
$fa-var-pied-piper-pp: "\f1a7";
$fa-var-bootstrap: "\f836";
$fa-var-odnoklassniki: "\f263";
$fa-var-nfc-symbol: "\e531";
$fa-var-mintbit: "\e62f";
$fa-var-ethereum: "\f42e";
$fa-var-speaker-deck: "\f83c";
$fa-var-creative-commons-nc-eu: "\f4e9";
$fa-var-patreon: "\f3d9";
$fa-var-avianex: "\f374";
$fa-var-ello: "\f5f1";
$fa-var-gofore: "\f3a7";
$fa-var-bimobject: "\f378";
$fa-var-brave-reverse: "\e63d";
$fa-var-facebook-f: "\f39e";
$fa-var-square-google-plus: "\f0d4";
$fa-var-google-plus-square: "\f0d4";
$fa-var-mandalorian: "\f50f";
$fa-var-first-order-alt: "\f50a";
$fa-var-osi: "\f41a";
$fa-var-google-wallet: "\f1ee";
$fa-var-d-and-d-beyond: "\f6ca";
$fa-var-periscope: "\f3da";
$fa-var-fulcrum: "\f50b";
$fa-var-cloudscale: "\f383";
$fa-var-forumbee: "\f211";
$fa-var-mizuni: "\f3cc";
$fa-var-schlix: "\f3ea";
$fa-var-square-xing: "\f169";
$fa-var-xing-square: "\f169";
$fa-var-bandcamp: "\f2d5";
$fa-var-wpforms: "\f298";
$fa-var-cloudversify: "\f385";
$fa-var-usps: "\f7e1";
$fa-var-megaport: "\f5a3";
$fa-var-magento: "\f3c4";
$fa-var-spotify: "\f1bc";
$fa-var-optin-monster: "\f23c";
$fa-var-fly: "\f417";
$fa-var-aviato: "\f421";
$fa-var-itunes: "\f3b4";
$fa-var-cuttlefish: "\f38c";
$fa-var-blogger: "\f37c";
$fa-var-flickr: "\f16e";
$fa-var-viber: "\f409";
$fa-var-soundcloud: "\f1be";
$fa-var-digg: "\f1a6";
$fa-var-tencent-weibo: "\f1d5";
$fa-var-letterboxd: "\e62d";
$fa-var-symfony: "\f83d";
$fa-var-maxcdn: "\f136";
$fa-var-etsy: "\f2d7";
$fa-var-facebook-messenger: "\f39f";
$fa-var-audible: "\f373";
$fa-var-think-peaks: "\f731";
$fa-var-bilibili: "\e3d9";
$fa-var-erlang: "\f39d";
$fa-var-x-twitter: "\e61b";
$fa-var-cotton-bureau: "\f89e";
$fa-var-dashcube: "\f210";
$fa-var-42-group: "\e080";
$fa-var-innosoft: "\e080";
$fa-var-stack-exchange: "\f18d";
$fa-var-elementor: "\f430";
$fa-var-square-pied-piper: "\e01e";
$fa-var-pied-piper-square: "\e01e";
$fa-var-creative-commons-nd: "\f4eb";
$fa-var-palfed: "\f3d8";
$fa-var-superpowers: "\f2dd";
$fa-var-resolving: "\f3e7";
$fa-var-xbox: "\f412";
$fa-var-searchengin: "\f3eb";
$fa-var-tiktok: "\e07b";
$fa-var-square-facebook: "\f082";
$fa-var-facebook-square: "\f082";
$fa-var-renren: "\f18b";
$fa-var-linux: "\f17c";
$fa-var-glide: "\f2a5";
$fa-var-linkedin: "\f08c";
$fa-var-hubspot: "\f3b2";
$fa-var-deploydog: "\f38e";
$fa-var-twitch: "\f1e8";
$fa-var-ravelry: "\f2d9";
$fa-var-mixer: "\e056";
$fa-var-square-lastfm: "\f203";
$fa-var-lastfm-square: "\f203";
$fa-var-vimeo: "\f40a";
$fa-var-mendeley: "\f7b3";
$fa-var-uniregistry: "\f404";
$fa-var-figma: "\f799";
$fa-var-creative-commons-remix: "\f4ee";
$fa-var-cc-amazon-pay: "\f42d";
$fa-var-dropbox: "\f16b";
$fa-var-instagram: "\f16d";
$fa-var-cmplid: "\e360";
$fa-var-upwork: "\e641";
$fa-var-facebook: "\f09a";
$fa-var-gripfire: "\f3ac";
$fa-var-jedi-order: "\f50e";
$fa-var-uikit: "\f403";
$fa-var-fort-awesome-alt: "\f3a3";
$fa-var-phabricator: "\f3db";
$fa-var-ussunnah: "\f407";
$fa-var-earlybirds: "\f39a";
$fa-var-trade-federation: "\f513";
$fa-var-autoprefixer: "\f41c";
$fa-var-whatsapp: "\f232";
$fa-var-slideshare: "\f1e7";
$fa-var-google-play: "\f3ab";
$fa-var-viadeo: "\f2a9";
$fa-var-line: "\f3c0";
$fa-var-google-drive: "\f3aa";
$fa-var-servicestack: "\f3ec";
$fa-var-simplybuilt: "\f215";
$fa-var-bitbucket: "\f171";
$fa-var-imdb: "\f2d8";
$fa-var-deezer: "\e077";
$fa-var-raspberry-pi: "\f7bb";
$fa-var-jira: "\f7b1";
$fa-var-docker: "\f395";
$fa-var-screenpal: "\e570";
$fa-var-bluetooth: "\f293";
$fa-var-gitter: "\f426";
$fa-var-d-and-d: "\f38d";
$fa-var-microblog: "\e01a";
$fa-var-cc-diners-club: "\f24c";
$fa-var-gg-circle: "\f261";
$fa-var-pied-piper-hat: "\f4e5";
$fa-var-kickstarter-k: "\f3bc";
$fa-var-yandex: "\f413";
$fa-var-readme: "\f4d5";
$fa-var-html5: "\f13b";
$fa-var-sellsy: "\f213";
$fa-var-sass: "\f41e";
$fa-var-wirsindhandwerk: "\e2d0";
$fa-var-wsh: "\e2d0";
$fa-var-buromobelexperte: "\f37f";
$fa-var-salesforce: "\f83b";
$fa-var-octopus-deploy: "\e082";
$fa-var-medapps: "\f3c6";
$fa-var-ns8: "\f3d5";
$fa-var-pinterest-p: "\f231";
$fa-var-apper: "\f371";
$fa-var-fort-awesome: "\f286";
$fa-var-waze: "\f83f";
$fa-var-cc-jcb: "\f24b";
$fa-var-snapchat: "\f2ab";
$fa-var-snapchat-ghost: "\f2ab";
$fa-var-fantasy-flight-games: "\f6dc";
$fa-var-rust: "\e07a";
$fa-var-wix: "\f5cf";
$fa-var-square-behance: "\f1b5";
$fa-var-behance-square: "\f1b5";
$fa-var-supple: "\f3f9";
$fa-var-webflow: "\e65c";
$fa-var-rebel: "\f1d0";
$fa-var-css3: "\f13c";
$fa-var-staylinked: "\f3f5";
$fa-var-kaggle: "\f5fa";
$fa-var-space-awesome: "\e5ac";
$fa-var-deviantart: "\f1bd";
$fa-var-cpanel: "\f388";
$fa-var-goodreads-g: "\f3a9";
$fa-var-square-git: "\f1d2";
$fa-var-git-square: "\f1d2";
$fa-var-square-tumblr: "\f174";
$fa-var-tumblr-square: "\f174";
$fa-var-trello: "\f181";
$fa-var-creative-commons-nc-jp: "\f4ea";
$fa-var-get-pocket: "\f265";
$fa-var-perbyte: "\e083";
$fa-var-grunt: "\f3ad";
$fa-var-weebly: "\f5cc";
$fa-var-connectdevelop: "\f20e";
$fa-var-leanpub: "\f212";
$fa-var-black-tie: "\f27e";
$fa-var-themeco: "\f5c6";
$fa-var-python: "\f3e2";
$fa-var-android: "\f17b";
$fa-var-bots: "\e340";
$fa-var-free-code-camp: "\f2c5";
$fa-var-hornbill: "\f592";
$fa-var-js: "\f3b8";
$fa-var-ideal: "\e013";
$fa-var-git: "\f1d3";
$fa-var-dev: "\f6cc";
$fa-var-sketch: "\f7c6";
$fa-var-yandex-international: "\f414";
$fa-var-cc-amex: "\f1f3";
$fa-var-uber: "\f402";
$fa-var-github: "\f09b";
$fa-var-php: "\f457";
$fa-var-alipay: "\f642";
$fa-var-youtube: "\f167";
$fa-var-skyatlas: "\f216";
$fa-var-firefox-browser: "\e007";
$fa-var-replyd: "\f3e6";
$fa-var-suse: "\f7d6";
$fa-var-jenkins: "\f3b6";
$fa-var-twitter: "\f099";
$fa-var-rockrms: "\f3e9";
$fa-var-pinterest: "\f0d2";
$fa-var-buffer: "\f837";
$fa-var-npm: "\f3d4";
$fa-var-yammer: "\f840";
$fa-var-btc: "\f15a";
$fa-var-dribbble: "\f17d";
$fa-var-stumbleupon-circle: "\f1a3";
$fa-var-internet-explorer: "\f26b";
$fa-var-stubber: "\e5c7";
$fa-var-telegram: "\f2c6";
$fa-var-telegram-plane: "\f2c6";
$fa-var-old-republic: "\f510";
$fa-var-odysee: "\e5c6";
$fa-var-square-whatsapp: "\f40c";
$fa-var-whatsapp-square: "\f40c";
$fa-var-node-js: "\f3d3";
$fa-var-edge-legacy: "\e078";
$fa-var-slack: "\f198";
$fa-var-slack-hash: "\f198";
$fa-var-medrt: "\f3c8";
$fa-var-usb: "\f287";
$fa-var-tumblr: "\f173";
$fa-var-vaadin: "\f408";
$fa-var-quora: "\f2c4";
$fa-var-square-x-twitter: "\e61a";
$fa-var-reacteurope: "\f75d";
$fa-var-medium: "\f23a";
$fa-var-medium-m: "\f23a";
$fa-var-amilia: "\f36d";
$fa-var-mixcloud: "\f289";
$fa-var-flipboard: "\f44d";
$fa-var-viacoin: "\f237";
$fa-var-critical-role: "\f6c9";
$fa-var-sitrox: "\e44a";
$fa-var-discourse: "\f393";
$fa-var-joomla: "\f1aa";
$fa-var-mastodon: "\f4f6";
$fa-var-airbnb: "\f834";
$fa-var-wolf-pack-battalion: "\f514";
$fa-var-buy-n-large: "\f8a6";
$fa-var-gulp: "\f3ae";
$fa-var-creative-commons-sampling-plus: "\f4f1";
$fa-var-strava: "\f428";
$fa-var-ember: "\f423";
$fa-var-canadian-maple-leaf: "\f785";
$fa-var-teamspeak: "\f4f9";
$fa-var-pushed: "\f3e1";
$fa-var-wordpress-simple: "\f411";
$fa-var-nutritionix: "\f3d6";
$fa-var-wodu: "\e088";
$fa-var-google-pay: "\e079";
$fa-var-intercom: "\f7af";
$fa-var-zhihu: "\f63f";
$fa-var-korvue: "\f42f";
$fa-var-pix: "\e43a";
$fa-var-steam-symbol: "\f3f6";

$fa-icons: (
  "0": $fa-var-0,
  "1": $fa-var-1,
  "2": $fa-var-2,
  "3": $fa-var-3,
  "4": $fa-var-4,
  "5": $fa-var-5,
  "6": $fa-var-6,
  "7": $fa-var-7,
  "8": $fa-var-8,
  "9": $fa-var-9,
  "fill-drip": $fa-var-fill-drip,
  "arrows-to-circle": $fa-var-arrows-to-circle,
  "circle-chevron-right": $fa-var-circle-chevron-right,
  "chevron-circle-right": $fa-var-chevron-circle-right,
  "at": $fa-var-at,
  "trash-can": $fa-var-trash-can,
  "trash-alt": $fa-var-trash-alt,
  "text-height": $fa-var-text-height,
  "user-xmark": $fa-var-user-xmark,
  "user-times": $fa-var-user-times,
  "stethoscope": $fa-var-stethoscope,
  "message": $fa-var-message,
  "comment-alt": $fa-var-comment-alt,
  "info": $fa-var-info,
  "down-left-and-up-right-to-center": $fa-var-down-left-and-up-right-to-center,
  "compress-alt": $fa-var-compress-alt,
  "explosion": $fa-var-explosion,
  "file-lines": $fa-var-file-lines,
  "file-alt": $fa-var-file-alt,
  "file-text": $fa-var-file-text,
  "wave-square": $fa-var-wave-square,
  "ring": $fa-var-ring,
  "building-un": $fa-var-building-un,
  "dice-three": $fa-var-dice-three,
  "calendar-days": $fa-var-calendar-days,
  "calendar-alt": $fa-var-calendar-alt,
  "anchor-circle-check": $fa-var-anchor-circle-check,
  "building-circle-arrow-right": $fa-var-building-circle-arrow-right,
  "volleyball": $fa-var-volleyball,
  "volleyball-ball": $fa-var-volleyball-ball,
  "arrows-up-to-line": $fa-var-arrows-up-to-line,
  "sort-down": $fa-var-sort-down,
  "sort-desc": $fa-var-sort-desc,
  "circle-minus": $fa-var-circle-minus,
  "minus-circle": $fa-var-minus-circle,
  "door-open": $fa-var-door-open,
  "right-from-bracket": $fa-var-right-from-bracket,
  "sign-out-alt": $fa-var-sign-out-alt,
  "atom": $fa-var-atom,
  "soap": $fa-var-soap,
  "icons": $fa-var-icons,
  "heart-music-camera-bolt": $fa-var-heart-music-camera-bolt,
  "microphone-lines-slash": $fa-var-microphone-lines-slash,
  "microphone-alt-slash": $fa-var-microphone-alt-slash,
  "bridge-circle-check": $fa-var-bridge-circle-check,
  "pump-medical": $fa-var-pump-medical,
  "fingerprint": $fa-var-fingerprint,
  "hand-point-right": $fa-var-hand-point-right,
  "magnifying-glass-location": $fa-var-magnifying-glass-location,
  "search-location": $fa-var-search-location,
  "forward-step": $fa-var-forward-step,
  "step-forward": $fa-var-step-forward,
  "face-smile-beam": $fa-var-face-smile-beam,
  "smile-beam": $fa-var-smile-beam,
  "flag-checkered": $fa-var-flag-checkered,
  "football": $fa-var-football,
  "football-ball": $fa-var-football-ball,
  "school-circle-exclamation": $fa-var-school-circle-exclamation,
  "crop": $fa-var-crop,
  "angles-down": $fa-var-angles-down,
  "angle-double-down": $fa-var-angle-double-down,
  "users-rectangle": $fa-var-users-rectangle,
  "people-roof": $fa-var-people-roof,
  "people-line": $fa-var-people-line,
  "beer-mug-empty": $fa-var-beer-mug-empty,
  "beer": $fa-var-beer,
  "diagram-predecessor": $fa-var-diagram-predecessor,
  "arrow-up-long": $fa-var-arrow-up-long,
  "long-arrow-up": $fa-var-long-arrow-up,
  "fire-flame-simple": $fa-var-fire-flame-simple,
  "burn": $fa-var-burn,
  "person": $fa-var-person,
  "male": $fa-var-male,
  "laptop": $fa-var-laptop,
  "file-csv": $fa-var-file-csv,
  "menorah": $fa-var-menorah,
  "truck-plane": $fa-var-truck-plane,
  "record-vinyl": $fa-var-record-vinyl,
  "face-grin-stars": $fa-var-face-grin-stars,
  "grin-stars": $fa-var-grin-stars,
  "bong": $fa-var-bong,
  "spaghetti-monster-flying": $fa-var-spaghetti-monster-flying,
  "pastafarianism": $fa-var-pastafarianism,
  "arrow-down-up-across-line": $fa-var-arrow-down-up-across-line,
  "spoon": $fa-var-spoon,
  "utensil-spoon": $fa-var-utensil-spoon,
  "jar-wheat": $fa-var-jar-wheat,
  "envelopes-bulk": $fa-var-envelopes-bulk,
  "mail-bulk": $fa-var-mail-bulk,
  "file-circle-exclamation": $fa-var-file-circle-exclamation,
  "circle-h": $fa-var-circle-h,
  "hospital-symbol": $fa-var-hospital-symbol,
  "pager": $fa-var-pager,
  "address-book": $fa-var-address-book,
  "contact-book": $fa-var-contact-book,
  "strikethrough": $fa-var-strikethrough,
  "k": $fa-var-k,
  "landmark-flag": $fa-var-landmark-flag,
  "pencil": $fa-var-pencil,
  "pencil-alt": $fa-var-pencil-alt,
  "backward": $fa-var-backward,
  "caret-right": $fa-var-caret-right,
  "comments": $fa-var-comments,
  "paste": $fa-var-paste,
  "file-clipboard": $fa-var-file-clipboard,
  "code-pull-request": $fa-var-code-pull-request,
  "clipboard-list": $fa-var-clipboard-list,
  "truck-ramp-box": $fa-var-truck-ramp-box,
  "truck-loading": $fa-var-truck-loading,
  "user-check": $fa-var-user-check,
  "vial-virus": $fa-var-vial-virus,
  "sheet-plastic": $fa-var-sheet-plastic,
  "blog": $fa-var-blog,
  "user-ninja": $fa-var-user-ninja,
  "person-arrow-up-from-line": $fa-var-person-arrow-up-from-line,
  "scroll-torah": $fa-var-scroll-torah,
  "torah": $fa-var-torah,
  "broom-ball": $fa-var-broom-ball,
  "quidditch": $fa-var-quidditch,
  "quidditch-broom-ball": $fa-var-quidditch-broom-ball,
  "toggle-off": $fa-var-toggle-off,
  "box-archive": $fa-var-box-archive,
  "archive": $fa-var-archive,
  "person-drowning": $fa-var-person-drowning,
  "arrow-down-9-1": $fa-var-arrow-down-9-1,
  "sort-numeric-desc": $fa-var-sort-numeric-desc,
  "sort-numeric-down-alt": $fa-var-sort-numeric-down-alt,
  "face-grin-tongue-squint": $fa-var-face-grin-tongue-squint,
  "grin-tongue-squint": $fa-var-grin-tongue-squint,
  "spray-can": $fa-var-spray-can,
  "truck-monster": $fa-var-truck-monster,
  "w": $fa-var-w,
  "earth-africa": $fa-var-earth-africa,
  "globe-africa": $fa-var-globe-africa,
  "rainbow": $fa-var-rainbow,
  "circle-notch": $fa-var-circle-notch,
  "tablet-screen-button": $fa-var-tablet-screen-button,
  "tablet-alt": $fa-var-tablet-alt,
  "paw": $fa-var-paw,
  "cloud": $fa-var-cloud,
  "trowel-bricks": $fa-var-trowel-bricks,
  "face-flushed": $fa-var-face-flushed,
  "flushed": $fa-var-flushed,
  "hospital-user": $fa-var-hospital-user,
  "tent-arrow-left-right": $fa-var-tent-arrow-left-right,
  "gavel": $fa-var-gavel,
  "legal": $fa-var-legal,
  "binoculars": $fa-var-binoculars,
  "microphone-slash": $fa-var-microphone-slash,
  "box-tissue": $fa-var-box-tissue,
  "motorcycle": $fa-var-motorcycle,
  "bell-concierge": $fa-var-bell-concierge,
  "concierge-bell": $fa-var-concierge-bell,
  "pen-ruler": $fa-var-pen-ruler,
  "pencil-ruler": $fa-var-pencil-ruler,
  "people-arrows": $fa-var-people-arrows,
  "people-arrows-left-right": $fa-var-people-arrows-left-right,
  "mars-and-venus-burst": $fa-var-mars-and-venus-burst,
  "square-caret-right": $fa-var-square-caret-right,
  "caret-square-right": $fa-var-caret-square-right,
  "scissors": $fa-var-scissors,
  "cut": $fa-var-cut,
  "sun-plant-wilt": $fa-var-sun-plant-wilt,
  "toilets-portable": $fa-var-toilets-portable,
  "hockey-puck": $fa-var-hockey-puck,
  "table": $fa-var-table,
  "magnifying-glass-arrow-right": $fa-var-magnifying-glass-arrow-right,
  "tachograph-digital": $fa-var-tachograph-digital,
  "digital-tachograph": $fa-var-digital-tachograph,
  "users-slash": $fa-var-users-slash,
  "clover": $fa-var-clover,
  "reply": $fa-var-reply,
  "mail-reply": $fa-var-mail-reply,
  "star-and-crescent": $fa-var-star-and-crescent,
  "house-fire": $fa-var-house-fire,
  "square-minus": $fa-var-square-minus,
  "minus-square": $fa-var-minus-square,
  "helicopter": $fa-var-helicopter,
  "compass": $fa-var-compass,
  "square-caret-down": $fa-var-square-caret-down,
  "caret-square-down": $fa-var-caret-square-down,
  "file-circle-question": $fa-var-file-circle-question,
  "laptop-code": $fa-var-laptop-code,
  "swatchbook": $fa-var-swatchbook,
  "prescription-bottle": $fa-var-prescription-bottle,
  "bars": $fa-var-bars,
  "navicon": $fa-var-navicon,
  "people-group": $fa-var-people-group,
  "hourglass-end": $fa-var-hourglass-end,
  "hourglass-3": $fa-var-hourglass-3,
  "heart-crack": $fa-var-heart-crack,
  "heart-broken": $fa-var-heart-broken,
  "square-up-right": $fa-var-square-up-right,
  "external-link-square-alt": $fa-var-external-link-square-alt,
  "face-kiss-beam": $fa-var-face-kiss-beam,
  "kiss-beam": $fa-var-kiss-beam,
  "film": $fa-var-film,
  "ruler-horizontal": $fa-var-ruler-horizontal,
  "people-robbery": $fa-var-people-robbery,
  "lightbulb": $fa-var-lightbulb,
  "caret-left": $fa-var-caret-left,
  "circle-exclamation": $fa-var-circle-exclamation,
  "exclamation-circle": $fa-var-exclamation-circle,
  "school-circle-xmark": $fa-var-school-circle-xmark,
  "arrow-right-from-bracket": $fa-var-arrow-right-from-bracket,
  "sign-out": $fa-var-sign-out,
  "circle-chevron-down": $fa-var-circle-chevron-down,
  "chevron-circle-down": $fa-var-chevron-circle-down,
  "unlock-keyhole": $fa-var-unlock-keyhole,
  "unlock-alt": $fa-var-unlock-alt,
  "cloud-showers-heavy": $fa-var-cloud-showers-heavy,
  "headphones-simple": $fa-var-headphones-simple,
  "headphones-alt": $fa-var-headphones-alt,
  "sitemap": $fa-var-sitemap,
  "circle-dollar-to-slot": $fa-var-circle-dollar-to-slot,
  "donate": $fa-var-donate,
  "memory": $fa-var-memory,
  "road-spikes": $fa-var-road-spikes,
  "fire-burner": $fa-var-fire-burner,
  "flag": $fa-var-flag,
  "hanukiah": $fa-var-hanukiah,
  "feather": $fa-var-feather,
  "volume-low": $fa-var-volume-low,
  "volume-down": $fa-var-volume-down,
  "comment-slash": $fa-var-comment-slash,
  "cloud-sun-rain": $fa-var-cloud-sun-rain,
  "compress": $fa-var-compress,
  "wheat-awn": $fa-var-wheat-awn,
  "wheat-alt": $fa-var-wheat-alt,
  "ankh": $fa-var-ankh,
  "hands-holding-child": $fa-var-hands-holding-child,
  "asterisk": $fa-var-asterisk,
  "square-check": $fa-var-square-check,
  "check-square": $fa-var-check-square,
  "peseta-sign": $fa-var-peseta-sign,
  "heading": $fa-var-heading,
  "header": $fa-var-header,
  "ghost": $fa-var-ghost,
  "list": $fa-var-list,
  "list-squares": $fa-var-list-squares,
  "square-phone-flip": $fa-var-square-phone-flip,
  "phone-square-alt": $fa-var-phone-square-alt,
  "cart-plus": $fa-var-cart-plus,
  "gamepad": $fa-var-gamepad,
  "circle-dot": $fa-var-circle-dot,
  "dot-circle": $fa-var-dot-circle,
  "face-dizzy": $fa-var-face-dizzy,
  "dizzy": $fa-var-dizzy,
  "egg": $fa-var-egg,
  "house-medical-circle-xmark": $fa-var-house-medical-circle-xmark,
  "campground": $fa-var-campground,
  "folder-plus": $fa-var-folder-plus,
  "futbol": $fa-var-futbol,
  "futbol-ball": $fa-var-futbol-ball,
  "soccer-ball": $fa-var-soccer-ball,
  "paintbrush": $fa-var-paintbrush,
  "paint-brush": $fa-var-paint-brush,
  "lock": $fa-var-lock,
  "gas-pump": $fa-var-gas-pump,
  "hot-tub-person": $fa-var-hot-tub-person,
  "hot-tub": $fa-var-hot-tub,
  "map-location": $fa-var-map-location,
  "map-marked": $fa-var-map-marked,
  "house-flood-water": $fa-var-house-flood-water,
  "tree": $fa-var-tree,
  "bridge-lock": $fa-var-bridge-lock,
  "sack-dollar": $fa-var-sack-dollar,
  "pen-to-square": $fa-var-pen-to-square,
  "edit": $fa-var-edit,
  "car-side": $fa-var-car-side,
  "share-nodes": $fa-var-share-nodes,
  "share-alt": $fa-var-share-alt,
  "heart-circle-minus": $fa-var-heart-circle-minus,
  "hourglass-half": $fa-var-hourglass-half,
  "hourglass-2": $fa-var-hourglass-2,
  "microscope": $fa-var-microscope,
  "sink": $fa-var-sink,
  "bag-shopping": $fa-var-bag-shopping,
  "shopping-bag": $fa-var-shopping-bag,
  "arrow-down-z-a": $fa-var-arrow-down-z-a,
  "sort-alpha-desc": $fa-var-sort-alpha-desc,
  "sort-alpha-down-alt": $fa-var-sort-alpha-down-alt,
  "mitten": $fa-var-mitten,
  "person-rays": $fa-var-person-rays,
  "users": $fa-var-users,
  "eye-slash": $fa-var-eye-slash,
  "flask-vial": $fa-var-flask-vial,
  "hand": $fa-var-hand,
  "hand-paper": $fa-var-hand-paper,
  "om": $fa-var-om,
  "worm": $fa-var-worm,
  "house-circle-xmark": $fa-var-house-circle-xmark,
  "plug": $fa-var-plug,
  "chevron-up": $fa-var-chevron-up,
  "hand-spock": $fa-var-hand-spock,
  "stopwatch": $fa-var-stopwatch,
  "face-kiss": $fa-var-face-kiss,
  "kiss": $fa-var-kiss,
  "bridge-circle-xmark": $fa-var-bridge-circle-xmark,
  "face-grin-tongue": $fa-var-face-grin-tongue,
  "grin-tongue": $fa-var-grin-tongue,
  "chess-bishop": $fa-var-chess-bishop,
  "face-grin-wink": $fa-var-face-grin-wink,
  "grin-wink": $fa-var-grin-wink,
  "ear-deaf": $fa-var-ear-deaf,
  "deaf": $fa-var-deaf,
  "deafness": $fa-var-deafness,
  "hard-of-hearing": $fa-var-hard-of-hearing,
  "road-circle-check": $fa-var-road-circle-check,
  "dice-five": $fa-var-dice-five,
  "square-rss": $fa-var-square-rss,
  "rss-square": $fa-var-rss-square,
  "land-mine-on": $fa-var-land-mine-on,
  "i-cursor": $fa-var-i-cursor,
  "stamp": $fa-var-stamp,
  "stairs": $fa-var-stairs,
  "i": $fa-var-i,
  "hryvnia-sign": $fa-var-hryvnia-sign,
  "hryvnia": $fa-var-hryvnia,
  "pills": $fa-var-pills,
  "face-grin-wide": $fa-var-face-grin-wide,
  "grin-alt": $fa-var-grin-alt,
  "tooth": $fa-var-tooth,
  "v": $fa-var-v,
  "bangladeshi-taka-sign": $fa-var-bangladeshi-taka-sign,
  "bicycle": $fa-var-bicycle,
  "staff-snake": $fa-var-staff-snake,
  "rod-asclepius": $fa-var-rod-asclepius,
  "rod-snake": $fa-var-rod-snake,
  "staff-aesculapius": $fa-var-staff-aesculapius,
  "head-side-cough-slash": $fa-var-head-side-cough-slash,
  "truck-medical": $fa-var-truck-medical,
  "ambulance": $fa-var-ambulance,
  "wheat-awn-circle-exclamation": $fa-var-wheat-awn-circle-exclamation,
  "snowman": $fa-var-snowman,
  "mortar-pestle": $fa-var-mortar-pestle,
  "road-barrier": $fa-var-road-barrier,
  "school": $fa-var-school,
  "igloo": $fa-var-igloo,
  "joint": $fa-var-joint,
  "angle-right": $fa-var-angle-right,
  "horse": $fa-var-horse,
  "q": $fa-var-q,
  "g": $fa-var-g,
  "notes-medical": $fa-var-notes-medical,
  "temperature-half": $fa-var-temperature-half,
  "temperature-2": $fa-var-temperature-2,
  "thermometer-2": $fa-var-thermometer-2,
  "thermometer-half": $fa-var-thermometer-half,
  "dong-sign": $fa-var-dong-sign,
  "capsules": $fa-var-capsules,
  "poo-storm": $fa-var-poo-storm,
  "poo-bolt": $fa-var-poo-bolt,
  "face-frown-open": $fa-var-face-frown-open,
  "frown-open": $fa-var-frown-open,
  "hand-point-up": $fa-var-hand-point-up,
  "money-bill": $fa-var-money-bill,
  "bookmark": $fa-var-bookmark,
  "align-justify": $fa-var-align-justify,
  "umbrella-beach": $fa-var-umbrella-beach,
  "helmet-un": $fa-var-helmet-un,
  "bullseye": $fa-var-bullseye,
  "bacon": $fa-var-bacon,
  "hand-point-down": $fa-var-hand-point-down,
  "arrow-up-from-bracket": $fa-var-arrow-up-from-bracket,
  "folder": $fa-var-folder,
  "folder-blank": $fa-var-folder-blank,
  "file-waveform": $fa-var-file-waveform,
  "file-medical-alt": $fa-var-file-medical-alt,
  "radiation": $fa-var-radiation,
  "chart-simple": $fa-var-chart-simple,
  "mars-stroke": $fa-var-mars-stroke,
  "vial": $fa-var-vial,
  "gauge": $fa-var-gauge,
  "dashboard": $fa-var-dashboard,
  "gauge-med": $fa-var-gauge-med,
  "tachometer-alt-average": $fa-var-tachometer-alt-average,
  "wand-magic-sparkles": $fa-var-wand-magic-sparkles,
  "magic-wand-sparkles": $fa-var-magic-wand-sparkles,
  "e": $fa-var-e,
  "pen-clip": $fa-var-pen-clip,
  "pen-alt": $fa-var-pen-alt,
  "bridge-circle-exclamation": $fa-var-bridge-circle-exclamation,
  "user": $fa-var-user,
  "school-circle-check": $fa-var-school-circle-check,
  "dumpster": $fa-var-dumpster,
  "van-shuttle": $fa-var-van-shuttle,
  "shuttle-van": $fa-var-shuttle-van,
  "building-user": $fa-var-building-user,
  "square-caret-left": $fa-var-square-caret-left,
  "caret-square-left": $fa-var-caret-square-left,
  "highlighter": $fa-var-highlighter,
  "key": $fa-var-key,
  "bullhorn": $fa-var-bullhorn,
  "globe": $fa-var-globe,
  "synagogue": $fa-var-synagogue,
  "person-half-dress": $fa-var-person-half-dress,
  "road-bridge": $fa-var-road-bridge,
  "location-arrow": $fa-var-location-arrow,
  "c": $fa-var-c,
  "tablet-button": $fa-var-tablet-button,
  "building-lock": $fa-var-building-lock,
  "pizza-slice": $fa-var-pizza-slice,
  "money-bill-wave": $fa-var-money-bill-wave,
  "chart-area": $fa-var-chart-area,
  "area-chart": $fa-var-area-chart,
  "house-flag": $fa-var-house-flag,
  "person-circle-minus": $fa-var-person-circle-minus,
  "ban": $fa-var-ban,
  "cancel": $fa-var-cancel,
  "camera-rotate": $fa-var-camera-rotate,
  "spray-can-sparkles": $fa-var-spray-can-sparkles,
  "air-freshener": $fa-var-air-freshener,
  "star": $fa-var-star,
  "repeat": $fa-var-repeat,
  "cross": $fa-var-cross,
  "box": $fa-var-box,
  "venus-mars": $fa-var-venus-mars,
  "arrow-pointer": $fa-var-arrow-pointer,
  "mouse-pointer": $fa-var-mouse-pointer,
  "maximize": $fa-var-maximize,
  "expand-arrows-alt": $fa-var-expand-arrows-alt,
  "charging-station": $fa-var-charging-station,
  "shapes": $fa-var-shapes,
  "triangle-circle-square": $fa-var-triangle-circle-square,
  "shuffle": $fa-var-shuffle,
  "random": $fa-var-random,
  "person-running": $fa-var-person-running,
  "running": $fa-var-running,
  "mobile-retro": $fa-var-mobile-retro,
  "grip-lines-vertical": $fa-var-grip-lines-vertical,
  "spider": $fa-var-spider,
  "hands-bound": $fa-var-hands-bound,
  "file-invoice-dollar": $fa-var-file-invoice-dollar,
  "plane-circle-exclamation": $fa-var-plane-circle-exclamation,
  "x-ray": $fa-var-x-ray,
  "spell-check": $fa-var-spell-check,
  "slash": $fa-var-slash,
  "computer-mouse": $fa-var-computer-mouse,
  "mouse": $fa-var-mouse,
  "arrow-right-to-bracket": $fa-var-arrow-right-to-bracket,
  "sign-in": $fa-var-sign-in,
  "shop-slash": $fa-var-shop-slash,
  "store-alt-slash": $fa-var-store-alt-slash,
  "server": $fa-var-server,
  "virus-covid-slash": $fa-var-virus-covid-slash,
  "shop-lock": $fa-var-shop-lock,
  "hourglass-start": $fa-var-hourglass-start,
  "hourglass-1": $fa-var-hourglass-1,
  "blender-phone": $fa-var-blender-phone,
  "building-wheat": $fa-var-building-wheat,
  "person-breastfeeding": $fa-var-person-breastfeeding,
  "right-to-bracket": $fa-var-right-to-bracket,
  "sign-in-alt": $fa-var-sign-in-alt,
  "venus": $fa-var-venus,
  "passport": $fa-var-passport,
  "heart-pulse": $fa-var-heart-pulse,
  "heartbeat": $fa-var-heartbeat,
  "people-carry-box": $fa-var-people-carry-box,
  "people-carry": $fa-var-people-carry,
  "temperature-high": $fa-var-temperature-high,
  "microchip": $fa-var-microchip,
  "crown": $fa-var-crown,
  "weight-hanging": $fa-var-weight-hanging,
  "xmarks-lines": $fa-var-xmarks-lines,
  "file-prescription": $fa-var-file-prescription,
  "weight-scale": $fa-var-weight-scale,
  "weight": $fa-var-weight,
  "user-group": $fa-var-user-group,
  "user-friends": $fa-var-user-friends,
  "arrow-up-a-z": $fa-var-arrow-up-a-z,
  "sort-alpha-up": $fa-var-sort-alpha-up,
  "chess-knight": $fa-var-chess-knight,
  "face-laugh-squint": $fa-var-face-laugh-squint,
  "laugh-squint": $fa-var-laugh-squint,
  "wheelchair": $fa-var-wheelchair,
  "circle-arrow-up": $fa-var-circle-arrow-up,
  "arrow-circle-up": $fa-var-arrow-circle-up,
  "toggle-on": $fa-var-toggle-on,
  "person-walking": $fa-var-person-walking,
  "walking": $fa-var-walking,
  "l": $fa-var-l,
  "fire": $fa-var-fire,
  "bed-pulse": $fa-var-bed-pulse,
  "procedures": $fa-var-procedures,
  "shuttle-space": $fa-var-shuttle-space,
  "space-shuttle": $fa-var-space-shuttle,
  "face-laugh": $fa-var-face-laugh,
  "laugh": $fa-var-laugh,
  "folder-open": $fa-var-folder-open,
  "heart-circle-plus": $fa-var-heart-circle-plus,
  "code-fork": $fa-var-code-fork,
  "city": $fa-var-city,
  "microphone-lines": $fa-var-microphone-lines,
  "microphone-alt": $fa-var-microphone-alt,
  "pepper-hot": $fa-var-pepper-hot,
  "unlock": $fa-var-unlock,
  "colon-sign": $fa-var-colon-sign,
  "headset": $fa-var-headset,
  "store-slash": $fa-var-store-slash,
  "road-circle-xmark": $fa-var-road-circle-xmark,
  "user-minus": $fa-var-user-minus,
  "mars-stroke-up": $fa-var-mars-stroke-up,
  "mars-stroke-v": $fa-var-mars-stroke-v,
  "champagne-glasses": $fa-var-champagne-glasses,
  "glass-cheers": $fa-var-glass-cheers,
  "clipboard": $fa-var-clipboard,
  "house-circle-exclamation": $fa-var-house-circle-exclamation,
  "file-arrow-up": $fa-var-file-arrow-up,
  "file-upload": $fa-var-file-upload,
  "wifi": $fa-var-wifi,
  "wifi-3": $fa-var-wifi-3,
  "wifi-strong": $fa-var-wifi-strong,
  "bath": $fa-var-bath,
  "bathtub": $fa-var-bathtub,
  "underline": $fa-var-underline,
  "user-pen": $fa-var-user-pen,
  "user-edit": $fa-var-user-edit,
  "signature": $fa-var-signature,
  "stroopwafel": $fa-var-stroopwafel,
  "bold": $fa-var-bold,
  "anchor-lock": $fa-var-anchor-lock,
  "building-ngo": $fa-var-building-ngo,
  "manat-sign": $fa-var-manat-sign,
  "not-equal": $fa-var-not-equal,
  "border-top-left": $fa-var-border-top-left,
  "border-style": $fa-var-border-style,
  "map-location-dot": $fa-var-map-location-dot,
  "map-marked-alt": $fa-var-map-marked-alt,
  "jedi": $fa-var-jedi,
  "square-poll-vertical": $fa-var-square-poll-vertical,
  "poll": $fa-var-poll,
  "mug-hot": $fa-var-mug-hot,
  "car-battery": $fa-var-car-battery,
  "battery-car": $fa-var-battery-car,
  "gift": $fa-var-gift,
  "dice-two": $fa-var-dice-two,
  "chess-queen": $fa-var-chess-queen,
  "glasses": $fa-var-glasses,
  "chess-board": $fa-var-chess-board,
  "building-circle-check": $fa-var-building-circle-check,
  "person-chalkboard": $fa-var-person-chalkboard,
  "mars-stroke-right": $fa-var-mars-stroke-right,
  "mars-stroke-h": $fa-var-mars-stroke-h,
  "hand-back-fist": $fa-var-hand-back-fist,
  "hand-rock": $fa-var-hand-rock,
  "square-caret-up": $fa-var-square-caret-up,
  "caret-square-up": $fa-var-caret-square-up,
  "cloud-showers-water": $fa-var-cloud-showers-water,
  "chart-bar": $fa-var-chart-bar,
  "bar-chart": $fa-var-bar-chart,
  "hands-bubbles": $fa-var-hands-bubbles,
  "hands-wash": $fa-var-hands-wash,
  "less-than-equal": $fa-var-less-than-equal,
  "train": $fa-var-train,
  "eye-low-vision": $fa-var-eye-low-vision,
  "low-vision": $fa-var-low-vision,
  "crow": $fa-var-crow,
  "sailboat": $fa-var-sailboat,
  "window-restore": $fa-var-window-restore,
  "square-plus": $fa-var-square-plus,
  "plus-square": $fa-var-plus-square,
  "torii-gate": $fa-var-torii-gate,
  "frog": $fa-var-frog,
  "bucket": $fa-var-bucket,
  "image": $fa-var-image,
  "microphone": $fa-var-microphone,
  "cow": $fa-var-cow,
  "caret-up": $fa-var-caret-up,
  "screwdriver": $fa-var-screwdriver,
  "folder-closed": $fa-var-folder-closed,
  "house-tsunami": $fa-var-house-tsunami,
  "square-nfi": $fa-var-square-nfi,
  "arrow-up-from-ground-water": $fa-var-arrow-up-from-ground-water,
  "martini-glass": $fa-var-martini-glass,
  "glass-martini-alt": $fa-var-glass-martini-alt,
  "rotate-left": $fa-var-rotate-left,
  "rotate-back": $fa-var-rotate-back,
  "rotate-backward": $fa-var-rotate-backward,
  "undo-alt": $fa-var-undo-alt,
  "table-columns": $fa-var-table-columns,
  "columns": $fa-var-columns,
  "lemon": $fa-var-lemon,
  "head-side-mask": $fa-var-head-side-mask,
  "handshake": $fa-var-handshake,
  "gem": $fa-var-gem,
  "dolly": $fa-var-dolly,
  "dolly-box": $fa-var-dolly-box,
  "smoking": $fa-var-smoking,
  "minimize": $fa-var-minimize,
  "compress-arrows-alt": $fa-var-compress-arrows-alt,
  "monument": $fa-var-monument,
  "snowplow": $fa-var-snowplow,
  "angles-right": $fa-var-angles-right,
  "angle-double-right": $fa-var-angle-double-right,
  "cannabis": $fa-var-cannabis,
  "circle-play": $fa-var-circle-play,
  "play-circle": $fa-var-play-circle,
  "tablets": $fa-var-tablets,
  "ethernet": $fa-var-ethernet,
  "euro-sign": $fa-var-euro-sign,
  "eur": $fa-var-eur,
  "euro": $fa-var-euro,
  "chair": $fa-var-chair,
  "circle-check": $fa-var-circle-check,
  "check-circle": $fa-var-check-circle,
  "circle-stop": $fa-var-circle-stop,
  "stop-circle": $fa-var-stop-circle,
  "compass-drafting": $fa-var-compass-drafting,
  "drafting-compass": $fa-var-drafting-compass,
  "plate-wheat": $fa-var-plate-wheat,
  "icicles": $fa-var-icicles,
  "person-shelter": $fa-var-person-shelter,
  "neuter": $fa-var-neuter,
  "id-badge": $fa-var-id-badge,
  "marker": $fa-var-marker,
  "face-laugh-beam": $fa-var-face-laugh-beam,
  "laugh-beam": $fa-var-laugh-beam,
  "helicopter-symbol": $fa-var-helicopter-symbol,
  "universal-access": $fa-var-universal-access,
  "circle-chevron-up": $fa-var-circle-chevron-up,
  "chevron-circle-up": $fa-var-chevron-circle-up,
  "lari-sign": $fa-var-lari-sign,
  "volcano": $fa-var-volcano,
  "person-walking-dashed-line-arrow-right": $fa-var-person-walking-dashed-line-arrow-right,
  "sterling-sign": $fa-var-sterling-sign,
  "gbp": $fa-var-gbp,
  "pound-sign": $fa-var-pound-sign,
  "viruses": $fa-var-viruses,
  "square-person-confined": $fa-var-square-person-confined,
  "user-tie": $fa-var-user-tie,
  "arrow-down-long": $fa-var-arrow-down-long,
  "long-arrow-down": $fa-var-long-arrow-down,
  "tent-arrow-down-to-line": $fa-var-tent-arrow-down-to-line,
  "certificate": $fa-var-certificate,
  "reply-all": $fa-var-reply-all,
  "mail-reply-all": $fa-var-mail-reply-all,
  "suitcase": $fa-var-suitcase,
  "person-skating": $fa-var-person-skating,
  "skating": $fa-var-skating,
  "filter-circle-dollar": $fa-var-filter-circle-dollar,
  "funnel-dollar": $fa-var-funnel-dollar,
  "camera-retro": $fa-var-camera-retro,
  "circle-arrow-down": $fa-var-circle-arrow-down,
  "arrow-circle-down": $fa-var-arrow-circle-down,
  "file-import": $fa-var-file-import,
  "arrow-right-to-file": $fa-var-arrow-right-to-file,
  "square-arrow-up-right": $fa-var-square-arrow-up-right,
  "external-link-square": $fa-var-external-link-square,
  "box-open": $fa-var-box-open,
  "scroll": $fa-var-scroll,
  "spa": $fa-var-spa,
  "location-pin-lock": $fa-var-location-pin-lock,
  "pause": $fa-var-pause,
  "hill-avalanche": $fa-var-hill-avalanche,
  "temperature-empty": $fa-var-temperature-empty,
  "temperature-0": $fa-var-temperature-0,
  "thermometer-0": $fa-var-thermometer-0,
  "thermometer-empty": $fa-var-thermometer-empty,
  "bomb": $fa-var-bomb,
  "registered": $fa-var-registered,
  "address-card": $fa-var-address-card,
  "contact-card": $fa-var-contact-card,
  "vcard": $fa-var-vcard,
  "scale-unbalanced-flip": $fa-var-scale-unbalanced-flip,
  "balance-scale-right": $fa-var-balance-scale-right,
  "subscript": $fa-var-subscript,
  "diamond-turn-right": $fa-var-diamond-turn-right,
  "directions": $fa-var-directions,
  "burst": $fa-var-burst,
  "house-laptop": $fa-var-house-laptop,
  "laptop-house": $fa-var-laptop-house,
  "face-tired": $fa-var-face-tired,
  "tired": $fa-var-tired,
  "money-bills": $fa-var-money-bills,
  "smog": $fa-var-smog,
  "crutch": $fa-var-crutch,
  "cloud-arrow-up": $fa-var-cloud-arrow-up,
  "cloud-upload": $fa-var-cloud-upload,
  "cloud-upload-alt": $fa-var-cloud-upload-alt,
  "palette": $fa-var-palette,
  "arrows-turn-right": $fa-var-arrows-turn-right,
  "vest": $fa-var-vest,
  "ferry": $fa-var-ferry,
  "arrows-down-to-people": $fa-var-arrows-down-to-people,
  "seedling": $fa-var-seedling,
  "sprout": $fa-var-sprout,
  "left-right": $fa-var-left-right,
  "arrows-alt-h": $fa-var-arrows-alt-h,
  "boxes-packing": $fa-var-boxes-packing,
  "circle-arrow-left": $fa-var-circle-arrow-left,
  "arrow-circle-left": $fa-var-arrow-circle-left,
  "group-arrows-rotate": $fa-var-group-arrows-rotate,
  "bowl-food": $fa-var-bowl-food,
  "candy-cane": $fa-var-candy-cane,
  "arrow-down-wide-short": $fa-var-arrow-down-wide-short,
  "sort-amount-asc": $fa-var-sort-amount-asc,
  "sort-amount-down": $fa-var-sort-amount-down,
  "cloud-bolt": $fa-var-cloud-bolt,
  "thunderstorm": $fa-var-thunderstorm,
  "text-slash": $fa-var-text-slash,
  "remove-format": $fa-var-remove-format,
  "face-smile-wink": $fa-var-face-smile-wink,
  "smile-wink": $fa-var-smile-wink,
  "file-word": $fa-var-file-word,
  "file-powerpoint": $fa-var-file-powerpoint,
  "arrows-left-right": $fa-var-arrows-left-right,
  "arrows-h": $fa-var-arrows-h,
  "house-lock": $fa-var-house-lock,
  "cloud-arrow-down": $fa-var-cloud-arrow-down,
  "cloud-download": $fa-var-cloud-download,
  "cloud-download-alt": $fa-var-cloud-download-alt,
  "children": $fa-var-children,
  "chalkboard": $fa-var-chalkboard,
  "blackboard": $fa-var-blackboard,
  "user-large-slash": $fa-var-user-large-slash,
  "user-alt-slash": $fa-var-user-alt-slash,
  "envelope-open": $fa-var-envelope-open,
  "handshake-simple-slash": $fa-var-handshake-simple-slash,
  "handshake-alt-slash": $fa-var-handshake-alt-slash,
  "mattress-pillow": $fa-var-mattress-pillow,
  "guarani-sign": $fa-var-guarani-sign,
  "arrows-rotate": $fa-var-arrows-rotate,
  "refresh": $fa-var-refresh,
  "sync": $fa-var-sync,
  "fire-extinguisher": $fa-var-fire-extinguisher,
  "cruzeiro-sign": $fa-var-cruzeiro-sign,
  "greater-than-equal": $fa-var-greater-than-equal,
  "shield-halved": $fa-var-shield-halved,
  "shield-alt": $fa-var-shield-alt,
  "book-atlas": $fa-var-book-atlas,
  "atlas": $fa-var-atlas,
  "virus": $fa-var-virus,
  "envelope-circle-check": $fa-var-envelope-circle-check,
  "layer-group": $fa-var-layer-group,
  "arrows-to-dot": $fa-var-arrows-to-dot,
  "archway": $fa-var-archway,
  "heart-circle-check": $fa-var-heart-circle-check,
  "house-chimney-crack": $fa-var-house-chimney-crack,
  "house-damage": $fa-var-house-damage,
  "file-zipper": $fa-var-file-zipper,
  "file-archive": $fa-var-file-archive,
  "square": $fa-var-square,
  "martini-glass-empty": $fa-var-martini-glass-empty,
  "glass-martini": $fa-var-glass-martini,
  "couch": $fa-var-couch,
  "cedi-sign": $fa-var-cedi-sign,
  "italic": $fa-var-italic,
  "church": $fa-var-church,
  "comments-dollar": $fa-var-comments-dollar,
  "democrat": $fa-var-democrat,
  "z": $fa-var-z,
  "person-skiing": $fa-var-person-skiing,
  "skiing": $fa-var-skiing,
  "road-lock": $fa-var-road-lock,
  "a": $fa-var-a,
  "temperature-arrow-down": $fa-var-temperature-arrow-down,
  "temperature-down": $fa-var-temperature-down,
  "feather-pointed": $fa-var-feather-pointed,
  "feather-alt": $fa-var-feather-alt,
  "p": $fa-var-p,
  "snowflake": $fa-var-snowflake,
  "newspaper": $fa-var-newspaper,
  "rectangle-ad": $fa-var-rectangle-ad,
  "ad": $fa-var-ad,
  "circle-arrow-right": $fa-var-circle-arrow-right,
  "arrow-circle-right": $fa-var-arrow-circle-right,
  "filter-circle-xmark": $fa-var-filter-circle-xmark,
  "locust": $fa-var-locust,
  "sort": $fa-var-sort,
  "unsorted": $fa-var-unsorted,
  "list-ol": $fa-var-list-ol,
  "list-1-2": $fa-var-list-1-2,
  "list-numeric": $fa-var-list-numeric,
  "person-dress-burst": $fa-var-person-dress-burst,
  "money-check-dollar": $fa-var-money-check-dollar,
  "money-check-alt": $fa-var-money-check-alt,
  "vector-square": $fa-var-vector-square,
  "bread-slice": $fa-var-bread-slice,
  "language": $fa-var-language,
  "face-kiss-wink-heart": $fa-var-face-kiss-wink-heart,
  "kiss-wink-heart": $fa-var-kiss-wink-heart,
  "filter": $fa-var-filter,
  "question": $fa-var-question,
  "file-signature": $fa-var-file-signature,
  "up-down-left-right": $fa-var-up-down-left-right,
  "arrows-alt": $fa-var-arrows-alt,
  "house-chimney-user": $fa-var-house-chimney-user,
  "hand-holding-heart": $fa-var-hand-holding-heart,
  "puzzle-piece": $fa-var-puzzle-piece,
  "money-check": $fa-var-money-check,
  "star-half-stroke": $fa-var-star-half-stroke,
  "star-half-alt": $fa-var-star-half-alt,
  "code": $fa-var-code,
  "whiskey-glass": $fa-var-whiskey-glass,
  "glass-whiskey": $fa-var-glass-whiskey,
  "building-circle-exclamation": $fa-var-building-circle-exclamation,
  "magnifying-glass-chart": $fa-var-magnifying-glass-chart,
  "arrow-up-right-from-square": $fa-var-arrow-up-right-from-square,
  "external-link": $fa-var-external-link,
  "cubes-stacked": $fa-var-cubes-stacked,
  "won-sign": $fa-var-won-sign,
  "krw": $fa-var-krw,
  "won": $fa-var-won,
  "virus-covid": $fa-var-virus-covid,
  "austral-sign": $fa-var-austral-sign,
  "f": $fa-var-f,
  "leaf": $fa-var-leaf,
  "road": $fa-var-road,
  "taxi": $fa-var-taxi,
  "cab": $fa-var-cab,
  "person-circle-plus": $fa-var-person-circle-plus,
  "chart-pie": $fa-var-chart-pie,
  "pie-chart": $fa-var-pie-chart,
  "bolt-lightning": $fa-var-bolt-lightning,
  "sack-xmark": $fa-var-sack-xmark,
  "file-excel": $fa-var-file-excel,
  "file-contract": $fa-var-file-contract,
  "fish-fins": $fa-var-fish-fins,
  "building-flag": $fa-var-building-flag,
  "face-grin-beam": $fa-var-face-grin-beam,
  "grin-beam": $fa-var-grin-beam,
  "object-ungroup": $fa-var-object-ungroup,
  "poop": $fa-var-poop,
  "location-pin": $fa-var-location-pin,
  "map-marker": $fa-var-map-marker,
  "kaaba": $fa-var-kaaba,
  "toilet-paper": $fa-var-toilet-paper,
  "helmet-safety": $fa-var-helmet-safety,
  "hard-hat": $fa-var-hard-hat,
  "hat-hard": $fa-var-hat-hard,
  "eject": $fa-var-eject,
  "circle-right": $fa-var-circle-right,
  "arrow-alt-circle-right": $fa-var-arrow-alt-circle-right,
  "plane-circle-check": $fa-var-plane-circle-check,
  "face-rolling-eyes": $fa-var-face-rolling-eyes,
  "meh-rolling-eyes": $fa-var-meh-rolling-eyes,
  "object-group": $fa-var-object-group,
  "chart-line": $fa-var-chart-line,
  "line-chart": $fa-var-line-chart,
  "mask-ventilator": $fa-var-mask-ventilator,
  "arrow-right": $fa-var-arrow-right,
  "signs-post": $fa-var-signs-post,
  "map-signs": $fa-var-map-signs,
  "cash-register": $fa-var-cash-register,
  "person-circle-question": $fa-var-person-circle-question,
  "h": $fa-var-h,
  "tarp": $fa-var-tarp,
  "screwdriver-wrench": $fa-var-screwdriver-wrench,
  "tools": $fa-var-tools,
  "arrows-to-eye": $fa-var-arrows-to-eye,
  "plug-circle-bolt": $fa-var-plug-circle-bolt,
  "heart": $fa-var-heart,
  "mars-and-venus": $fa-var-mars-and-venus,
  "house-user": $fa-var-house-user,
  "home-user": $fa-var-home-user,
  "dumpster-fire": $fa-var-dumpster-fire,
  "house-crack": $fa-var-house-crack,
  "martini-glass-citrus": $fa-var-martini-glass-citrus,
  "cocktail": $fa-var-cocktail,
  "face-surprise": $fa-var-face-surprise,
  "surprise": $fa-var-surprise,
  "bottle-water": $fa-var-bottle-water,
  "circle-pause": $fa-var-circle-pause,
  "pause-circle": $fa-var-pause-circle,
  "toilet-paper-slash": $fa-var-toilet-paper-slash,
  "apple-whole": $fa-var-apple-whole,
  "apple-alt": $fa-var-apple-alt,
  "kitchen-set": $fa-var-kitchen-set,
  "r": $fa-var-r,
  "temperature-quarter": $fa-var-temperature-quarter,
  "temperature-1": $fa-var-temperature-1,
  "thermometer-1": $fa-var-thermometer-1,
  "thermometer-quarter": $fa-var-thermometer-quarter,
  "cube": $fa-var-cube,
  "bitcoin-sign": $fa-var-bitcoin-sign,
  "shield-dog": $fa-var-shield-dog,
  "solar-panel": $fa-var-solar-panel,
  "lock-open": $fa-var-lock-open,
  "elevator": $fa-var-elevator,
  "money-bill-transfer": $fa-var-money-bill-transfer,
  "money-bill-trend-up": $fa-var-money-bill-trend-up,
  "house-flood-water-circle-arrow-right": $fa-var-house-flood-water-circle-arrow-right,
  "square-poll-horizontal": $fa-var-square-poll-horizontal,
  "poll-h": $fa-var-poll-h,
  "circle": $fa-var-circle,
  "backward-fast": $fa-var-backward-fast,
  "fast-backward": $fa-var-fast-backward,
  "recycle": $fa-var-recycle,
  "user-astronaut": $fa-var-user-astronaut,
  "plane-slash": $fa-var-plane-slash,
  "trademark": $fa-var-trademark,
  "basketball": $fa-var-basketball,
  "basketball-ball": $fa-var-basketball-ball,
  "satellite-dish": $fa-var-satellite-dish,
  "circle-up": $fa-var-circle-up,
  "arrow-alt-circle-up": $fa-var-arrow-alt-circle-up,
  "mobile-screen-button": $fa-var-mobile-screen-button,
  "mobile-alt": $fa-var-mobile-alt,
  "volume-high": $fa-var-volume-high,
  "volume-up": $fa-var-volume-up,
  "users-rays": $fa-var-users-rays,
  "wallet": $fa-var-wallet,
  "clipboard-check": $fa-var-clipboard-check,
  "file-audio": $fa-var-file-audio,
  "burger": $fa-var-burger,
  "hamburger": $fa-var-hamburger,
  "wrench": $fa-var-wrench,
  "bugs": $fa-var-bugs,
  "rupee-sign": $fa-var-rupee-sign,
  "rupee": $fa-var-rupee,
  "file-image": $fa-var-file-image,
  "circle-question": $fa-var-circle-question,
  "question-circle": $fa-var-question-circle,
  "plane-departure": $fa-var-plane-departure,
  "handshake-slash": $fa-var-handshake-slash,
  "book-bookmark": $fa-var-book-bookmark,
  "code-branch": $fa-var-code-branch,
  "hat-cowboy": $fa-var-hat-cowboy,
  "bridge": $fa-var-bridge,
  "phone-flip": $fa-var-phone-flip,
  "phone-alt": $fa-var-phone-alt,
  "truck-front": $fa-var-truck-front,
  "cat": $fa-var-cat,
  "anchor-circle-exclamation": $fa-var-anchor-circle-exclamation,
  "truck-field": $fa-var-truck-field,
  "route": $fa-var-route,
  "clipboard-question": $fa-var-clipboard-question,
  "panorama": $fa-var-panorama,
  "comment-medical": $fa-var-comment-medical,
  "teeth-open": $fa-var-teeth-open,
  "file-circle-minus": $fa-var-file-circle-minus,
  "tags": $fa-var-tags,
  "wine-glass": $fa-var-wine-glass,
  "forward-fast": $fa-var-forward-fast,
  "fast-forward": $fa-var-fast-forward,
  "face-meh-blank": $fa-var-face-meh-blank,
  "meh-blank": $fa-var-meh-blank,
  "square-parking": $fa-var-square-parking,
  "parking": $fa-var-parking,
  "house-signal": $fa-var-house-signal,
  "bars-progress": $fa-var-bars-progress,
  "tasks-alt": $fa-var-tasks-alt,
  "faucet-drip": $fa-var-faucet-drip,
  "cart-flatbed": $fa-var-cart-flatbed,
  "dolly-flatbed": $fa-var-dolly-flatbed,
  "ban-smoking": $fa-var-ban-smoking,
  "smoking-ban": $fa-var-smoking-ban,
  "terminal": $fa-var-terminal,
  "mobile-button": $fa-var-mobile-button,
  "house-medical-flag": $fa-var-house-medical-flag,
  "basket-shopping": $fa-var-basket-shopping,
  "shopping-basket": $fa-var-shopping-basket,
  "tape": $fa-var-tape,
  "bus-simple": $fa-var-bus-simple,
  "bus-alt": $fa-var-bus-alt,
  "eye": $fa-var-eye,
  "face-sad-cry": $fa-var-face-sad-cry,
  "sad-cry": $fa-var-sad-cry,
  "audio-description": $fa-var-audio-description,
  "person-military-to-person": $fa-var-person-military-to-person,
  "file-shield": $fa-var-file-shield,
  "user-slash": $fa-var-user-slash,
  "pen": $fa-var-pen,
  "tower-observation": $fa-var-tower-observation,
  "file-code": $fa-var-file-code,
  "signal": $fa-var-signal,
  "signal-5": $fa-var-signal-5,
  "signal-perfect": $fa-var-signal-perfect,
  "bus": $fa-var-bus,
  "heart-circle-xmark": $fa-var-heart-circle-xmark,
  "house-chimney": $fa-var-house-chimney,
  "home-lg": $fa-var-home-lg,
  "window-maximize": $fa-var-window-maximize,
  "face-frown": $fa-var-face-frown,
  "frown": $fa-var-frown,
  "prescription": $fa-var-prescription,
  "shop": $fa-var-shop,
  "store-alt": $fa-var-store-alt,
  "floppy-disk": $fa-var-floppy-disk,
  "save": $fa-var-save,
  "vihara": $fa-var-vihara,
  "scale-unbalanced": $fa-var-scale-unbalanced,
  "balance-scale-left": $fa-var-balance-scale-left,
  "sort-up": $fa-var-sort-up,
  "sort-asc": $fa-var-sort-asc,
  "comment-dots": $fa-var-comment-dots,
  "commenting": $fa-var-commenting,
  "plant-wilt": $fa-var-plant-wilt,
  "diamond": $fa-var-diamond,
  "face-grin-squint": $fa-var-face-grin-squint,
  "grin-squint": $fa-var-grin-squint,
  "hand-holding-dollar": $fa-var-hand-holding-dollar,
  "hand-holding-usd": $fa-var-hand-holding-usd,
  "bacterium": $fa-var-bacterium,
  "hand-pointer": $fa-var-hand-pointer,
  "drum-steelpan": $fa-var-drum-steelpan,
  "hand-scissors": $fa-var-hand-scissors,
  "hands-praying": $fa-var-hands-praying,
  "praying-hands": $fa-var-praying-hands,
  "arrow-rotate-right": $fa-var-arrow-rotate-right,
  "arrow-right-rotate": $fa-var-arrow-right-rotate,
  "arrow-rotate-forward": $fa-var-arrow-rotate-forward,
  "redo": $fa-var-redo,
  "biohazard": $fa-var-biohazard,
  "location-crosshairs": $fa-var-location-crosshairs,
  "location": $fa-var-location,
  "mars-double": $fa-var-mars-double,
  "child-dress": $fa-var-child-dress,
  "users-between-lines": $fa-var-users-between-lines,
  "lungs-virus": $fa-var-lungs-virus,
  "face-grin-tears": $fa-var-face-grin-tears,
  "grin-tears": $fa-var-grin-tears,
  "phone": $fa-var-phone,
  "calendar-xmark": $fa-var-calendar-xmark,
  "calendar-times": $fa-var-calendar-times,
  "child-reaching": $fa-var-child-reaching,
  "head-side-virus": $fa-var-head-side-virus,
  "user-gear": $fa-var-user-gear,
  "user-cog": $fa-var-user-cog,
  "arrow-up-1-9": $fa-var-arrow-up-1-9,
  "sort-numeric-up": $fa-var-sort-numeric-up,
  "door-closed": $fa-var-door-closed,
  "shield-virus": $fa-var-shield-virus,
  "dice-six": $fa-var-dice-six,
  "mosquito-net": $fa-var-mosquito-net,
  "bridge-water": $fa-var-bridge-water,
  "person-booth": $fa-var-person-booth,
  "text-width": $fa-var-text-width,
  "hat-wizard": $fa-var-hat-wizard,
  "pen-fancy": $fa-var-pen-fancy,
  "person-digging": $fa-var-person-digging,
  "digging": $fa-var-digging,
  "trash": $fa-var-trash,
  "gauge-simple": $fa-var-gauge-simple,
  "gauge-simple-med": $fa-var-gauge-simple-med,
  "tachometer-average": $fa-var-tachometer-average,
  "book-medical": $fa-var-book-medical,
  "poo": $fa-var-poo,
  "quote-right": $fa-var-quote-right,
  "quote-right-alt": $fa-var-quote-right-alt,
  "shirt": $fa-var-shirt,
  "t-shirt": $fa-var-t-shirt,
  "tshirt": $fa-var-tshirt,
  "cubes": $fa-var-cubes,
  "divide": $fa-var-divide,
  "tenge-sign": $fa-var-tenge-sign,
  "tenge": $fa-var-tenge,
  "headphones": $fa-var-headphones,
  "hands-holding": $fa-var-hands-holding,
  "hands-clapping": $fa-var-hands-clapping,
  "republican": $fa-var-republican,
  "arrow-left": $fa-var-arrow-left,
  "person-circle-xmark": $fa-var-person-circle-xmark,
  "ruler": $fa-var-ruler,
  "align-left": $fa-var-align-left,
  "dice-d6": $fa-var-dice-d6,
  "restroom": $fa-var-restroom,
  "j": $fa-var-j,
  "users-viewfinder": $fa-var-users-viewfinder,
  "file-video": $fa-var-file-video,
  "up-right-from-square": $fa-var-up-right-from-square,
  "external-link-alt": $fa-var-external-link-alt,
  "table-cells": $fa-var-table-cells,
  "th": $fa-var-th,
  "file-pdf": $fa-var-file-pdf,
  "book-bible": $fa-var-book-bible,
  "bible": $fa-var-bible,
  "o": $fa-var-o,
  "suitcase-medical": $fa-var-suitcase-medical,
  "medkit": $fa-var-medkit,
  "user-secret": $fa-var-user-secret,
  "otter": $fa-var-otter,
  "person-dress": $fa-var-person-dress,
  "female": $fa-var-female,
  "comment-dollar": $fa-var-comment-dollar,
  "business-time": $fa-var-business-time,
  "briefcase-clock": $fa-var-briefcase-clock,
  "table-cells-large": $fa-var-table-cells-large,
  "th-large": $fa-var-th-large,
  "book-tanakh": $fa-var-book-tanakh,
  "tanakh": $fa-var-tanakh,
  "phone-volume": $fa-var-phone-volume,
  "volume-control-phone": $fa-var-volume-control-phone,
  "hat-cowboy-side": $fa-var-hat-cowboy-side,
  "clipboard-user": $fa-var-clipboard-user,
  "child": $fa-var-child,
  "lira-sign": $fa-var-lira-sign,
  "satellite": $fa-var-satellite,
  "plane-lock": $fa-var-plane-lock,
  "tag": $fa-var-tag,
  "comment": $fa-var-comment,
  "cake-candles": $fa-var-cake-candles,
  "birthday-cake": $fa-var-birthday-cake,
  "cake": $fa-var-cake,
  "envelope": $fa-var-envelope,
  "angles-up": $fa-var-angles-up,
  "angle-double-up": $fa-var-angle-double-up,
  "paperclip": $fa-var-paperclip,
  "arrow-right-to-city": $fa-var-arrow-right-to-city,
  "ribbon": $fa-var-ribbon,
  "lungs": $fa-var-lungs,
  "arrow-up-9-1": $fa-var-arrow-up-9-1,
  "sort-numeric-up-alt": $fa-var-sort-numeric-up-alt,
  "litecoin-sign": $fa-var-litecoin-sign,
  "border-none": $fa-var-border-none,
  "circle-nodes": $fa-var-circle-nodes,
  "parachute-box": $fa-var-parachute-box,
  "indent": $fa-var-indent,
  "truck-field-un": $fa-var-truck-field-un,
  "hourglass": $fa-var-hourglass,
  "hourglass-empty": $fa-var-hourglass-empty,
  "mountain": $fa-var-mountain,
  "user-doctor": $fa-var-user-doctor,
  "user-md": $fa-var-user-md,
  "circle-info": $fa-var-circle-info,
  "info-circle": $fa-var-info-circle,
  "cloud-meatball": $fa-var-cloud-meatball,
  "camera": $fa-var-camera,
  "camera-alt": $fa-var-camera-alt,
  "square-virus": $fa-var-square-virus,
  "meteor": $fa-var-meteor,
  "car-on": $fa-var-car-on,
  "sleigh": $fa-var-sleigh,
  "arrow-down-1-9": $fa-var-arrow-down-1-9,
  "sort-numeric-asc": $fa-var-sort-numeric-asc,
  "sort-numeric-down": $fa-var-sort-numeric-down,
  "hand-holding-droplet": $fa-var-hand-holding-droplet,
  "hand-holding-water": $fa-var-hand-holding-water,
  "water": $fa-var-water,
  "calendar-check": $fa-var-calendar-check,
  "braille": $fa-var-braille,
  "prescription-bottle-medical": $fa-var-prescription-bottle-medical,
  "prescription-bottle-alt": $fa-var-prescription-bottle-alt,
  "landmark": $fa-var-landmark,
  "truck": $fa-var-truck,
  "crosshairs": $fa-var-crosshairs,
  "person-cane": $fa-var-person-cane,
  "tent": $fa-var-tent,
  "vest-patches": $fa-var-vest-patches,
  "check-double": $fa-var-check-double,
  "arrow-down-a-z": $fa-var-arrow-down-a-z,
  "sort-alpha-asc": $fa-var-sort-alpha-asc,
  "sort-alpha-down": $fa-var-sort-alpha-down,
  "money-bill-wheat": $fa-var-money-bill-wheat,
  "cookie": $fa-var-cookie,
  "arrow-rotate-left": $fa-var-arrow-rotate-left,
  "arrow-left-rotate": $fa-var-arrow-left-rotate,
  "arrow-rotate-back": $fa-var-arrow-rotate-back,
  "arrow-rotate-backward": $fa-var-arrow-rotate-backward,
  "undo": $fa-var-undo,
  "hard-drive": $fa-var-hard-drive,
  "hdd": $fa-var-hdd,
  "face-grin-squint-tears": $fa-var-face-grin-squint-tears,
  "grin-squint-tears": $fa-var-grin-squint-tears,
  "dumbbell": $fa-var-dumbbell,
  "rectangle-list": $fa-var-rectangle-list,
  "list-alt": $fa-var-list-alt,
  "tarp-droplet": $fa-var-tarp-droplet,
  "house-medical-circle-check": $fa-var-house-medical-circle-check,
  "person-skiing-nordic": $fa-var-person-skiing-nordic,
  "skiing-nordic": $fa-var-skiing-nordic,
  "calendar-plus": $fa-var-calendar-plus,
  "plane-arrival": $fa-var-plane-arrival,
  "circle-left": $fa-var-circle-left,
  "arrow-alt-circle-left": $fa-var-arrow-alt-circle-left,
  "train-subway": $fa-var-train-subway,
  "subway": $fa-var-subway,
  "chart-gantt": $fa-var-chart-gantt,
  "indian-rupee-sign": $fa-var-indian-rupee-sign,
  "indian-rupee": $fa-var-indian-rupee,
  "inr": $fa-var-inr,
  "crop-simple": $fa-var-crop-simple,
  "crop-alt": $fa-var-crop-alt,
  "money-bill-1": $fa-var-money-bill-1,
  "money-bill-alt": $fa-var-money-bill-alt,
  "left-long": $fa-var-left-long,
  "long-arrow-alt-left": $fa-var-long-arrow-alt-left,
  "dna": $fa-var-dna,
  "virus-slash": $fa-var-virus-slash,
  "minus": $fa-var-minus,
  "subtract": $fa-var-subtract,
  "chess": $fa-var-chess,
  "arrow-left-long": $fa-var-arrow-left-long,
  "long-arrow-left": $fa-var-long-arrow-left,
  "plug-circle-check": $fa-var-plug-circle-check,
  "street-view": $fa-var-street-view,
  "franc-sign": $fa-var-franc-sign,
  "volume-off": $fa-var-volume-off,
  "hands-asl-interpreting": $fa-var-hands-asl-interpreting,
  "american-sign-language-interpreting": $fa-var-american-sign-language-interpreting,
  "asl-interpreting": $fa-var-asl-interpreting,
  "hands-american-sign-language-interpreting": $fa-var-hands-american-sign-language-interpreting,
  "gear": $fa-var-gear,
  "cog": $fa-var-cog,
  "droplet-slash": $fa-var-droplet-slash,
  "tint-slash": $fa-var-tint-slash,
  "mosque": $fa-var-mosque,
  "mosquito": $fa-var-mosquito,
  "star-of-david": $fa-var-star-of-david,
  "person-military-rifle": $fa-var-person-military-rifle,
  "cart-shopping": $fa-var-cart-shopping,
  "shopping-cart": $fa-var-shopping-cart,
  "vials": $fa-var-vials,
  "plug-circle-plus": $fa-var-plug-circle-plus,
  "place-of-worship": $fa-var-place-of-worship,
  "grip-vertical": $fa-var-grip-vertical,
  "arrow-turn-up": $fa-var-arrow-turn-up,
  "level-up": $fa-var-level-up,
  "u": $fa-var-u,
  "square-root-variable": $fa-var-square-root-variable,
  "square-root-alt": $fa-var-square-root-alt,
  "clock": $fa-var-clock,
  "clock-four": $fa-var-clock-four,
  "backward-step": $fa-var-backward-step,
  "step-backward": $fa-var-step-backward,
  "pallet": $fa-var-pallet,
  "faucet": $fa-var-faucet,
  "baseball-bat-ball": $fa-var-baseball-bat-ball,
  "s": $fa-var-s,
  "timeline": $fa-var-timeline,
  "keyboard": $fa-var-keyboard,
  "caret-down": $fa-var-caret-down,
  "house-chimney-medical": $fa-var-house-chimney-medical,
  "clinic-medical": $fa-var-clinic-medical,
  "temperature-three-quarters": $fa-var-temperature-three-quarters,
  "temperature-3": $fa-var-temperature-3,
  "thermometer-3": $fa-var-thermometer-3,
  "thermometer-three-quarters": $fa-var-thermometer-three-quarters,
  "mobile-screen": $fa-var-mobile-screen,
  "mobile-android-alt": $fa-var-mobile-android-alt,
  "plane-up": $fa-var-plane-up,
  "piggy-bank": $fa-var-piggy-bank,
  "battery-half": $fa-var-battery-half,
  "battery-3": $fa-var-battery-3,
  "mountain-city": $fa-var-mountain-city,
  "coins": $fa-var-coins,
  "khanda": $fa-var-khanda,
  "sliders": $fa-var-sliders,
  "sliders-h": $fa-var-sliders-h,
  "folder-tree": $fa-var-folder-tree,
  "network-wired": $fa-var-network-wired,
  "map-pin": $fa-var-map-pin,
  "hamsa": $fa-var-hamsa,
  "cent-sign": $fa-var-cent-sign,
  "flask": $fa-var-flask,
  "person-pregnant": $fa-var-person-pregnant,
  "wand-sparkles": $fa-var-wand-sparkles,
  "ellipsis-vertical": $fa-var-ellipsis-vertical,
  "ellipsis-v": $fa-var-ellipsis-v,
  "ticket": $fa-var-ticket,
  "power-off": $fa-var-power-off,
  "right-long": $fa-var-right-long,
  "long-arrow-alt-right": $fa-var-long-arrow-alt-right,
  "flag-usa": $fa-var-flag-usa,
  "laptop-file": $fa-var-laptop-file,
  "tty": $fa-var-tty,
  "teletype": $fa-var-teletype,
  "diagram-next": $fa-var-diagram-next,
  "person-rifle": $fa-var-person-rifle,
  "house-medical-circle-exclamation": $fa-var-house-medical-circle-exclamation,
  "closed-captioning": $fa-var-closed-captioning,
  "person-hiking": $fa-var-person-hiking,
  "hiking": $fa-var-hiking,
  "venus-double": $fa-var-venus-double,
  "images": $fa-var-images,
  "calculator": $fa-var-calculator,
  "people-pulling": $fa-var-people-pulling,
  "n": $fa-var-n,
  "cable-car": $fa-var-cable-car,
  "tram": $fa-var-tram,
  "cloud-rain": $fa-var-cloud-rain,
  "building-circle-xmark": $fa-var-building-circle-xmark,
  "ship": $fa-var-ship,
  "arrows-down-to-line": $fa-var-arrows-down-to-line,
  "download": $fa-var-download,
  "face-grin": $fa-var-face-grin,
  "grin": $fa-var-grin,
  "delete-left": $fa-var-delete-left,
  "backspace": $fa-var-backspace,
  "eye-dropper": $fa-var-eye-dropper,
  "eye-dropper-empty": $fa-var-eye-dropper-empty,
  "eyedropper": $fa-var-eyedropper,
  "file-circle-check": $fa-var-file-circle-check,
  "forward": $fa-var-forward,
  "mobile": $fa-var-mobile,
  "mobile-android": $fa-var-mobile-android,
  "mobile-phone": $fa-var-mobile-phone,
  "face-meh": $fa-var-face-meh,
  "meh": $fa-var-meh,
  "align-center": $fa-var-align-center,
  "book-skull": $fa-var-book-skull,
  "book-dead": $fa-var-book-dead,
  "id-card": $fa-var-id-card,
  "drivers-license": $fa-var-drivers-license,
  "outdent": $fa-var-outdent,
  "dedent": $fa-var-dedent,
  "heart-circle-exclamation": $fa-var-heart-circle-exclamation,
  "house": $fa-var-house,
  "home": $fa-var-home,
  "home-alt": $fa-var-home-alt,
  "home-lg-alt": $fa-var-home-lg-alt,
  "calendar-week": $fa-var-calendar-week,
  "laptop-medical": $fa-var-laptop-medical,
  "b": $fa-var-b,
  "file-medical": $fa-var-file-medical,
  "dice-one": $fa-var-dice-one,
  "kiwi-bird": $fa-var-kiwi-bird,
  "arrow-right-arrow-left": $fa-var-arrow-right-arrow-left,
  "exchange": $fa-var-exchange,
  "rotate-right": $fa-var-rotate-right,
  "redo-alt": $fa-var-redo-alt,
  "rotate-forward": $fa-var-rotate-forward,
  "utensils": $fa-var-utensils,
  "cutlery": $fa-var-cutlery,
  "arrow-up-wide-short": $fa-var-arrow-up-wide-short,
  "sort-amount-up": $fa-var-sort-amount-up,
  "mill-sign": $fa-var-mill-sign,
  "bowl-rice": $fa-var-bowl-rice,
  "skull": $fa-var-skull,
  "tower-broadcast": $fa-var-tower-broadcast,
  "broadcast-tower": $fa-var-broadcast-tower,
  "truck-pickup": $fa-var-truck-pickup,
  "up-long": $fa-var-up-long,
  "long-arrow-alt-up": $fa-var-long-arrow-alt-up,
  "stop": $fa-var-stop,
  "code-merge": $fa-var-code-merge,
  "upload": $fa-var-upload,
  "hurricane": $fa-var-hurricane,
  "mound": $fa-var-mound,
  "toilet-portable": $fa-var-toilet-portable,
  "compact-disc": $fa-var-compact-disc,
  "file-arrow-down": $fa-var-file-arrow-down,
  "file-download": $fa-var-file-download,
  "caravan": $fa-var-caravan,
  "shield-cat": $fa-var-shield-cat,
  "bolt": $fa-var-bolt,
  "zap": $fa-var-zap,
  "glass-water": $fa-var-glass-water,
  "oil-well": $fa-var-oil-well,
  "vault": $fa-var-vault,
  "mars": $fa-var-mars,
  "toilet": $fa-var-toilet,
  "plane-circle-xmark": $fa-var-plane-circle-xmark,
  "yen-sign": $fa-var-yen-sign,
  "cny": $fa-var-cny,
  "jpy": $fa-var-jpy,
  "rmb": $fa-var-rmb,
  "yen": $fa-var-yen,
  "ruble-sign": $fa-var-ruble-sign,
  "rouble": $fa-var-rouble,
  "rub": $fa-var-rub,
  "ruble": $fa-var-ruble,
  "sun": $fa-var-sun,
  "guitar": $fa-var-guitar,
  "face-laugh-wink": $fa-var-face-laugh-wink,
  "laugh-wink": $fa-var-laugh-wink,
  "horse-head": $fa-var-horse-head,
  "bore-hole": $fa-var-bore-hole,
  "industry": $fa-var-industry,
  "circle-down": $fa-var-circle-down,
  "arrow-alt-circle-down": $fa-var-arrow-alt-circle-down,
  "arrows-turn-to-dots": $fa-var-arrows-turn-to-dots,
  "florin-sign": $fa-var-florin-sign,
  "arrow-down-short-wide": $fa-var-arrow-down-short-wide,
  "sort-amount-desc": $fa-var-sort-amount-desc,
  "sort-amount-down-alt": $fa-var-sort-amount-down-alt,
  "less-than": $fa-var-less-than,
  "angle-down": $fa-var-angle-down,
  "car-tunnel": $fa-var-car-tunnel,
  "head-side-cough": $fa-var-head-side-cough,
  "grip-lines": $fa-var-grip-lines,
  "thumbs-down": $fa-var-thumbs-down,
  "user-lock": $fa-var-user-lock,
  "arrow-right-long": $fa-var-arrow-right-long,
  "long-arrow-right": $fa-var-long-arrow-right,
  "anchor-circle-xmark": $fa-var-anchor-circle-xmark,
  "ellipsis": $fa-var-ellipsis,
  "ellipsis-h": $fa-var-ellipsis-h,
  "chess-pawn": $fa-var-chess-pawn,
  "kit-medical": $fa-var-kit-medical,
  "first-aid": $fa-var-first-aid,
  "person-through-window": $fa-var-person-through-window,
  "toolbox": $fa-var-toolbox,
  "hands-holding-circle": $fa-var-hands-holding-circle,
  "bug": $fa-var-bug,
  "credit-card": $fa-var-credit-card,
  "credit-card-alt": $fa-var-credit-card-alt,
  "car": $fa-var-car,
  "automobile": $fa-var-automobile,
  "hand-holding-hand": $fa-var-hand-holding-hand,
  "book-open-reader": $fa-var-book-open-reader,
  "book-reader": $fa-var-book-reader,
  "mountain-sun": $fa-var-mountain-sun,
  "arrows-left-right-to-line": $fa-var-arrows-left-right-to-line,
  "dice-d20": $fa-var-dice-d20,
  "truck-droplet": $fa-var-truck-droplet,
  "file-circle-xmark": $fa-var-file-circle-xmark,
  "temperature-arrow-up": $fa-var-temperature-arrow-up,
  "temperature-up": $fa-var-temperature-up,
  "medal": $fa-var-medal,
  "bed": $fa-var-bed,
  "square-h": $fa-var-square-h,
  "h-square": $fa-var-h-square,
  "podcast": $fa-var-podcast,
  "temperature-full": $fa-var-temperature-full,
  "temperature-4": $fa-var-temperature-4,
  "thermometer-4": $fa-var-thermometer-4,
  "thermometer-full": $fa-var-thermometer-full,
  "bell": $fa-var-bell,
  "superscript": $fa-var-superscript,
  "plug-circle-xmark": $fa-var-plug-circle-xmark,
  "star-of-life": $fa-var-star-of-life,
  "phone-slash": $fa-var-phone-slash,
  "paint-roller": $fa-var-paint-roller,
  "handshake-angle": $fa-var-handshake-angle,
  "hands-helping": $fa-var-hands-helping,
  "location-dot": $fa-var-location-dot,
  "map-marker-alt": $fa-var-map-marker-alt,
  "file": $fa-var-file,
  "greater-than": $fa-var-greater-than,
  "person-swimming": $fa-var-person-swimming,
  "swimmer": $fa-var-swimmer,
  "arrow-down": $fa-var-arrow-down,
  "droplet": $fa-var-droplet,
  "tint": $fa-var-tint,
  "eraser": $fa-var-eraser,
  "earth-americas": $fa-var-earth-americas,
  "earth": $fa-var-earth,
  "earth-america": $fa-var-earth-america,
  "globe-americas": $fa-var-globe-americas,
  "person-burst": $fa-var-person-burst,
  "dove": $fa-var-dove,
  "battery-empty": $fa-var-battery-empty,
  "battery-0": $fa-var-battery-0,
  "socks": $fa-var-socks,
  "inbox": $fa-var-inbox,
  "section": $fa-var-section,
  "gauge-high": $fa-var-gauge-high,
  "tachometer-alt": $fa-var-tachometer-alt,
  "tachometer-alt-fast": $fa-var-tachometer-alt-fast,
  "envelope-open-text": $fa-var-envelope-open-text,
  "hospital": $fa-var-hospital,
  "hospital-alt": $fa-var-hospital-alt,
  "hospital-wide": $fa-var-hospital-wide,
  "wine-bottle": $fa-var-wine-bottle,
  "chess-rook": $fa-var-chess-rook,
  "bars-staggered": $fa-var-bars-staggered,
  "reorder": $fa-var-reorder,
  "stream": $fa-var-stream,
  "dharmachakra": $fa-var-dharmachakra,
  "hotdog": $fa-var-hotdog,
  "person-walking-with-cane": $fa-var-person-walking-with-cane,
  "blind": $fa-var-blind,
  "drum": $fa-var-drum,
  "ice-cream": $fa-var-ice-cream,
  "heart-circle-bolt": $fa-var-heart-circle-bolt,
  "fax": $fa-var-fax,
  "paragraph": $fa-var-paragraph,
  "check-to-slot": $fa-var-check-to-slot,
  "vote-yea": $fa-var-vote-yea,
  "star-half": $fa-var-star-half,
  "boxes-stacked": $fa-var-boxes-stacked,
  "boxes": $fa-var-boxes,
  "boxes-alt": $fa-var-boxes-alt,
  "link": $fa-var-link,
  "chain": $fa-var-chain,
  "ear-listen": $fa-var-ear-listen,
  "assistive-listening-systems": $fa-var-assistive-listening-systems,
  "tree-city": $fa-var-tree-city,
  "play": $fa-var-play,
  "font": $fa-var-font,
  "rupiah-sign": $fa-var-rupiah-sign,
  "magnifying-glass": $fa-var-magnifying-glass,
  "search": $fa-var-search,
  "table-tennis-paddle-ball": $fa-var-table-tennis-paddle-ball,
  "ping-pong-paddle-ball": $fa-var-ping-pong-paddle-ball,
  "table-tennis": $fa-var-table-tennis,
  "person-dots-from-line": $fa-var-person-dots-from-line,
  "diagnoses": $fa-var-diagnoses,
  "trash-can-arrow-up": $fa-var-trash-can-arrow-up,
  "trash-restore-alt": $fa-var-trash-restore-alt,
  "naira-sign": $fa-var-naira-sign,
  "cart-arrow-down": $fa-var-cart-arrow-down,
  "walkie-talkie": $fa-var-walkie-talkie,
  "file-pen": $fa-var-file-pen,
  "file-edit": $fa-var-file-edit,
  "receipt": $fa-var-receipt,
  "square-pen": $fa-var-square-pen,
  "pen-square": $fa-var-pen-square,
  "pencil-square": $fa-var-pencil-square,
  "suitcase-rolling": $fa-var-suitcase-rolling,
  "person-circle-exclamation": $fa-var-person-circle-exclamation,
  "chevron-down": $fa-var-chevron-down,
  "battery-full": $fa-var-battery-full,
  "battery": $fa-var-battery,
  "battery-5": $fa-var-battery-5,
  "skull-crossbones": $fa-var-skull-crossbones,
  "code-compare": $fa-var-code-compare,
  "list-ul": $fa-var-list-ul,
  "list-dots": $fa-var-list-dots,
  "school-lock": $fa-var-school-lock,
  "tower-cell": $fa-var-tower-cell,
  "down-long": $fa-var-down-long,
  "long-arrow-alt-down": $fa-var-long-arrow-alt-down,
  "ranking-star": $fa-var-ranking-star,
  "chess-king": $fa-var-chess-king,
  "person-harassing": $fa-var-person-harassing,
  "brazilian-real-sign": $fa-var-brazilian-real-sign,
  "landmark-dome": $fa-var-landmark-dome,
  "landmark-alt": $fa-var-landmark-alt,
  "arrow-up": $fa-var-arrow-up,
  "tv": $fa-var-tv,
  "television": $fa-var-television,
  "tv-alt": $fa-var-tv-alt,
  "shrimp": $fa-var-shrimp,
  "list-check": $fa-var-list-check,
  "tasks": $fa-var-tasks,
  "jug-detergent": $fa-var-jug-detergent,
  "circle-user": $fa-var-circle-user,
  "user-circle": $fa-var-user-circle,
  "user-shield": $fa-var-user-shield,
  "wind": $fa-var-wind,
  "car-burst": $fa-var-car-burst,
  "car-crash": $fa-var-car-crash,
  "y": $fa-var-y,
  "person-snowboarding": $fa-var-person-snowboarding,
  "snowboarding": $fa-var-snowboarding,
  "truck-fast": $fa-var-truck-fast,
  "shipping-fast": $fa-var-shipping-fast,
  "fish": $fa-var-fish,
  "user-graduate": $fa-var-user-graduate,
  "circle-half-stroke": $fa-var-circle-half-stroke,
  "adjust": $fa-var-adjust,
  "clapperboard": $fa-var-clapperboard,
  "circle-radiation": $fa-var-circle-radiation,
  "radiation-alt": $fa-var-radiation-alt,
  "baseball": $fa-var-baseball,
  "baseball-ball": $fa-var-baseball-ball,
  "jet-fighter-up": $fa-var-jet-fighter-up,
  "diagram-project": $fa-var-diagram-project,
  "project-diagram": $fa-var-project-diagram,
  "copy": $fa-var-copy,
  "volume-xmark": $fa-var-volume-xmark,
  "volume-mute": $fa-var-volume-mute,
  "volume-times": $fa-var-volume-times,
  "hand-sparkles": $fa-var-hand-sparkles,
  "grip": $fa-var-grip,
  "grip-horizontal": $fa-var-grip-horizontal,
  "share-from-square": $fa-var-share-from-square,
  "share-square": $fa-var-share-square,
  "child-combatant": $fa-var-child-combatant,
  "child-rifle": $fa-var-child-rifle,
  "gun": $fa-var-gun,
  "square-phone": $fa-var-square-phone,
  "phone-square": $fa-var-phone-square,
  "plus": $fa-var-plus,
  "add": $fa-var-add,
  "expand": $fa-var-expand,
  "computer": $fa-var-computer,
  "xmark": $fa-var-xmark,
  "close": $fa-var-close,
  "multiply": $fa-var-multiply,
  "remove": $fa-var-remove,
  "times": $fa-var-times,
  "arrows-up-down-left-right": $fa-var-arrows-up-down-left-right,
  "arrows": $fa-var-arrows,
  "chalkboard-user": $fa-var-chalkboard-user,
  "chalkboard-teacher": $fa-var-chalkboard-teacher,
  "peso-sign": $fa-var-peso-sign,
  "building-shield": $fa-var-building-shield,
  "baby": $fa-var-baby,
  "users-line": $fa-var-users-line,
  "quote-left": $fa-var-quote-left,
  "quote-left-alt": $fa-var-quote-left-alt,
  "tractor": $fa-var-tractor,
  "trash-arrow-up": $fa-var-trash-arrow-up,
  "trash-restore": $fa-var-trash-restore,
  "arrow-down-up-lock": $fa-var-arrow-down-up-lock,
  "lines-leaning": $fa-var-lines-leaning,
  "ruler-combined": $fa-var-ruler-combined,
  "copyright": $fa-var-copyright,
  "equals": $fa-var-equals,
  "blender": $fa-var-blender,
  "teeth": $fa-var-teeth,
  "shekel-sign": $fa-var-shekel-sign,
  "ils": $fa-var-ils,
  "shekel": $fa-var-shekel,
  "sheqel": $fa-var-sheqel,
  "sheqel-sign": $fa-var-sheqel-sign,
  "map": $fa-var-map,
  "rocket": $fa-var-rocket,
  "photo-film": $fa-var-photo-film,
  "photo-video": $fa-var-photo-video,
  "folder-minus": $fa-var-folder-minus,
  "store": $fa-var-store,
  "arrow-trend-up": $fa-var-arrow-trend-up,
  "plug-circle-minus": $fa-var-plug-circle-minus,
  "sign-hanging": $fa-var-sign-hanging,
  "sign": $fa-var-sign,
  "bezier-curve": $fa-var-bezier-curve,
  "bell-slash": $fa-var-bell-slash,
  "tablet": $fa-var-tablet,
  "tablet-android": $fa-var-tablet-android,
  "school-flag": $fa-var-school-flag,
  "fill": $fa-var-fill,
  "angle-up": $fa-var-angle-up,
  "drumstick-bite": $fa-var-drumstick-bite,
  "holly-berry": $fa-var-holly-berry,
  "chevron-left": $fa-var-chevron-left,
  "bacteria": $fa-var-bacteria,
  "hand-lizard": $fa-var-hand-lizard,
  "notdef": $fa-var-notdef,
  "disease": $fa-var-disease,
  "briefcase-medical": $fa-var-briefcase-medical,
  "genderless": $fa-var-genderless,
  "chevron-right": $fa-var-chevron-right,
  "retweet": $fa-var-retweet,
  "car-rear": $fa-var-car-rear,
  "car-alt": $fa-var-car-alt,
  "pump-soap": $fa-var-pump-soap,
  "video-slash": $fa-var-video-slash,
  "battery-quarter": $fa-var-battery-quarter,
  "battery-2": $fa-var-battery-2,
  "radio": $fa-var-radio,
  "baby-carriage": $fa-var-baby-carriage,
  "carriage-baby": $fa-var-carriage-baby,
  "traffic-light": $fa-var-traffic-light,
  "thermometer": $fa-var-thermometer,
  "vr-cardboard": $fa-var-vr-cardboard,
  "hand-middle-finger": $fa-var-hand-middle-finger,
  "percent": $fa-var-percent,
  "percentage": $fa-var-percentage,
  "truck-moving": $fa-var-truck-moving,
  "glass-water-droplet": $fa-var-glass-water-droplet,
  "display": $fa-var-display,
  "face-smile": $fa-var-face-smile,
  "smile": $fa-var-smile,
  "thumbtack": $fa-var-thumbtack,
  "thumb-tack": $fa-var-thumb-tack,
  "trophy": $fa-var-trophy,
  "person-praying": $fa-var-person-praying,
  "pray": $fa-var-pray,
  "hammer": $fa-var-hammer,
  "hand-peace": $fa-var-hand-peace,
  "rotate": $fa-var-rotate,
  "sync-alt": $fa-var-sync-alt,
  "spinner": $fa-var-spinner,
  "robot": $fa-var-robot,
  "peace": $fa-var-peace,
  "gears": $fa-var-gears,
  "cogs": $fa-var-cogs,
  "warehouse": $fa-var-warehouse,
  "arrow-up-right-dots": $fa-var-arrow-up-right-dots,
  "splotch": $fa-var-splotch,
  "face-grin-hearts": $fa-var-face-grin-hearts,
  "grin-hearts": $fa-var-grin-hearts,
  "dice-four": $fa-var-dice-four,
  "sim-card": $fa-var-sim-card,
  "transgender": $fa-var-transgender,
  "transgender-alt": $fa-var-transgender-alt,
  "mercury": $fa-var-mercury,
  "arrow-turn-down": $fa-var-arrow-turn-down,
  "level-down": $fa-var-level-down,
  "person-falling-burst": $fa-var-person-falling-burst,
  "award": $fa-var-award,
  "ticket-simple": $fa-var-ticket-simple,
  "ticket-alt": $fa-var-ticket-alt,
  "building": $fa-var-building,
  "angles-left": $fa-var-angles-left,
  "angle-double-left": $fa-var-angle-double-left,
  "qrcode": $fa-var-qrcode,
  "clock-rotate-left": $fa-var-clock-rotate-left,
  "history": $fa-var-history,
  "face-grin-beam-sweat": $fa-var-face-grin-beam-sweat,
  "grin-beam-sweat": $fa-var-grin-beam-sweat,
  "file-export": $fa-var-file-export,
  "arrow-right-from-file": $fa-var-arrow-right-from-file,
  "shield": $fa-var-shield,
  "shield-blank": $fa-var-shield-blank,
  "arrow-up-short-wide": $fa-var-arrow-up-short-wide,
  "sort-amount-up-alt": $fa-var-sort-amount-up-alt,
  "house-medical": $fa-var-house-medical,
  "golf-ball-tee": $fa-var-golf-ball-tee,
  "golf-ball": $fa-var-golf-ball,
  "circle-chevron-left": $fa-var-circle-chevron-left,
  "chevron-circle-left": $fa-var-chevron-circle-left,
  "house-chimney-window": $fa-var-house-chimney-window,
  "pen-nib": $fa-var-pen-nib,
  "tent-arrow-turn-left": $fa-var-tent-arrow-turn-left,
  "tents": $fa-var-tents,
  "wand-magic": $fa-var-wand-magic,
  "magic": $fa-var-magic,
  "dog": $fa-var-dog,
  "carrot": $fa-var-carrot,
  "moon": $fa-var-moon,
  "wine-glass-empty": $fa-var-wine-glass-empty,
  "wine-glass-alt": $fa-var-wine-glass-alt,
  "cheese": $fa-var-cheese,
  "yin-yang": $fa-var-yin-yang,
  "music": $fa-var-music,
  "code-commit": $fa-var-code-commit,
  "temperature-low": $fa-var-temperature-low,
  "person-biking": $fa-var-person-biking,
  "biking": $fa-var-biking,
  "broom": $fa-var-broom,
  "shield-heart": $fa-var-shield-heart,
  "gopuram": $fa-var-gopuram,
  "earth-oceania": $fa-var-earth-oceania,
  "globe-oceania": $fa-var-globe-oceania,
  "square-xmark": $fa-var-square-xmark,
  "times-square": $fa-var-times-square,
  "xmark-square": $fa-var-xmark-square,
  "hashtag": $fa-var-hashtag,
  "up-right-and-down-left-from-center": $fa-var-up-right-and-down-left-from-center,
  "expand-alt": $fa-var-expand-alt,
  "oil-can": $fa-var-oil-can,
  "t": $fa-var-t,
  "hippo": $fa-var-hippo,
  "chart-column": $fa-var-chart-column,
  "infinity": $fa-var-infinity,
  "vial-circle-check": $fa-var-vial-circle-check,
  "person-arrow-down-to-line": $fa-var-person-arrow-down-to-line,
  "voicemail": $fa-var-voicemail,
  "fan": $fa-var-fan,
  "person-walking-luggage": $fa-var-person-walking-luggage,
  "up-down": $fa-var-up-down,
  "arrows-alt-v": $fa-var-arrows-alt-v,
  "cloud-moon-rain": $fa-var-cloud-moon-rain,
  "calendar": $fa-var-calendar,
  "trailer": $fa-var-trailer,
  "bahai": $fa-var-bahai,
  "haykal": $fa-var-haykal,
  "sd-card": $fa-var-sd-card,
  "dragon": $fa-var-dragon,
  "shoe-prints": $fa-var-shoe-prints,
  "circle-plus": $fa-var-circle-plus,
  "plus-circle": $fa-var-plus-circle,
  "face-grin-tongue-wink": $fa-var-face-grin-tongue-wink,
  "grin-tongue-wink": $fa-var-grin-tongue-wink,
  "hand-holding": $fa-var-hand-holding,
  "plug-circle-exclamation": $fa-var-plug-circle-exclamation,
  "link-slash": $fa-var-link-slash,
  "chain-broken": $fa-var-chain-broken,
  "chain-slash": $fa-var-chain-slash,
  "unlink": $fa-var-unlink,
  "clone": $fa-var-clone,
  "person-walking-arrow-loop-left": $fa-var-person-walking-arrow-loop-left,
  "arrow-up-z-a": $fa-var-arrow-up-z-a,
  "sort-alpha-up-alt": $fa-var-sort-alpha-up-alt,
  "fire-flame-curved": $fa-var-fire-flame-curved,
  "fire-alt": $fa-var-fire-alt,
  "tornado": $fa-var-tornado,
  "file-circle-plus": $fa-var-file-circle-plus,
  "book-quran": $fa-var-book-quran,
  "quran": $fa-var-quran,
  "anchor": $fa-var-anchor,
  "border-all": $fa-var-border-all,
  "face-angry": $fa-var-face-angry,
  "angry": $fa-var-angry,
  "cookie-bite": $fa-var-cookie-bite,
  "arrow-trend-down": $fa-var-arrow-trend-down,
  "rss": $fa-var-rss,
  "feed": $fa-var-feed,
  "draw-polygon": $fa-var-draw-polygon,
  "scale-balanced": $fa-var-scale-balanced,
  "balance-scale": $fa-var-balance-scale,
  "gauge-simple-high": $fa-var-gauge-simple-high,
  "tachometer": $fa-var-tachometer,
  "tachometer-fast": $fa-var-tachometer-fast,
  "shower": $fa-var-shower,
  "desktop": $fa-var-desktop,
  "desktop-alt": $fa-var-desktop-alt,
  "m": $fa-var-m,
  "table-list": $fa-var-table-list,
  "th-list": $fa-var-th-list,
  "comment-sms": $fa-var-comment-sms,
  "sms": $fa-var-sms,
  "book": $fa-var-book,
  "user-plus": $fa-var-user-plus,
  "check": $fa-var-check,
  "battery-three-quarters": $fa-var-battery-three-quarters,
  "battery-4": $fa-var-battery-4,
  "house-circle-check": $fa-var-house-circle-check,
  "angle-left": $fa-var-angle-left,
  "diagram-successor": $fa-var-diagram-successor,
  "truck-arrow-right": $fa-var-truck-arrow-right,
  "arrows-split-up-and-left": $fa-var-arrows-split-up-and-left,
  "hand-fist": $fa-var-hand-fist,
  "fist-raised": $fa-var-fist-raised,
  "cloud-moon": $fa-var-cloud-moon,
  "briefcase": $fa-var-briefcase,
  "person-falling": $fa-var-person-falling,
  "image-portrait": $fa-var-image-portrait,
  "portrait": $fa-var-portrait,
  "user-tag": $fa-var-user-tag,
  "rug": $fa-var-rug,
  "earth-europe": $fa-var-earth-europe,
  "globe-europe": $fa-var-globe-europe,
  "cart-flatbed-suitcase": $fa-var-cart-flatbed-suitcase,
  "luggage-cart": $fa-var-luggage-cart,
  "rectangle-xmark": $fa-var-rectangle-xmark,
  "rectangle-times": $fa-var-rectangle-times,
  "times-rectangle": $fa-var-times-rectangle,
  "window-close": $fa-var-window-close,
  "baht-sign": $fa-var-baht-sign,
  "book-open": $fa-var-book-open,
  "book-journal-whills": $fa-var-book-journal-whills,
  "journal-whills": $fa-var-journal-whills,
  "handcuffs": $fa-var-handcuffs,
  "triangle-exclamation": $fa-var-triangle-exclamation,
  "exclamation-triangle": $fa-var-exclamation-triangle,
  "warning": $fa-var-warning,
  "database": $fa-var-database,
  "share": $fa-var-share,
  "mail-forward": $fa-var-mail-forward,
  "bottle-droplet": $fa-var-bottle-droplet,
  "mask-face": $fa-var-mask-face,
  "hill-rockslide": $fa-var-hill-rockslide,
  "right-left": $fa-var-right-left,
  "exchange-alt": $fa-var-exchange-alt,
  "paper-plane": $fa-var-paper-plane,
  "road-circle-exclamation": $fa-var-road-circle-exclamation,
  "dungeon": $fa-var-dungeon,
  "align-right": $fa-var-align-right,
  "money-bill-1-wave": $fa-var-money-bill-1-wave,
  "money-bill-wave-alt": $fa-var-money-bill-wave-alt,
  "life-ring": $fa-var-life-ring,
  "hands": $fa-var-hands,
  "sign-language": $fa-var-sign-language,
  "signing": $fa-var-signing,
  "calendar-day": $fa-var-calendar-day,
  "water-ladder": $fa-var-water-ladder,
  "ladder-water": $fa-var-ladder-water,
  "swimming-pool": $fa-var-swimming-pool,
  "arrows-up-down": $fa-var-arrows-up-down,
  "arrows-v": $fa-var-arrows-v,
  "face-grimace": $fa-var-face-grimace,
  "grimace": $fa-var-grimace,
  "wheelchair-move": $fa-var-wheelchair-move,
  "wheelchair-alt": $fa-var-wheelchair-alt,
  "turn-down": $fa-var-turn-down,
  "level-down-alt": $fa-var-level-down-alt,
  "person-walking-arrow-right": $fa-var-person-walking-arrow-right,
  "square-envelope": $fa-var-square-envelope,
  "envelope-square": $fa-var-envelope-square,
  "dice": $fa-var-dice,
  "bowling-ball": $fa-var-bowling-ball,
  "brain": $fa-var-brain,
  "bandage": $fa-var-bandage,
  "band-aid": $fa-var-band-aid,
  "calendar-minus": $fa-var-calendar-minus,
  "circle-xmark": $fa-var-circle-xmark,
  "times-circle": $fa-var-times-circle,
  "xmark-circle": $fa-var-xmark-circle,
  "gifts": $fa-var-gifts,
  "hotel": $fa-var-hotel,
  "earth-asia": $fa-var-earth-asia,
  "globe-asia": $fa-var-globe-asia,
  "id-card-clip": $fa-var-id-card-clip,
  "id-card-alt": $fa-var-id-card-alt,
  "magnifying-glass-plus": $fa-var-magnifying-glass-plus,
  "search-plus": $fa-var-search-plus,
  "thumbs-up": $fa-var-thumbs-up,
  "user-clock": $fa-var-user-clock,
  "hand-dots": $fa-var-hand-dots,
  "allergies": $fa-var-allergies,
  "file-invoice": $fa-var-file-invoice,
  "window-minimize": $fa-var-window-minimize,
  "mug-saucer": $fa-var-mug-saucer,
  "coffee": $fa-var-coffee,
  "brush": $fa-var-brush,
  "mask": $fa-var-mask,
  "magnifying-glass-minus": $fa-var-magnifying-glass-minus,
  "search-minus": $fa-var-search-minus,
  "ruler-vertical": $fa-var-ruler-vertical,
  "user-large": $fa-var-user-large,
  "user-alt": $fa-var-user-alt,
  "train-tram": $fa-var-train-tram,
  "user-nurse": $fa-var-user-nurse,
  "syringe": $fa-var-syringe,
  "cloud-sun": $fa-var-cloud-sun,
  "stopwatch-20": $fa-var-stopwatch-20,
  "square-full": $fa-var-square-full,
  "magnet": $fa-var-magnet,
  "jar": $fa-var-jar,
  "note-sticky": $fa-var-note-sticky,
  "sticky-note": $fa-var-sticky-note,
  "bug-slash": $fa-var-bug-slash,
  "arrow-up-from-water-pump": $fa-var-arrow-up-from-water-pump,
  "bone": $fa-var-bone,
  "user-injured": $fa-var-user-injured,
  "face-sad-tear": $fa-var-face-sad-tear,
  "sad-tear": $fa-var-sad-tear,
  "plane": $fa-var-plane,
  "tent-arrows-down": $fa-var-tent-arrows-down,
  "exclamation": $fa-var-exclamation,
  "arrows-spin": $fa-var-arrows-spin,
  "print": $fa-var-print,
  "turkish-lira-sign": $fa-var-turkish-lira-sign,
  "try": $fa-var-try,
  "turkish-lira": $fa-var-turkish-lira,
  "dollar-sign": $fa-var-dollar-sign,
  "dollar": $fa-var-dollar,
  "usd": $fa-var-usd,
  "x": $fa-var-x,
  "magnifying-glass-dollar": $fa-var-magnifying-glass-dollar,
  "search-dollar": $fa-var-search-dollar,
  "users-gear": $fa-var-users-gear,
  "users-cog": $fa-var-users-cog,
  "person-military-pointing": $fa-var-person-military-pointing,
  "building-columns": $fa-var-building-columns,
  "bank": $fa-var-bank,
  "institution": $fa-var-institution,
  "museum": $fa-var-museum,
  "university": $fa-var-university,
  "umbrella": $fa-var-umbrella,
  "trowel": $fa-var-trowel,
  "d": $fa-var-d,
  "stapler": $fa-var-stapler,
  "masks-theater": $fa-var-masks-theater,
  "theater-masks": $fa-var-theater-masks,
  "kip-sign": $fa-var-kip-sign,
  "hand-point-left": $fa-var-hand-point-left,
  "handshake-simple": $fa-var-handshake-simple,
  "handshake-alt": $fa-var-handshake-alt,
  "jet-fighter": $fa-var-jet-fighter,
  "fighter-jet": $fa-var-fighter-jet,
  "square-share-nodes": $fa-var-square-share-nodes,
  "share-alt-square": $fa-var-share-alt-square,
  "barcode": $fa-var-barcode,
  "plus-minus": $fa-var-plus-minus,
  "video": $fa-var-video,
  "video-camera": $fa-var-video-camera,
  "graduation-cap": $fa-var-graduation-cap,
  "mortar-board": $fa-var-mortar-board,
  "hand-holding-medical": $fa-var-hand-holding-medical,
  "person-circle-check": $fa-var-person-circle-check,
  "turn-up": $fa-var-turn-up,
  "level-up-alt": $fa-var-level-up-alt,
);

$fa-brand-icons: (
  "monero": $fa-var-monero,
  "hooli": $fa-var-hooli,
  "yelp": $fa-var-yelp,
  "cc-visa": $fa-var-cc-visa,
  "lastfm": $fa-var-lastfm,
  "shopware": $fa-var-shopware,
  "creative-commons-nc": $fa-var-creative-commons-nc,
  "aws": $fa-var-aws,
  "redhat": $fa-var-redhat,
  "yoast": $fa-var-yoast,
  "cloudflare": $fa-var-cloudflare,
  "ups": $fa-var-ups,
  "pixiv": $fa-var-pixiv,
  "wpexplorer": $fa-var-wpexplorer,
  "dyalog": $fa-var-dyalog,
  "bity": $fa-var-bity,
  "stackpath": $fa-var-stackpath,
  "buysellads": $fa-var-buysellads,
  "first-order": $fa-var-first-order,
  "modx": $fa-var-modx,
  "guilded": $fa-var-guilded,
  "vnv": $fa-var-vnv,
  "square-js": $fa-var-square-js,
  "js-square": $fa-var-js-square,
  "microsoft": $fa-var-microsoft,
  "qq": $fa-var-qq,
  "orcid": $fa-var-orcid,
  "java": $fa-var-java,
  "invision": $fa-var-invision,
  "creative-commons-pd-alt": $fa-var-creative-commons-pd-alt,
  "centercode": $fa-var-centercode,
  "glide-g": $fa-var-glide-g,
  "drupal": $fa-var-drupal,
  "hire-a-helper": $fa-var-hire-a-helper,
  "creative-commons-by": $fa-var-creative-commons-by,
  "unity": $fa-var-unity,
  "whmcs": $fa-var-whmcs,
  "rocketchat": $fa-var-rocketchat,
  "vk": $fa-var-vk,
  "untappd": $fa-var-untappd,
  "mailchimp": $fa-var-mailchimp,
  "css3-alt": $fa-var-css3-alt,
  "square-reddit": $fa-var-square-reddit,
  "reddit-square": $fa-var-reddit-square,
  "vimeo-v": $fa-var-vimeo-v,
  "contao": $fa-var-contao,
  "square-font-awesome": $fa-var-square-font-awesome,
  "deskpro": $fa-var-deskpro,
  "brave": $fa-var-brave,
  "sistrix": $fa-var-sistrix,
  "square-instagram": $fa-var-square-instagram,
  "instagram-square": $fa-var-instagram-square,
  "battle-net": $fa-var-battle-net,
  "the-red-yeti": $fa-var-the-red-yeti,
  "square-hacker-news": $fa-var-square-hacker-news,
  "hacker-news-square": $fa-var-hacker-news-square,
  "edge": $fa-var-edge,
  "threads": $fa-var-threads,
  "napster": $fa-var-napster,
  "square-snapchat": $fa-var-square-snapchat,
  "snapchat-square": $fa-var-snapchat-square,
  "google-plus-g": $fa-var-google-plus-g,
  "artstation": $fa-var-artstation,
  "markdown": $fa-var-markdown,
  "sourcetree": $fa-var-sourcetree,
  "google-plus": $fa-var-google-plus,
  "diaspora": $fa-var-diaspora,
  "foursquare": $fa-var-foursquare,
  "stack-overflow": $fa-var-stack-overflow,
  "github-alt": $fa-var-github-alt,
  "phoenix-squadron": $fa-var-phoenix-squadron,
  "pagelines": $fa-var-pagelines,
  "algolia": $fa-var-algolia,
  "red-river": $fa-var-red-river,
  "creative-commons-sa": $fa-var-creative-commons-sa,
  "safari": $fa-var-safari,
  "google": $fa-var-google,
  "square-font-awesome-stroke": $fa-var-square-font-awesome-stroke,
  "font-awesome-alt": $fa-var-font-awesome-alt,
  "atlassian": $fa-var-atlassian,
  "linkedin-in": $fa-var-linkedin-in,
  "digital-ocean": $fa-var-digital-ocean,
  "nimblr": $fa-var-nimblr,
  "chromecast": $fa-var-chromecast,
  "evernote": $fa-var-evernote,
  "hacker-news": $fa-var-hacker-news,
  "creative-commons-sampling": $fa-var-creative-commons-sampling,
  "adversal": $fa-var-adversal,
  "creative-commons": $fa-var-creative-commons,
  "watchman-monitoring": $fa-var-watchman-monitoring,
  "fonticons": $fa-var-fonticons,
  "weixin": $fa-var-weixin,
  "shirtsinbulk": $fa-var-shirtsinbulk,
  "codepen": $fa-var-codepen,
  "git-alt": $fa-var-git-alt,
  "lyft": $fa-var-lyft,
  "rev": $fa-var-rev,
  "windows": $fa-var-windows,
  "wizards-of-the-coast": $fa-var-wizards-of-the-coast,
  "square-viadeo": $fa-var-square-viadeo,
  "viadeo-square": $fa-var-viadeo-square,
  "meetup": $fa-var-meetup,
  "centos": $fa-var-centos,
  "adn": $fa-var-adn,
  "cloudsmith": $fa-var-cloudsmith,
  "opensuse": $fa-var-opensuse,
  "pied-piper-alt": $fa-var-pied-piper-alt,
  "square-dribbble": $fa-var-square-dribbble,
  "dribbble-square": $fa-var-dribbble-square,
  "codiepie": $fa-var-codiepie,
  "node": $fa-var-node,
  "mix": $fa-var-mix,
  "steam": $fa-var-steam,
  "cc-apple-pay": $fa-var-cc-apple-pay,
  "scribd": $fa-var-scribd,
  "debian": $fa-var-debian,
  "openid": $fa-var-openid,
  "instalod": $fa-var-instalod,
  "expeditedssl": $fa-var-expeditedssl,
  "sellcast": $fa-var-sellcast,
  "square-twitter": $fa-var-square-twitter,
  "twitter-square": $fa-var-twitter-square,
  "r-project": $fa-var-r-project,
  "delicious": $fa-var-delicious,
  "freebsd": $fa-var-freebsd,
  "vuejs": $fa-var-vuejs,
  "accusoft": $fa-var-accusoft,
  "ioxhost": $fa-var-ioxhost,
  "fonticons-fi": $fa-var-fonticons-fi,
  "app-store": $fa-var-app-store,
  "cc-mastercard": $fa-var-cc-mastercard,
  "itunes-note": $fa-var-itunes-note,
  "golang": $fa-var-golang,
  "kickstarter": $fa-var-kickstarter,
  "grav": $fa-var-grav,
  "weibo": $fa-var-weibo,
  "uncharted": $fa-var-uncharted,
  "firstdraft": $fa-var-firstdraft,
  "square-youtube": $fa-var-square-youtube,
  "youtube-square": $fa-var-youtube-square,
  "wikipedia-w": $fa-var-wikipedia-w,
  "wpressr": $fa-var-wpressr,
  "rendact": $fa-var-rendact,
  "angellist": $fa-var-angellist,
  "galactic-republic": $fa-var-galactic-republic,
  "nfc-directional": $fa-var-nfc-directional,
  "skype": $fa-var-skype,
  "joget": $fa-var-joget,
  "fedora": $fa-var-fedora,
  "stripe-s": $fa-var-stripe-s,
  "meta": $fa-var-meta,
  "laravel": $fa-var-laravel,
  "hotjar": $fa-var-hotjar,
  "bluetooth-b": $fa-var-bluetooth-b,
  "square-letterboxd": $fa-var-square-letterboxd,
  "sticker-mule": $fa-var-sticker-mule,
  "creative-commons-zero": $fa-var-creative-commons-zero,
  "hips": $fa-var-hips,
  "behance": $fa-var-behance,
  "reddit": $fa-var-reddit,
  "discord": $fa-var-discord,
  "chrome": $fa-var-chrome,
  "app-store-ios": $fa-var-app-store-ios,
  "cc-discover": $fa-var-cc-discover,
  "wpbeginner": $fa-var-wpbeginner,
  "confluence": $fa-var-confluence,
  "shoelace": $fa-var-shoelace,
  "mdb": $fa-var-mdb,
  "dochub": $fa-var-dochub,
  "accessible-icon": $fa-var-accessible-icon,
  "ebay": $fa-var-ebay,
  "amazon": $fa-var-amazon,
  "unsplash": $fa-var-unsplash,
  "yarn": $fa-var-yarn,
  "square-steam": $fa-var-square-steam,
  "steam-square": $fa-var-steam-square,
  "500px": $fa-var-500px,
  "square-vimeo": $fa-var-square-vimeo,
  "vimeo-square": $fa-var-vimeo-square,
  "asymmetrik": $fa-var-asymmetrik,
  "font-awesome": $fa-var-font-awesome,
  "font-awesome-flag": $fa-var-font-awesome-flag,
  "font-awesome-logo-full": $fa-var-font-awesome-logo-full,
  "gratipay": $fa-var-gratipay,
  "apple": $fa-var-apple,
  "hive": $fa-var-hive,
  "gitkraken": $fa-var-gitkraken,
  "keybase": $fa-var-keybase,
  "apple-pay": $fa-var-apple-pay,
  "padlet": $fa-var-padlet,
  "amazon-pay": $fa-var-amazon-pay,
  "square-github": $fa-var-square-github,
  "github-square": $fa-var-github-square,
  "stumbleupon": $fa-var-stumbleupon,
  "fedex": $fa-var-fedex,
  "phoenix-framework": $fa-var-phoenix-framework,
  "shopify": $fa-var-shopify,
  "neos": $fa-var-neos,
  "square-threads": $fa-var-square-threads,
  "hackerrank": $fa-var-hackerrank,
  "researchgate": $fa-var-researchgate,
  "swift": $fa-var-swift,
  "angular": $fa-var-angular,
  "speakap": $fa-var-speakap,
  "angrycreative": $fa-var-angrycreative,
  "y-combinator": $fa-var-y-combinator,
  "empire": $fa-var-empire,
  "envira": $fa-var-envira,
  "google-scholar": $fa-var-google-scholar,
  "square-gitlab": $fa-var-square-gitlab,
  "gitlab-square": $fa-var-gitlab-square,
  "studiovinari": $fa-var-studiovinari,
  "pied-piper": $fa-var-pied-piper,
  "wordpress": $fa-var-wordpress,
  "product-hunt": $fa-var-product-hunt,
  "firefox": $fa-var-firefox,
  "linode": $fa-var-linode,
  "goodreads": $fa-var-goodreads,
  "square-odnoklassniki": $fa-var-square-odnoklassniki,
  "odnoklassniki-square": $fa-var-odnoklassniki-square,
  "jsfiddle": $fa-var-jsfiddle,
  "sith": $fa-var-sith,
  "themeisle": $fa-var-themeisle,
  "page4": $fa-var-page4,
  "hashnode": $fa-var-hashnode,
  "react": $fa-var-react,
  "cc-paypal": $fa-var-cc-paypal,
  "squarespace": $fa-var-squarespace,
  "cc-stripe": $fa-var-cc-stripe,
  "creative-commons-share": $fa-var-creative-commons-share,
  "bitcoin": $fa-var-bitcoin,
  "keycdn": $fa-var-keycdn,
  "opera": $fa-var-opera,
  "itch-io": $fa-var-itch-io,
  "umbraco": $fa-var-umbraco,
  "galactic-senate": $fa-var-galactic-senate,
  "ubuntu": $fa-var-ubuntu,
  "draft2digital": $fa-var-draft2digital,
  "stripe": $fa-var-stripe,
  "houzz": $fa-var-houzz,
  "gg": $fa-var-gg,
  "dhl": $fa-var-dhl,
  "square-pinterest": $fa-var-square-pinterest,
  "pinterest-square": $fa-var-pinterest-square,
  "xing": $fa-var-xing,
  "blackberry": $fa-var-blackberry,
  "creative-commons-pd": $fa-var-creative-commons-pd,
  "playstation": $fa-var-playstation,
  "quinscape": $fa-var-quinscape,
  "less": $fa-var-less,
  "blogger-b": $fa-var-blogger-b,
  "opencart": $fa-var-opencart,
  "vine": $fa-var-vine,
  "signal-messenger": $fa-var-signal-messenger,
  "paypal": $fa-var-paypal,
  "gitlab": $fa-var-gitlab,
  "typo3": $fa-var-typo3,
  "reddit-alien": $fa-var-reddit-alien,
  "yahoo": $fa-var-yahoo,
  "dailymotion": $fa-var-dailymotion,
  "affiliatetheme": $fa-var-affiliatetheme,
  "pied-piper-pp": $fa-var-pied-piper-pp,
  "bootstrap": $fa-var-bootstrap,
  "odnoklassniki": $fa-var-odnoklassniki,
  "nfc-symbol": $fa-var-nfc-symbol,
  "mintbit": $fa-var-mintbit,
  "ethereum": $fa-var-ethereum,
  "speaker-deck": $fa-var-speaker-deck,
  "creative-commons-nc-eu": $fa-var-creative-commons-nc-eu,
  "patreon": $fa-var-patreon,
  "avianex": $fa-var-avianex,
  "ello": $fa-var-ello,
  "gofore": $fa-var-gofore,
  "bimobject": $fa-var-bimobject,
  "brave-reverse": $fa-var-brave-reverse,
  "facebook-f": $fa-var-facebook-f,
  "square-google-plus": $fa-var-square-google-plus,
  "google-plus-square": $fa-var-google-plus-square,
  "mandalorian": $fa-var-mandalorian,
  "first-order-alt": $fa-var-first-order-alt,
  "osi": $fa-var-osi,
  "google-wallet": $fa-var-google-wallet,
  "d-and-d-beyond": $fa-var-d-and-d-beyond,
  "periscope": $fa-var-periscope,
  "fulcrum": $fa-var-fulcrum,
  "cloudscale": $fa-var-cloudscale,
  "forumbee": $fa-var-forumbee,
  "mizuni": $fa-var-mizuni,
  "schlix": $fa-var-schlix,
  "square-xing": $fa-var-square-xing,
  "xing-square": $fa-var-xing-square,
  "bandcamp": $fa-var-bandcamp,
  "wpforms": $fa-var-wpforms,
  "cloudversify": $fa-var-cloudversify,
  "usps": $fa-var-usps,
  "megaport": $fa-var-megaport,
  "magento": $fa-var-magento,
  "spotify": $fa-var-spotify,
  "optin-monster": $fa-var-optin-monster,
  "fly": $fa-var-fly,
  "aviato": $fa-var-aviato,
  "itunes": $fa-var-itunes,
  "cuttlefish": $fa-var-cuttlefish,
  "blogger": $fa-var-blogger,
  "flickr": $fa-var-flickr,
  "viber": $fa-var-viber,
  "soundcloud": $fa-var-soundcloud,
  "digg": $fa-var-digg,
  "tencent-weibo": $fa-var-tencent-weibo,
  "letterboxd": $fa-var-letterboxd,
  "symfony": $fa-var-symfony,
  "maxcdn": $fa-var-maxcdn,
  "etsy": $fa-var-etsy,
  "facebook-messenger": $fa-var-facebook-messenger,
  "audible": $fa-var-audible,
  "think-peaks": $fa-var-think-peaks,
  "bilibili": $fa-var-bilibili,
  "erlang": $fa-var-erlang,
  "x-twitter": $fa-var-x-twitter,
  "cotton-bureau": $fa-var-cotton-bureau,
  "dashcube": $fa-var-dashcube,
  "42-group": $fa-var-42-group,
  "innosoft": $fa-var-innosoft,
  "stack-exchange": $fa-var-stack-exchange,
  "elementor": $fa-var-elementor,
  "square-pied-piper": $fa-var-square-pied-piper,
  "pied-piper-square": $fa-var-pied-piper-square,
  "creative-commons-nd": $fa-var-creative-commons-nd,
  "palfed": $fa-var-palfed,
  "superpowers": $fa-var-superpowers,
  "resolving": $fa-var-resolving,
  "xbox": $fa-var-xbox,
  "searchengin": $fa-var-searchengin,
  "tiktok": $fa-var-tiktok,
  "square-facebook": $fa-var-square-facebook,
  "facebook-square": $fa-var-facebook-square,
  "renren": $fa-var-renren,
  "linux": $fa-var-linux,
  "glide": $fa-var-glide,
  "linkedin": $fa-var-linkedin,
  "hubspot": $fa-var-hubspot,
  "deploydog": $fa-var-deploydog,
  "twitch": $fa-var-twitch,
  "ravelry": $fa-var-ravelry,
  "mixer": $fa-var-mixer,
  "square-lastfm": $fa-var-square-lastfm,
  "lastfm-square": $fa-var-lastfm-square,
  "vimeo": $fa-var-vimeo,
  "mendeley": $fa-var-mendeley,
  "uniregistry": $fa-var-uniregistry,
  "figma": $fa-var-figma,
  "creative-commons-remix": $fa-var-creative-commons-remix,
  "cc-amazon-pay": $fa-var-cc-amazon-pay,
  "dropbox": $fa-var-dropbox,
  "instagram": $fa-var-instagram,
  "cmplid": $fa-var-cmplid,
  "upwork": $fa-var-upwork,
  "facebook": $fa-var-facebook,
  "gripfire": $fa-var-gripfire,
  "jedi-order": $fa-var-jedi-order,
  "uikit": $fa-var-uikit,
  "fort-awesome-alt": $fa-var-fort-awesome-alt,
  "phabricator": $fa-var-phabricator,
  "ussunnah": $fa-var-ussunnah,
  "earlybirds": $fa-var-earlybirds,
  "trade-federation": $fa-var-trade-federation,
  "autoprefixer": $fa-var-autoprefixer,
  "whatsapp": $fa-var-whatsapp,
  "slideshare": $fa-var-slideshare,
  "google-play": $fa-var-google-play,
  "viadeo": $fa-var-viadeo,
  "line": $fa-var-line,
  "google-drive": $fa-var-google-drive,
  "servicestack": $fa-var-servicestack,
  "simplybuilt": $fa-var-simplybuilt,
  "bitbucket": $fa-var-bitbucket,
  "imdb": $fa-var-imdb,
  "deezer": $fa-var-deezer,
  "raspberry-pi": $fa-var-raspberry-pi,
  "jira": $fa-var-jira,
  "docker": $fa-var-docker,
  "screenpal": $fa-var-screenpal,
  "bluetooth": $fa-var-bluetooth,
  "gitter": $fa-var-gitter,
  "d-and-d": $fa-var-d-and-d,
  "microblog": $fa-var-microblog,
  "cc-diners-club": $fa-var-cc-diners-club,
  "gg-circle": $fa-var-gg-circle,
  "pied-piper-hat": $fa-var-pied-piper-hat,
  "kickstarter-k": $fa-var-kickstarter-k,
  "yandex": $fa-var-yandex,
  "readme": $fa-var-readme,
  "html5": $fa-var-html5,
  "sellsy": $fa-var-sellsy,
  "sass": $fa-var-sass,
  "wirsindhandwerk": $fa-var-wirsindhandwerk,
  "wsh": $fa-var-wsh,
  "buromobelexperte": $fa-var-buromobelexperte,
  "salesforce": $fa-var-salesforce,
  "octopus-deploy": $fa-var-octopus-deploy,
  "medapps": $fa-var-medapps,
  "ns8": $fa-var-ns8,
  "pinterest-p": $fa-var-pinterest-p,
  "apper": $fa-var-apper,
  "fort-awesome": $fa-var-fort-awesome,
  "waze": $fa-var-waze,
  "cc-jcb": $fa-var-cc-jcb,
  "snapchat": $fa-var-snapchat,
  "snapchat-ghost": $fa-var-snapchat-ghost,
  "fantasy-flight-games": $fa-var-fantasy-flight-games,
  "rust": $fa-var-rust,
  "wix": $fa-var-wix,
  "square-behance": $fa-var-square-behance,
  "behance-square": $fa-var-behance-square,
  "supple": $fa-var-supple,
  "webflow": $fa-var-webflow,
  "rebel": $fa-var-rebel,
  "css3": $fa-var-css3,
  "staylinked": $fa-var-staylinked,
  "kaggle": $fa-var-kaggle,
  "space-awesome": $fa-var-space-awesome,
  "deviantart": $fa-var-deviantart,
  "cpanel": $fa-var-cpanel,
  "goodreads-g": $fa-var-goodreads-g,
  "square-git": $fa-var-square-git,
  "git-square": $fa-var-git-square,
  "square-tumblr": $fa-var-square-tumblr,
  "tumblr-square": $fa-var-tumblr-square,
  "trello": $fa-var-trello,
  "creative-commons-nc-jp": $fa-var-creative-commons-nc-jp,
  "get-pocket": $fa-var-get-pocket,
  "perbyte": $fa-var-perbyte,
  "grunt": $fa-var-grunt,
  "weebly": $fa-var-weebly,
  "connectdevelop": $fa-var-connectdevelop,
  "leanpub": $fa-var-leanpub,
  "black-tie": $fa-var-black-tie,
  "themeco": $fa-var-themeco,
  "python": $fa-var-python,
  "android": $fa-var-android,
  "bots": $fa-var-bots,
  "free-code-camp": $fa-var-free-code-camp,
  "hornbill": $fa-var-hornbill,
  "js": $fa-var-js,
  "ideal": $fa-var-ideal,
  "git": $fa-var-git,
  "dev": $fa-var-dev,
  "sketch": $fa-var-sketch,
  "yandex-international": $fa-var-yandex-international,
  "cc-amex": $fa-var-cc-amex,
  "uber": $fa-var-uber,
  "github": $fa-var-github,
  "php": $fa-var-php,
  "alipay": $fa-var-alipay,
  "youtube": $fa-var-youtube,
  "skyatlas": $fa-var-skyatlas,
  "firefox-browser": $fa-var-firefox-browser,
  "replyd": $fa-var-replyd,
  "suse": $fa-var-suse,
  "jenkins": $fa-var-jenkins,
  "twitter": $fa-var-twitter,
  "rockrms": $fa-var-rockrms,
  "pinterest": $fa-var-pinterest,
  "buffer": $fa-var-buffer,
  "npm": $fa-var-npm,
  "yammer": $fa-var-yammer,
  "btc": $fa-var-btc,
  "dribbble": $fa-var-dribbble,
  "stumbleupon-circle": $fa-var-stumbleupon-circle,
  "internet-explorer": $fa-var-internet-explorer,
  "stubber": $fa-var-stubber,
  "telegram": $fa-var-telegram,
  "telegram-plane": $fa-var-telegram-plane,
  "old-republic": $fa-var-old-republic,
  "odysee": $fa-var-odysee,
  "square-whatsapp": $fa-var-square-whatsapp,
  "whatsapp-square": $fa-var-whatsapp-square,
  "node-js": $fa-var-node-js,
  "edge-legacy": $fa-var-edge-legacy,
  "slack": $fa-var-slack,
  "slack-hash": $fa-var-slack-hash,
  "medrt": $fa-var-medrt,
  "usb": $fa-var-usb,
  "tumblr": $fa-var-tumblr,
  "vaadin": $fa-var-vaadin,
  "quora": $fa-var-quora,
  "square-x-twitter": $fa-var-square-x-twitter,
  "reacteurope": $fa-var-reacteurope,
  "medium": $fa-var-medium,
  "medium-m": $fa-var-medium-m,
  "amilia": $fa-var-amilia,
  "mixcloud": $fa-var-mixcloud,
  "flipboard": $fa-var-flipboard,
  "viacoin": $fa-var-viacoin,
  "critical-role": $fa-var-critical-role,
  "sitrox": $fa-var-sitrox,
  "discourse": $fa-var-discourse,
  "joomla": $fa-var-joomla,
  "mastodon": $fa-var-mastodon,
  "airbnb": $fa-var-airbnb,
  "wolf-pack-battalion": $fa-var-wolf-pack-battalion,
  "buy-n-large": $fa-var-buy-n-large,
  "gulp": $fa-var-gulp,
  "creative-commons-sampling-plus": $fa-var-creative-commons-sampling-plus,
  "strava": $fa-var-strava,
  "ember": $fa-var-ember,
  "canadian-maple-leaf": $fa-var-canadian-maple-leaf,
  "teamspeak": $fa-var-teamspeak,
  "pushed": $fa-var-pushed,
  "wordpress-simple": $fa-var-wordpress-simple,
  "nutritionix": $fa-var-nutritionix,
  "wodu": $fa-var-wodu,
  "google-pay": $fa-var-google-pay,
  "intercom": $fa-var-intercom,
  "zhihu": $fa-var-zhihu,
  "korvue": $fa-var-korvue,
  "pix": $fa-var-pix,
  "steam-symbol": $fa-var-steam-symbol,
);
