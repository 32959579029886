/**=====================
   73. Update  CSS Start
==========================**/
@import "../utils/_variables.scss";
// popover
.popover {
  background-color: $white;
  border: none;
  box-shadow: 0 0 20px rgba($primary-color, 0.1);
  .popover-header {
    background-color: $theme-medium-color;
    color: $primary-color;
    border-bottom: none;
  }
  .popover-body {
    color: rgba(43, 43, 43, 0.7);
  }
}
.popover-main{
  .btn-showcase{
    a{
      color: $white;
    }
    button{
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
.page-wrapper{
  .page-body-wrapper{
    .fix-sample{
      .card{
        margin-bottom: 95px;
      }
    }
  }
} 
.note-btn-group.btn-group {
  &:hover {
    >.dropdown-menu {
      display: block !important;
    }
  }
}
.cke_bottom{
  display: none !important;
}
// shadow
.shadow, .shadow-lg, .shadow-sm{
  &.shadow-showcase{
    margin-bottom: 10px;
  }
}
// modal
.modal-dialog{
  &.modal-dialog-centered{
    .modal-header{
      .btn-close{
        top: 22px;
      }
    }
  }
  &.static-modal{
    .modal-content{
      border: 1px solid $light-gray;
      border-radius: 0.3rem;
      .modal-header , .modal-footer , .modal-body{
        padding: 1rem;
      }
      .modal-header, .modal-body{
        border-bottom: 1px solid $light-gray;
        .btn-close{
          top: 22px;
        }
      }
    }
  }
}
// tooltip css start
.tooltip {
  &.bs-tooltip-top {
    .tooltip-arrow {
      &:before {
        border-top-color: $theme-medium-color;
      }
    }
  }
  &.bs-tooltip-bottom {
    .tooltip-arrow {
      &:before {
        border-bottom-color: $primary-color;
        border-top-color: transparent;
      }
    }
  }
  &.bs-tooltip-start {
    .tooltip-arrow {
      &:before {
        border-left-color: $theme-medium-color;
        border-top-color: transparent;
      }
    }
  }
  &.bs-tooltip-end {
    .tooltip-arrow {
      &:before {
        border-right-color: $theme-medium-color;
        border-top-color: transparent;
      }
    }
  }
  .tooltip-inner {
    background-color: $primary-color;
    color: #fff;
  }
  .tooltip-arrow {
    &:before {
      border-top-color: $theme-medium-color;
    }
  }
}
// tooltip css end
// dropdown css start
.dropdown-basic {
  overflow: visible !important;
  .dropdown {
    .dropdown-content {
      a {
        padding: 6px 16px;
        color: $dark-editor-document;
        opacity: 0.6;
        font-size: 13px;
        border-top: 1px solid $light-semi-gray;
        background: $white;
        &:hover {
          background-color: $white;
        }
      }
      .dropdown-header {
        padding: 8px 16px;
        font-weight: 400;
        color: $dark-color;
        font-size: 13px;
      }
    }
  }
  .dropup {
    .dropup-content {
      top: auto;
    }
  }
}
// dropdown css end
// tab-bootstrap css start
.nav-tabs {
  border-bottom-color: $light-semi-gray;
  .nav-bottom {
    .nav-item {
      .nav-link.active {
        border-color: $light-semi-gray;
      }
    }
  }
}
.nav-tabs {
  .nav-item {
    &.show {
      .nav-link {
        font-weight: 500;
      }
    }
  }
  .nav-link {
    color: $dark-color;
    &.active {
      font-weight: 500;
      border-color: $light-gray $light-gray $white;
    }
    &.dropdown-toggle {
      border-radius: 5px;
      border-bottom: none;
    }
  }
  &.nav-bottom {
    .nav-item {
      .nav-link {
        &.active {
          border-color: $light-gray $light-gray $white;
        }
      }
    }
  }
}
.dropdown-menu {
  -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  border: none;
  padding: 0;
  .dropdown-item {
    color: $theme-body-font-color;
    opacity: 0.6;
    font-size: 13px;
    padding: 6px 12px;
    border-top: 1px solid $light-semi-gray;
    background: $white;
    &:hover {
      background-color: $white;
    }
  }
  .dropdown-divider {
    margin: 0;
    border-top: 1px solid $light-gray;
  }
}
// tab-bootstrap css end
.border-tab {
  .nav-tabs {
    .nav-item {
      .nav-link {
        &.active {
          border-bottom: 2px solid $primary-color;
        }
        &.show {
          border-bottom: 2px solid $primary-color;
        }
        &:focus {
          border-bottom: 2px solid $primary-color;
        }
      }
    }
  }
}
.tabbed-card {
  .nav-tabs {
    .nav-item {
      .nav-link {
        top: 17px;
      }
    }
  }
}
.carousal-page{
  .slick-slider{
    .slick-dots{
      li{
        margin: 0;
        button{
          &::before{
            font-size: 8px;
          }
        }
      }
    }
  }
  .card-body{
    .slick-slider{
      .slick-slide{
        > div{
          &:first-child{
            margin: 0 5px;
          }
        }
      }
    }
  }
}
.sticky-header-main{
  .sticky-note{
    .note.ui-draggable{
      margin-bottom: 0;
    }
  }
}
.basic-cards{
  .border-bottom-light {
    border-bottom: 1px solid $light-gray;
  }
}
.component {
  .input-group {
    .btn {
      line-height: 32px;
      text-transform: capitalize;
    }
  }
}
.login-card {
  .login-main {
    .theme-form {
      label {
        font-size: $body-font-size;
      }

      .show-hide {
        top: 50%;
      }
    }
  }
}
// faq css end
.job-filter {
  .faq-form {
    .form-control {
      font-size: 14px;
    }
    .search-icon {
      width: 16px;
      height: 16px;
    }
  }
}
// list group //
.list-group-item {
  border-color: $light-gray;
}
// pagination //
.page-link {
  border-color: $light-gray;
}
// gallery //
.img-thumbnail {
  border-color: $light-gray;
}
.my-gallery.gallery-with-description {
  border-color: $light-gray !important;
}
.gallery-with-description {
  a {
    >div {
      border-color: $light-gray;
    }
  }
}
// editor
.editor-toolbar,
.editor-statusbar,
.CodeMirror {
  border-color: $light-gray;
}
// clockpicker
.clockpicker {
  input {
    border-radius: 10px !important;
  }
}
// time picker
.clockpicker-popover {
  .popover-title {
    background-color: $light;
  }
}
// progress
.progress {
  border-radius: 30px;
}
// modal
.modal-header,
.modal-footer,
.modal-content {
  .btn-close {
    position: absolute;
    top: 34px;
    right: 25px;
  }
}
.configuration-modal{
  .modal-header{
    .btn-close{
      top: 22px;
    }
  }
}
/**=====================
   73. Update  CSS Ends.
==========================**/