/**=====================
    19. React-Plugin CSS Start
==========================**/
.simple-list {
  .list-group-item {
    padding: 0;
    border: none;
    background-color: transparent;
  }
}

.simple-btn {
  background-color: transparent !important;
  border: none;
  padding: 0;
  margin: 0;
}

li.list-group-item.active {
  background-color: unset;
  border-color: unset;
  color: unset;
  font-weight: 600;
}

img.media {
  margin-bottom: 0;
}

ul.list-group {
  display: block;

  li.list-group-item.active {
    background-color: $transparent-color;
    border-color: $transparent-color;
  }

  img {
    width: auto;
    height: auto;
  }

  i {
    margin-right: unset;
  }
}

ul.bgcolor {
  li.list-group-item.active {
    background-color: #2b7704 !important;
    border-color: #2b7704 !important;
    color: #e6e6e6 !important;
    cursor: pointer;
  }
}

// slick
.slick-page {
  .slick-slider {
    margin: -8px 0 20px;
  }
}

.slick-slider {
  margin: -7px 0 0;

  .item {
    &:focus {
      outline: none;
    }
  }

  .slick-dots {
    position: relative;
    bottom: 0;
    margin-bottom: -10px;
    margin-top: 20px;

    li {
      button {
        &:before {
          font-size: 12px;
          color: var(--theme-default);
        }
      }

      &.slick-active {
        button {
          &:before {
            color: var(--theme-default);
          }
        }
      }
    }
  }
}

.product-main {
  .slick-slider {
    margin: 0;
  }
}

.widget-list .simple-list li {
  color: $white;
}

// sticky
.note {
  background-color: rgba($primary-color, 0.3);

  &:nth-child(2n) {
    background: rgba($secondary-color, 0.3);
  }
}

.react-stickies-wrapper {
  .btn-close {

    &:before,
    &:after {
      background: $transparent-color !important;
    }
  }

  .react-grid-layout {
    .react-grid-item {
      &:nth-child(1n) {
        .note {
          background: lighten($primary-color, 35%) !important;
        }
      }

      &:nth-child(2n) {
        .note {
          background: lighten($secondary-color, 35%) !important;
        }
      }

      &:nth-child(3n) {
        .note {
          background: lighten($success-color, 50%) !important;
        }
      }

      &:nth-child(4n) {
        .note {
          background: lighten($info-color, 30%) !important;
        }
      }

      &:nth-child(5n) {
        .note {
          background: lighten($warning-color, 35%) !important;
        }
      }

      &:nth-child(6n) {
        .note {
          background: lighten($danger-color, 25%) !important;
        }
      }
    }
  }
}

.date-picker .cal-date-widget {
  .react-datepicker {
    border: none;
    padding: 8px 16px 0;
    width: 100%;
  }

  .react-datepicker__navigation {
    top: 20px;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: $primary-color;
  }

  .react-datepicker__navigation-icon {
    &::before {
      border-width: 2px 2px 0 0;
      width: 8px;
      height: 8px;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    height: 32px;
  }

  .react-datepicker__day--outside-month {
    color: $gray-60;
  }

  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    &:hover {
      background-color: $primary-color;
      color: $white;
    }
  }
}

.react-datepicker {
  border: 1px solid $light-semi-gray;
}

.react-datepicker__header {
  background-color: $white;
  border: none;
}

.react-datepicker__navigation {
  // background-color: $primary-color;
  // border-radius: 20px;
  color: $white;
  top: 13px;

  &.react-datepicker__navigation--previous {
    left: 4px;
  }

  &.react-datepicker__navigation--next {
    right: 4px;
  }
}

.react-datepicker__day--selected {
  background-color: $primary-color;
  border-radius: 10px;

  &:hover {
    background-color: $primary-color;
  }
}

.react-datepicker__navigation-icon--next {
  left: -3px;
}

.react-datepicker__navigation-icon {
  top: 0px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: $primary-color;
  padding: 10px 10px 20px;
}

.react-datepicker__navigation-icon--previous {
  right: 0;
}

.react-datepicker__day-name {
  font-weight: bold;
}

.react-datepicker__week,
.react-datepicker__day {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker__navigation-icon--next,
.react-datepicker__navigation-icon--previous {
  &::before {
    top: 10px;
    left: -3px;
    border-color: $primary-color;
  }
}

.react-datepicker-popper[data-placement^="top"],
.react-datepicker-popper[data-placement^="bottom"] {
  .react-datepicker__triangle {
    margin-left: -10px;
  }
}

// tree css
.deni-react-treeview-container {
  width: 100% !important;

  .icon-and-text {
    .text {
      .text-inner {
        color: $primary-color;
        font-size: 14px;

        .treeview-item-example-text {
          vertical-align: middle;
        }
      }
    }
  }
}

.deni-react-treeview-container.classic {
  border-color: $body-color !important;

  .deni-react-treeview-item-container.classic {
    &.selected.select-row {
      background: linear-gradient(to bottom,
          rgba($primary-color, 0.1) 0%,
          rgba($primary-color, 0.5) 100%) !important;
    }

    &:hover.select-row {
      background-color: rgba($primary-color, 0.1) !important;
      border: unset;
    }

    .checkbox {
      border-color: $primary-color !important;
    }

    .expand-button.hasChild {
      color: $primary-color !important;
    }

    .actionButton {
      margin-left: 6px;
    }

    .icon-and-text {
      &.selected {
        background: linear-gradient(to bottom,
            rgba($primary-color, 0.1) 0%,
            rgba($primary-color, 0.6) 100%) !important;
        border: unset;
      }

      &:hover:not(.select-row) {
        background-color: rgba($primary-color, 0.1) !important;
        border: unset;
        padding-left: calc(5px - 0px);
      }

      .icon {
        opacity: 0.5;
      }
    }
  }
}

.deni-react-treeview-item-container {
  .expand-button.colapsed {
    &::before {
      height: 29px;
    }
  }
}

// tour page
.reactour__helper {
  .reactour__close {
    top: 16px;
    right: 16px;
  }

  span[data-tour-elem="badge"] {
    background-color: $primary-color;
    height: 30px;
  }

  .reactour__dot {
    &.reactour__dot--is-active {
      background-color: $primary-color;
    }
  }
}

.EasyMDEContainer {
  .CodeMirror {
    border: 1px solid $light-gray;
  }
}

// data tables 


.data-tables {
  .rdt_Table {
    .rdt_TableHead {
      .rdt_TableCol {
        .rdt_TableCol_Sortable {
          div {
            font-size: 14px;

            ~span {
              // display: none;
            }
          }
        }

        div {
          input {
            border-radius: 3px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border: 1px solid #e5e5e5;
            width: 6rem;
            margin-top: 0.5rem;
            font-size: 12px !important;
          }
        }
      }
    }
  }
}

// range slider
.rangeslider-horizontal,
.rangeslider-vertical {
  .rangeslider__fill {
    background-color: var(--theme-default) !important;
  }

  .rangeslider__handle {
    &:focus {
      outline: none;
    }
  }
}

.slider-vertical,
.vertical-height {
  .value {
    text-align: center;
  }
}

.rangeslider-vertical {
  .rangeslider__labels {
    margin: 0 0 0 32px !important;
  }
}

.input-range__slider {
  background: var(--theme-default);
  border: 1px solid var(--theme-default);
}

.input-range__track--active {
  background: var(--theme-default);
}

.input-range {
  margin-bottom: 34px;
}

// dropzone
.dzu-dropzone {
  border: 2px dashed var(--theme-default) !important;
  border-radius: 10px;
  background: rgba($primary-color, 0.1);
  overflow: auto !important;

  .dzu-inputLabel {
    padding: 0 14px;
  }

  .dzu-previewImage {
    max-height: 54px;
  }

  .dz-message {
    .note {
      background-color: $transparent-color;
    }
  }
}

.dzu-inputLabel {
  color: var(--theme-default) !important;
  text-align: center;
  margin-bottom: 0;
  font-family: $font-Poppins;
}

.dzu-previewContainer {
  padding: 30px !important;
  border-bottom: unset;
}

.dzu-inputLabelWithFiles {
  margin-left: 30px !important;
  background-color: var(--theme-default) !important;
  color: $white !important;
}

.dzu-submitButton {
  background-color: var(--theme-default) !important;

  &:disabled {
    background-color: #e6e6e6 !important;
  }
}

// Image Cropper
.input-cropper {
  border-radius: 4px;
  width: auto;

  ~.ReactCrop {
    margin-top: 20px;
  }
}

.ReactCrop {
  ~div {
    >canvas {
      margin-top: 25px;
      width: 35% !important;
      height: 35% !important;
    }
  }
}

.crop-portion {
  margin-left: 30px;
  margin-top: 30px;
  vertical-align: baseline;
}

.cropper-view-box {
  outline-color: var(--theme-default);
  outline: 1px solid var(--theme-default);
}

.cropper-point {
  background-color: var(--theme-default);
}

.card {
  >.list-group {
    +.card-footer {
      border-top: 1px solid $light-gray;
    }
  }
}

// sticky
.note {
  background-color: rgba($primary-color, 0.3);

  &:nth-child(2n) {
    background: rgba($secondary-color, 0.3);
  }
}

.react-stickies-wrapper {
  .btn-close {

    &:before,
    &:after {
      background: $transparent-color !important;
    }
  }

  .react-grid-layout {
    .react-grid-item {
      &:nth-child(1n) {
        .note {
          background: lighten($primary-color, 35%) !important;
        }
      }

      &:nth-child(2n) {
        .note {
          background: lighten($secondary-color, 35%) !important;
        }
      }

      &:nth-child(3n) {
        .note {
          background: lighten($success-color, 50%) !important;
        }
      }

      &:nth-child(4n) {
        .note {
          background: lighten($info-color, 30%) !important;
        }
      }

      &:nth-child(5n) {
        .note {
          background: lighten($warning-color, 35%) !important;
        }
      }

      &:nth-child(6n) {
        .note {
          background: lighten($danger-color, 25%) !important;
        }
      }
    }
  }
}

// sweetalert
.lead {
  margin-bottom: 5px;
}

.swal2-container {
  .swal2-actions {
    .swal2-styled {
      font-size: 14px;
    }
  }
}

.swal2-styled {
  &.swal2-confirm {
    background-color: var(--theme-default) !important;

    &:focus {
      box-shadow: 0 0 0 1px $white, 0 0 0 3px rgba($primary-color, 0.4);
    }
  }
}

.swal2-title {
  font-size: 20px !important;
}

.swal2-progress-steps {

  .swal2-progress-step,
  .swal2-progress-step-line {
    background: var(--theme-default) !important;
  }

  .swal2-progress-step {
    &.swal2-active-progress-step {
      background: var(--theme-default) !important;

      ~.swal2-progress-step,
      ~.swal2-progress-step-line {
        background: rgba($primary-color, 0.2) !important;
      }
    }
  }
}

// datetime picker
.bootstrap-datetimepicker-widget {
  tr {

    th,
    td {

      &:active,
      &.active {
        background-color: var(--theme-default);
      }

      span {

        &:active,
        &.active {
          background-color: var(--theme-default);
        }
      }
    }
  }
}

// upload
.fileContainer {
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
  background: $transparent-color !important;

  .uploadPictureContainer {
    margin: 1% !important;
  }

  .chooseFileButton {
    margin-top: 0 !important;
  }
}

// daterange picker
.daterangepicker {

  td,
  th {
    &.available {
      &:hover {
        background-color: var(--theme-default);
      }
    }
  }
}

// to-do
.todo {
  .action-box {
    &.large {
      .icon {

        .icon-trash,
        .icon-check {
          color: var(--theme-default);
        }
      }
    }
  }

  .todo-list-wrapper {
    #todo-list {
      li {
        &.completed {
          .task-container {
            .task-label {
              color: var(--theme-default);
            }
          }
        }
      }
    }
  }

  @keyframes taskHighlighter {
    0% {
      background: rgba($primary-color, 0.5);
    }

    100% {
      background: $white;
    }
  }
}

.react-clock__face {
  border: 1px solid $white !important;
}

.react-clock__mark__body,
.react-clock__hand__body {
  background-color: $white !important;
}

// scrollable page
.scrollarea {
  max-width: 800px;
  max-height: 300px;

  .scrollbar-container {
    z-index: 7 !important;
  }
}

.horz-scroll-content {
  width: 1600px;
}

//form wizard
ol {
  display: flex;

  &.progtrckr {
    list-style-type: none;
    padding: 0;

    li {
      display: inline-block;
      text-align: center;
      line-height: 4.5rem;
      cursor: pointer;
      width: 100%;

      em {
        display: none;
      }

      span {
        padding: 0 1.5rem;
      }

      &.progtrckr-todo,
      &.progtrckr-doing,
      &.progtrckr-done {
        &:before {
          font-size: 20px !important;
          width: 30px !important;
          height: 30px !important;
          line-height: 30px !important;
        }
      }

      &.progtrckr-doing {
        color: $black;
        border-bottom: 4px solid $gray-60;

        &:before {
          content: "\2022";
          color: $white;
          background-color: $gray-60;
          width: 1.2em;
          line-height: 1.2em;
          border-radius: 1.2em;
        }

        &:hover {
          &:before {
            color: $danger-color;
          }
        }
      }

      &.progtrckr-done {
        color: $black;
        border-bottom: 4px solid $primary-color;

        &:before {
          content: "\2713";
          color: $white;
          background-color: $primary-color;
          width: 1.2em;
          line-height: 1.2em;
          border-radius: 1.2em;
        }

        &:hover {
          &:before {
            color: $white;
          }
        }
      }

      &:after {
        content: "\00a0\00a0";
      }

      &:before {
        position: relative;
        bottom: -3.7rem;
        float: left;
        left: 50%;
      }

      &.progtrckr-todo {
        &:before {
          content: "\039F";
          color: silver;
          background-color: $transparent-color;
          width: 1.2em;
          line-height: 1.4em;
        }

        &:hover {
          &:before {
            color: $danger-color;
          }
        }
      }
    }
  }
}

.multi-step {
  position: relative;

  .footer-buttons {
    .btn-lg {
      font-size: 14px;
    }
  }

  .btnsubmit {
    position: absolute;
    bottom: -34px;
    right: 15px;
  }
}

// Image Gallery
.pswp__img {
  padding: 25px 0px;
}

// calender
.rbc-calendar {
  height: 1000px !important;
}

#external-events {
  position: absolute;
  z-index: 2;
  padding: 0 10px;
  border: 1px solid $gray-60;
  background: #eee;
  overflow-y: scroll;

  p {
    font-size: 14px;
  }
}

#external-events .fc-event {
  margin: 1em 0;
  cursor: move;
  padding: 12px !important;
}

.rbc-toolbar {
  button {
    border: 1px solid var(--theme-default) !important;
    font-size: 14px;

    &:active,
    &.rbc-active {
      background-color: var(--theme-default) !important;
      box-shadow: none !important;
      color: $white !important;
    }
  }
}

.rbc-today {
  background-color: rgba($primary-color, 0.1) !important;
}

.rbc-row-content {
  z-index: 1 !important;
}

.rbc-header {
  padding: 5px !important;
}

.rbc-month-view {
  height: auto !important;
}

.rbc-date-cell {
  text-align: center;
}

.rbc-event,
.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  background-color: var(--theme-default) !important;
}

// draggable calender
#external-events {
  background-color: $transparent-color !important;
  overflow-y: auto !important;
  padding: 10px !important;
  width: 90%;
}

.fc-event,
.fc-event-dot {
  background-color: var(--theme-default) !important;
  border-color: var(--theme-default) !important;
  padding: 7px !important;
  color: $white !important;
}

.fc {
  .fc-button {
    .fc-icon {
      vertical-align: -2px;
    }
  }
}

.fc .fc-daygrid-day-top {
  justify-content: center;
}

.fc-button-primary {
  background-color: var(--theme-default) !important;
  border-color: var(--theme-default) !important;
}

//   base-typeahead
.rbt-token {
  color: var(--theme-default) !important;
  background-color: rgba($primary-color, 0.1) !important;

  .rbt-token-remove-button {
    top: 1px !important;
  }
}

.btn-toolbar {
  margin-bottom: -8px !important;
}

.rbt-aux {
  .rbt-close {
    margin-top: 0;

    &.btn-close {
      background: unset;
    }
  }
}

.reactour__close-button {
  top: 14px !important;
  right: 14px !important;
}

.reactour__helper {
  .reactour__close {
    top: 16px;
    right: 16px;
  }

  span[data-tour-elem="badge"] {
    background-color: $primary-color;
    height: 30px;
  }

  .reactour__dot {
    &.reactour__dot--is-active {
      background-color: $primary-color;
    }
  }
}

// draggable board
.draggable-normal {
  .react-trello-board {
    height: auto;
    padding: 0;

    .react-trello-lane {
      margin: 0;
      padding: 15px;
    }

    .react-trello-card {
      max-width: 100%;
      padding: 15px;
      margin-bottom: 0;
    }
  }
}

// time-picker
.react_times_button {
  .wrapper {
    display: block;
  }
}

.time_picker_wrapper2 {
  display: flex;

  .time_picker_container {
    width: 44%;
  }

  .gap {
    padding: 0 5px;
  }
}

.datetime-picker {
  .input-group {
    .react-datepicker-wrapper {
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .input-group {
    flex-wrap: unset;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 96px;
  }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: $primary-color;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: $primary-color;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: $primary-color;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: rgba($primary-color, 0.2);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: $primary-color;
}

.react-datepicker__close-icon {
  &::after {
    background-color: $primary-color;
  }
}

.date-range-picker {
  .daterange-card {
    padding-bottom: 10px;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 96px;
  }
}

// toastr
.Toastify__close-button {
  font-weight: 500;

  svg {
    stroke: $white !important;
    fill: $white !important;
  }
}

.Toastify__toast--success {
  background: #eefbf4 !important;
  color: #2dcf63 !important;
  font-weight: 600;

  svg {
    fill: transparent !important;
    stroke: #2dcf63 !important;
  }

  .Toastify__progress-bar {
    background: #2dcf63 !important;
  }
}

.Toastify__toast--info {
  background: #e8f6ff !important;
  color: #18adfd !important;

  svg {
    fill: transparent !important;
    stroke: #18adfd !important;
  }

  .Toastify__progress-bar {
    background: #18adfd !important;
  }
}

.Toastify__toast--warning {
  background: #fff6e6 !important;
  color: #efa82d !important;

  svg {
    fill: transparent !important;
    stroke: #efa82d !important;
  }

  .Toastify__progress-bar {
    background: #efa82d !important;
  }
}

.Toastify__toast--error {
  background: #ffeded !important;
  color: #ff484a !important;
  font-weight: 600;

  svg {
    fill: transparent !important;
    stroke: #ff484a !important;
  }

  .Toastify__progress-bar {
    background: #ff484a !important;
  }
}

// draggable card
.draggable-card {
  >div {
    >div {
      width: 100%;
      display: flex;

      .react-kanban-column {
        width: 100%;
      }

      .react-kanban-card {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}

.ui-sortable {
  .react-kanban-board {
    div[data-rbd-droppable-id="board-droppable"] {
      width: 100%;
      display: flex;
    }

    .react-kanban-column {
      width: 100%;
    }

    .react-kanban-card {
      max-width: 100%;
    }
  }
}

.jkanban-container {
  .custom-board {
    .kanban-container {
      .react-trello-board {
        .smooth-dnd-container {
          &.horizontal {
            .react-trello-lane {
              >header {
                span {
                  color: $white;
                }
              }
            }

            .smooth-dnd-draggable-wrapper {
              &:first-child {
                .react-trello-lane {
                  background-color: rgba($info-color, 0.8);
                }
              }
            }

            .smooth-dnd-draggable-wrapper {
              &:nth-child(2) {
                .react-trello-lane {
                  background-color: rgba($warning-color, 0.7);
                }
              }
            }

            .smooth-dnd-draggable-wrapper {
              &:nth-child(3) {
                .react-trello-lane {
                  background-color: rgba($success-color, 0.7);
                }
              }
            }
          }
        }
      }
    }
  }

  .kanban-container {
    .react-trello-board {
      background-color: transparent;
      padding: 0;
      margin: -5px;

      .smooth-dnd-container {
        &.horizontal {
          .react-trello-lane {
            width: 480px;
            padding: 0;
            border-radius: 10px;
            background-color: $light;

            >header {
              padding: 20px 24px;
              border-bottom: 1px solid $white;

              ~div {
                margin-top: 28px;
              }
            }
          }
        }

        &.vertical {
          width: 428px;

          .kanban-content {
            .d-flex {
              .flex-grow-1 {
                p {
                  opacity: 0.7;
                }
              }
            }

            .list,
            .date {
              opacity: .4;
            }

            .list {
              li {
                +li {
                  margin-left: 2px;
                }
              }
            }

            .customers {
              ul {
                text-align: right;

                li {
                  vertical-align: middle;
                }
              }
            }
          }

          header {
            padding-bottom: 10px;

            span[draggable="true"] {
              font-weight: 500;
            }

            .badge {
              font-size: 10px;
              padding: 4px 6px;
            }

            button {
              display: none;
            }
          }

          article.react-trello-card {
            max-width: 100%;
            padding: 20px;
            border-bottom: 0;
            margin-bottom: 28px;

            &:hover {
              background-color: $white;
            }
          }
        }
      }
    }
  }
}

//DragNDrop
.ui-sortable {
  .container {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: var(--theme-default);

    div {
      padding: 10px;
      background-color: lighten($primary-color, 4%);
      transition: opacity 0.4s ease-in-out;
      color: $white;
    }

    div {
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.ex-moved {
      background-color: #e74c3c;
    }

    &.ex-over {
      background-color: var(--theme-default);
    }

    &:nth-child(odd) {
      background-color: var(--theme-default);
    }
  }
}

.gh-fork {
  position: fixed;
  top: 0;
  right: 0;
  border: 0;
}

.gu-mirror {
  padding: 10px;
  background-color: darken($primary-color, 5%);
  transition: opacity 0.4s ease-in-out;
  color: $white;
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.wrapper {
  display: table;
}

.gu-mirror {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.handle {
  padding: 0 5px;
  margin-right: 5px;
  background-color: darken($primary-color, 15%);
  cursor: move;
}

// timeline
.vertical-timeline {
  &::before {
    background: $light-color;
    width: 2px;
  }

  .vertical-timeline-element-subtitle {
    font-weight: 600;
  }

  .vertical-timeline-element {
    p {
      font-size: 13px !important;
    }

    .vertical-timeline-element-icon {
      color: $white;
      border-radius: 4px;
      box-shadow: none;
    }

    &:first-child,
    &:nth-child(7) {
      .vertical-timeline-element-icon {
        background-color: var(--theme-default);
      }
    }

    &:nth-child(2) {
      .vertical-timeline-element-icon {
        background-color: $secondary-color;
      }
    }

    &:nth-child(3),
    &:last-child {
      .vertical-timeline-element-icon {
        background-color: $success-color;
      }
    }

    &:nth-child(4) {
      .vertical-timeline-element-icon {
        background-color: $info-color;
      }
    }

    &:nth-child(5) {
      .vertical-timeline-element-icon {
        background-color: $warning-color;
      }
    }

    &:nth-child(6) {
      .vertical-timeline-element-icon {
        background-color: $danger-color;
      }
    }
  }
}

.vertical-timeline-element-content {
  box-shadow: 1px 1px 2px 1px rgba($black, 0.08);
  background-color: $light;

  p {
    font-weight: 400;
  }

  audio {
    width: 100%;
    margin-top: 15px;
  }

  .vertical-timeline-element-date {
    font-size: 14px !important;
    padding-bottom: 0;
  }

  .vertical-timeline-element-content-arrow {
    border-right: 7px solid $light-color;
  }
}

.vertical-timeline--two-columns {
  .vertical-timeline-element-content {
    .vertical-timeline-element-date {
      top: 10px !important;
    }
  }
}

.vertical-timeline--one-column {
  &:before {
    left: 28px !important;
  }

  .vertical-timeline-element-icon {
    width: 60px;
    height: 60px;
  }

  .vertical-timeline-element-content {
    margin-left: 90px;

    img {
      width: 500px;
    }

    .vertical-timeline-element-date {
      float: none;
      display: block;
    }
  }
}

// leafleft map
.leaflet-top,
.leaflet-bottom {
  z-index: 7 !important;
}

.leaflet-pane {
  z-index: 1 !important;
}

// vector map
#gmap-simple {
  svg {
    path {
      fill: var(--theme-default) !important;
    }
  }
}

#usa {
  svg {
    path {
      fill: var(--theme-secondary) !important;
    }
  }
}

#canada {
  svg {
    path {
      fill: $success-color !important;
    }
  }
}

#asia {
  svg {
    path {
      fill: $warning-color !important;
    }
  }
}

#europe {
  svg {
    path {
      fill: $danger-color !important;
    }
  }
}

#america {
  svg {
    path {
      fill: $dark-color !important;
    }
  }
}

// rating
.rating {
  align-items: center;

  span {
    .fa {
      color: var(--theme-default);
    }

    span {
      span {
        color: var(--theme-default);
      }
    }
  }
}

// form wizard
@media (max-width: 485px) {
  ol {
    &.progtrckr {
      li {
        &:before {
          bottom: -63px !important;
        }

        span {
          padding: 0 !important;
        }

        &.progtrckr-todo,
        &.progtrckr-doing,
        &.progtrckr-done {
          &:before {
            font-size: 18px !important;
            width: 20px !important;
            height: 20px !important;
            line-height: 20px !important;
          }
        }
      }
    }
  }
}

.color-box {
  button+button {
    margin-left: 5px;
  }
}

// Error pages
.error1 {
  tspan {
    white-space: pre;
  }

  .shp0 {
    fill: none;
    stroke: #263238;
    stroke-width: 1.082;
  }

  .shp1 {
    fill: #263238;
  }

  .shp2 {
    fill: #2b7704;
  }

  .shp3 {
    fill: none;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.082;
  }

  .shp4 {
    fill: #ffa47a;
  }

  .shp5 {
    fill: #ffffff;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.082;
  }

  .shp6 {
    fill: #ffa47a;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.082;
  }

  .shp7 {
    fill: #263238;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.082;
  }

  .shp8 {
    fill: #e5e5e5;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.082;
  }

  .shp9 {
    fill: none;
    stroke: #ffffff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.082;
  }

  .shp10 {
    fill: #2b7704;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.082;
  }

  .shp11 {
    fill: #adadad;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.082;
  }

  .shp12 {
    fill: #2b7704;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.541;
  }

  .shp13 {
    fill: #ffffff;
    stroke: #263238;
    stroke-width: 1.082;
  }
}

a {
  button {
    letter-spacing: 1px !important;
  }
}

.error2 {
  tspan {
    white-space: pre;
  }

  .shp0 {
    fill: #f5f5f5;
  }

  .shp1 {
    fill: #e6e6e6;
  }

  .shp2 {
    fill: #ffa8a7;
  }

  .shp3 {
    fill: #37474f;
  }

  .shp4 {
    fill: #263238;
  }

  .shp5 {
    fill: #000000;
  }

  .shp6 {
    fill: #455a64;
  }

  .shp7 {
    fill: #2b7704;
  }

  .shp8 {
    fill: #f28f8f;
  }

  .shp9 {
    opacity: 0.149;
    fill: #000000;
  }

  .shp10 {
    opacity: 0.2;
    fill: #000000;
  }

  .shp11 {
    opacity: 0.502;
    fill: #ffffff;
  }

  .shp12 {
    fill: #e0e0e0;
  }

  .shp13 {
    fill: #b16668;
  }

  .shp14 {
    fill: #ffffff;
  }

  .shp15 {
    opacity: 0.349;
    fill: #000000;
  }

  .shp16 {
    opacity: 0.102;
    fill: #000000;
  }
}

.error3 {
  tspan {
    white-space: pre;
  }

  .shp0 {
    fill: #f5f5f5;
  }

  .shp1 {
    fill: #263238;
  }

  .shp2 {
    fill: #ff8b7b;
  }

  .shp3 {
    fill: #2b7704;
  }

  .shp4 {
    fill: #ffffff;
  }

  .shp5 {
    opacity: 0.2;
    fill: #000000;
  }

  .shp6 {
    opacity: 0.302;
    fill: #2b7704;
  }

  .shp7 {
    fill: #ff5652;
  }

  .shp8 {
    opacity: 0.702;
    fill: #ffffff;
  }

  .shp9 {
    opacity: 0.2;
    fill: #2b7704;
  }

  .shp10 {
    fill: #ffc3bd;
  }

  .shp11 {
    fill: #ed847e;
  }
}

.error4 {
  tspan {
    white-space: pre;
  }

  .shp0 {
    fill: #ffffff;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.75;
  }

  .shp1 {
    fill: #263238;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.75;
  }

  .shp2 {
    fill: #263238;
  }

  .shp3 {
    fill: #2b7704;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.75;
  }

  .shp4 {
    fill: #ffffff;
  }

  .shp5 {
    fill: none;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.75;
  }

  .shp6 {
    fill: none;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .shp7 {
    fill: #2b7704;
  }

  .shp8 {
    fill: #ffa47a;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .shp9 {
    fill: #ffffff;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .shp10 {
    opacity: 0.502;
    fill: #ffffff;
  }

  .shp11 {
    fill: #ffa47a;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.75;
  }

  .shp12 {
    fill: #ffa47a;
  }

  .shp13 {
    opacity: 0.502;
    fill: #ffa47a;
  }

  .shp14 {
    fill: none;
    stroke: #ffffff;
    stroke-linecap: round;
  }

  .shp15 {
    opacity: 0.502;
    fill: #ffa47a;
    stroke: #263238;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.75;
  }
}

.error5 {
  tspan {
    white-space: pre;
  }

  .shp0 {
    fill: #f5f5f5;
  }

  .shp1 {
    fill: #e0e0e0;
  }

  .shp2 {
    fill: #b16668;
  }

  .shp3 {
    fill: #263238;
  }

  .shp4 {
    fill: #455a64;
  }

  .shp5 {
    fill: #37474f;
  }

  .shp6 {
    fill: #9a4a4d;
  }

  .shp7 {
    fill: #2b7704;
  }

  .shp8 {
    fill: #000000;
  }

  .shp9 {
    fill: #ffffff;
  }

  .shp10 {
    fill: #ffa8a7;
  }

  .shp11 {
    fill: #f28f8f;
  }

  .shp12 {
    opacity: 0.102;
    fill: #000000;
  }

  .shp13 {
    opacity: 0.4;
    fill: #000000;
  }

  .shp14 {
    opacity: 0.302;
    fill: #000000;
  }

  .shp15 {
    opacity: 0.2;
    fill: #000000;
  }
}

/**=====================
    19. React-Plugin CSS Ends
==========================**/